import Vue from 'vue';

let waitForRefreshPromise = null;

const _addGlobalHeaderKeys = (headers) => {
  const {
    companyId,
    shadowUser,
    selectedAffiliateFilters,
    selectedAffiliateCompanyFilters,
  } = Vue.prototype.$globals;

  if (companyId) {
    headers['helix-company-id'] = `${companyId}`;
  }

  if (shadowUser) {
    headers['helix-user-id'] = `${shadowUser.id}`;
  }

  if (selectedAffiliateFilters?.length) {
    headers['filter-affiliate-ids'] = selectedAffiliateFilters.join(',');
  }

  const pubSubSessionId = Vue.prototype.$globals.pubSubUtil.sessionId;
  if (pubSubSessionId) {
    headers['helix-pub-sub-session-id'] = pubSubSessionId;
  }

  if (selectedAffiliateCompanyFilters.length) {
    headers['filter-affiliate-company-ids'] = selectedAffiliateCompanyFilters.join(',');
  }

  try {
    headers['helix-timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (ex) {}
};

export default class BaseAPIClient {
  constructor($http) {
    this.baseUrl = Vue.prototype.$globalEnv.baseUrl;
    this.http = $http;
  }

  async _refreshToken() {
    if (!waitForRefreshPromise) {
      // eslint-disable-next-line no-async-promise-executor
      waitForRefreshPromise = new Promise(async (resolve) => {
        let newAccessToken = '';
        const refreshToken = Vue.prototype.$globals.refreshToken || '';
        let valid = false;

        if (refreshToken) {
          Vue.prototype.$globals.accessToken = '';
          Vue.prototype.$globals.saveCookie();

          let results;
          try {
            results = await this.http.post(`${this.baseUrl}/users/token/refresh/`, {
              refresh: refreshToken,
            }, {
              headers: {
                'Content-Type': 'application/json',
              },
            });
          } catch (ex) {
            console.log(ex);
            valid = false;
          }

          if (results) {
            const body = results.body || results || {};

            if (body.access) {
              newAccessToken = body.access;
              Vue.prototype.$globals.accessToken = body.access;
              if (body.refresh) {
                Vue.prototype.$globals.refreshToken = body.refresh;
                Vue.prototype.$globals.setRefreshToken();
              }
              valid = true;
              Vue.prototype.$globals.saveCookie();
            }
          }
        }

        if (valid) {
          resolve(newAccessToken);
        } else {
          resolve(null);
        }
        waitForRefreshPromise = null;
      });
    }
    return waitForRefreshPromise;
  }

  async _get(url, params, options) {
    const companyIdOverride = (options && options.companyIdOverride) || null;
    const affiliateCompanyOverride = (options && options.affiliateCompanyOverride) || null;
    const fromOwner = options && options.fromOwner;
    return this.requestWithParams('get', url, params, { companyIdOverride, affiliateCompanyOverride, fromOwner });
  }

  async _getUrl(url) {
    const accessToken = Vue.prototype.$globals.accessToken || '';

    const headers = {
      'Content-Type': 'application/json',
    };

    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }
    _addGlobalHeaderKeys(headers);

    return this.http.get(url, {
      headers,
    }).then(this._handleResponse);
  }

  async _delete(url, params) {
    return this.requestWithParams('delete', url, params);
  }

  async _post(url, data, options) {
    const companyIdOverride = (options && options.companyIdOverride) || null;
    const fromOwner = options && options.fromOwner;
    return this.request('post', url, data, { companyIdOverride, fromOwner });
  }

  async _postMedia(url, formData) {
    return this.request('post', url, formData);
  }

  async _basicAuthPost(url, email, password, params) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Basic ${window.btoa(`${email}:${password}`)}`,
    };

    return this.http.post(`${this.baseUrl}/${url}`, params || {}, {
      headers,
    }).then(this._handleResponse);
  }

  async _singleUseTokenAuthPost(url, token) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `SingleUseToken ${token}`,
    };

    return this.http.post(`${this.baseUrl}/${url}`, {}, {
      headers,
    }).then(this._handleResponse);
  }

  async _put(url, data) {
    return this.request('put', url, data);
  }

  async _patch(url, data, options) {
    const companyIdOverride = (options && options.companyIdOverride) || null;
    const fromOwner = options && options.fromOwner;
    return this.request('patch', url, data, { fromOwner, companyIdOverride });
  }

  _handleResponse(results) {
    if (results && results.ok) {
      return results.body;
    }
    return {};
  }

  // create generic request
  async requestWithParams(action, url, params, options) {
    if (waitForRefreshPromise) {
      await waitForRefreshPromise;
    }
    const affiliateCompanyOverride = (options && options.affiliateCompanyOverride) || null;
    const companyIdOverride = (options && options.companyIdOverride) || null;
    let accessToken = Vue.prototype.$globals.accessToken || '';
    const fromOwner = options && options.fromOwner;

    const headers = {
      'Content-Type': 'application/json',
    };

    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }

    _addGlobalHeaderKeys(headers);

    if (companyIdOverride !== null) {
      headers['helix-company-id'] = `${companyIdOverride}`;
    } else if (fromOwner) {
      delete headers['helix-company-id'];
    }

    if (affiliateCompanyOverride && affiliateCompanyOverride.length) {
      headers['filter-affiliate-company-ids'] = affiliateCompanyOverride.join(',');
    }

    let response;
    let firstError;
    let tryToRefresh = false;

    try {
      response = await this.http[action](`${this.baseUrl}/${url}`, {
        headers,
        params: params || {},
      });
    } catch (ex) {
      firstError = ex;
      tryToRefresh = !tryToRefresh && ex.status === 401;
    }

    if (!response && tryToRefresh) {
      let newAccessToken = '';
      try {
        newAccessToken = await this._refreshToken();
      } catch (ex) {
        console.log(ex);
      }

      // only try to refresh once
      if (newAccessToken) {
        accessToken = newAccessToken;
        headers.Authorization = `Bearer ${accessToken}`;

        try {
          response = await this.http[action](`${this.baseUrl}/${url}`, {
            headers,
            params: params || {},
          });
        } catch (ex) {

        }
      } else if (Vue.prototype.$globals.routeRequiresAuth || Vue.prototype.$globals.showSiteLoader) {
        // only force logout if app is still loading or route requires auth
        Vue.prototype.$globals.forceLogout();
      } else {
        console.log('failed to refresh');
      }
    }

    if (response) {
      return this._handleResponse(response);
    }
    throw firstError;
  }

  async request(action, url, data, options) {
    if (waitForRefreshPromise) {
      await waitForRefreshPromise;
    }

    const companyIdOverride = (options && options.companyIdOverride) || null;
    const fromOwner = options && options.fromOwner;

    let accessToken = Vue.prototype.$globals.accessToken || '';

    const headers = {
      'Content-Type': 'application/json',
    };

    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }

    _addGlobalHeaderKeys(headers);

    if (companyIdOverride !== null) {
      headers['helix-company-id'] = `${companyIdOverride}`;
    } else if (fromOwner) {
      delete headers['helix-company-id'];
    }

    let response;
    let firstError;
    let tryToRefresh = false;

    try {
      response = await this.http[action](`${this.baseUrl}/${url}`, data, {
        headers,
      });
    } catch (ex) {
      firstError = ex;
      tryToRefresh = !tryToRefresh && ex.status === 401;
    }

    if (!response && tryToRefresh) {
      let newAccessToken = false;
      try {
        newAccessToken = await this._refreshToken();
      } catch (ex) {
        console.log(ex);
      }

      // only try to refresh once
      if (newAccessToken) {
        accessToken = newAccessToken;
        headers.Authorization = `Bearer ${accessToken}`;

        try {
          // re-try the call if we had a refresh success
          response = await this.http[action](`${this.baseUrl}/${url}`, data, {
            headers,
          });
        } catch (ex) {

        }
      } else {
        // failed to refresh
        Vue.prototype.$globals.forceLogout();
      }
    }

    if (response) {
      return this._handleResponse(response);
    }
    throw firstError;
  }
}
