import BaseModel from './BaseModel';

export default class EquipmentTypeModel extends BaseModel {
  init(data) {
    this.equipment_type_name = data.equipment_type_name || 'Unknown';
    this.equipment_type_id = data.equipment_type_id || 0;
    this.count = data.count || 0;
  }

  get chartData() {
    return [this.equipment_type_name, this.count];
  }
}
