import { convertHexToRGB, convertSecondsToStopwatch, getCurrentDateNoTime, getNumberOfDays } from '@/util';
import BaseModel from './BaseModel';
import TaskModel from './TaskModel';
import TaskStatusModel from './TaskStatusModel';
import UserModel from './UserModel';
import TaskTimeModel from './TaskTimeModel';
import { TaskChecklistModel } from './TaskChecklistModel';

export default class TaskInstanceModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.archived = data.archived || false;
    this.due_date = data.due_date || ''; // 2021-01-01
    this.completed = data.completed || false;
    this.task = new TaskModel(data.task); // { id, name, description }
    this.show_delete_task_option = data.show_delete_task_option || false; // true if has many or is recurring
    this.completedDate = data.completed_date || '';
    this.completed_by = data.completed_by ? new UserModel(data.completed_by) : null;

    this.time_tracking_log = (data.time_tracking_log || []).map(t => new TaskTimeModel(t));
    this.time_tracking_total_in_seconds = data.time_tracking_total_in_seconds || 0;
    this.my_active_time_tracking = data.my_active_time_tracking ? new TaskTimeModel(data.my_active_time_tracking) : {};

    this.checklists = (data.checklists || []).map(c => new TaskChecklistModel(c, { persisted: true }));
    this.status = data.status ? new TaskStatusModel(data.status) : {};

    this.is_current_instance = data.is_current_instance || false;

    this.exporting = false;
  }

  get timeTrackingTotalDisplayValue() {
    return convertSecondsToStopwatch(this.time_tracking_total_in_seconds);
  }

  get canComplete() {
    return this.is_current_instance || false;
  }

  get priorityDisplay() {
    return this.task.priorityDisplay;
  }

  get completedDateDate() {
    return this.stringToDate(this.completedDate);
  }

  get priority() {
    return this.task.priority;
  }

  get taskHasRecurrence() {
    return this.show_delete_task_option;
  }

  get rfp() {
    return this.task.rfp;
  }

  get location() {
    return this.task.location;
  }

  get taskCreated() {
    return this.task?.created;
  }

  get creator() {
    return this.task.creator;
  }

  get simpleCreator() {
    return this.task.simpleCreator;
  }

  get completedByDisplay() {
    return this.completed_by ? this.completed_by.fullName : BaseModel.defaultDisplayValue;
  }

  get created() {
    return this.task.created;
  }

  get part() {
    return this.task.part;
  }

  get equipment() {
    return this.task.equipment;
  }

  get name() {
    return this.task.name;
  }

  get description() {
    return this.task.description;
  }

  get taskId() {
    return this.task.id;
  }

  get dueDateDate() {
    if (!this.due_date) {
      return null;
    }
    const dateParts = this.due_date.split('-');
    return new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
  }

  get dueInDaysClass() {
    if (this.due_date && !this.completed && !this.archived) {
      const dueDate = this.dueDateDate;
      const now = new Date();

      if (dueDate > now) {
        return '';
      }
      return 'red';
    }
    return '';
  }

  get overdue() {
    if (this.due_date && !this.completed && !this.archived) {
      return this.dueDateDate < getCurrentDateNoTime();
    }
    return false;
  }

  get statusClass() {
    if (this.overdue) {
      return 'inactive';
    }
    if (this.archived) {
      return 'archived';
    }
    return this.completed ? 'complete' : 'active';
  }

  get dueInDays() {
    if (this.due_date && !this.completed && !this.archived) {
      const dueDate = this.dueDateDate;
      const now = new Date();

      if (dueDate > now) {
        // in days
        const numberDays = getNumberOfDays(now, dueDate);
        return `${numberDays} Day${numberDays === 1 ? '' : 's'}`;
      }
      return 'Overdue';
    }

    return '';
  }

  get dueDateFormattedStr() {
    if (!this.due_date) {
      return null;
    }
    const dateParts = this.due_date.split('-');
    if (dateParts.length === 3) {
      const month = parseInt(dateParts[1]);
      const day = parseInt(dateParts[2]);
      return `${month >= 10 ? month : `0${month}`}/${day >= 10 ? day : `0${day}`}/${dateParts[0]}`;
    }
    return '';
  }

  get displayStatus() {
    if (this.archived) {
      return 'Skipped';
    }
    if (this.overdue) {
      return 'Overdue';
    }
    return this.completed ? 'Completed' : 'Active';
  }

  get assignment() {
    return this.task.assignment;
  }

  get equipmentName() {
    return this.task.equipmentName;
  }

  get partName() {
    return this.task.partName;
  }

  get proposalName() {
    return this.task.proposalName;
  }

  get projectName() {
    return this.task.projectName;
  }

  get location_name() {
    return this.task.location_name;
  }

  get textColorClass() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return this.completed ? 'bg-green' : (this.dueDateDate < today ? 'bg-red' : 'bg-blue');
  }

  get projectColor() {
    return `#${this.task.project.color}`;
  }

  get taskTypeDisplay() {
    return this.task.taskTypeDisplay;
  }

  get taskTypeDisplayLower() {
    return this.task.taskTypeDisplayLower;
  }

  projectColorWithAlpha(alpha = 1) {
    const { r, g, b } = convertHexToRGB(this.projectColor);
    return `rgba(${r},${g},${b},${alpha})`;
  }
}
