import Vue from 'vue';
import { riskScoreLevelHigh, riskScoreLevelMedium } from '@/constants/options';
import BaseModel from './BaseModel';
import RiskAssessmentQuestionModel from './RiskAssessmentQuestionModel';
import UserModel from './UserModel';
import { titleCase, isNullOrUndefined } from '../util';

export default class RiskAssessmentSurveyModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.name = data.name || '';
    this.summary = data.summary || '';
    this.description = data.description || '';
    this.response_count = data.response_count || 0;
    this.version = data.version || 0;

    if (!isNullOrUndefined(data.risk_score)) {
      this.risk_score = data.risk_score;
    } else if (!isNullOrUndefined(data.most_recent_risk_score)) {
      this.risk_score = data.most_recent_risk_score;
    } else {
      this.risk_score = null;
    }

    this.questions = [];

    this.company_response_count = data.company_response_count || 0;
    this.company = data.company || null;
    this.created = data.created ? new Date(data.created) : null;
    this.submitted = data.submitted || false;
    this.submission_date = data.submission_date ? new Date(data.submission_date) : null;
    this.submission_user = new UserModel(data.submission_user);

    if (data.current_question_set) {
      this.questionSetId = data.current_question_set.id;
      this.version = data.current_question_set.version;
      this.questions = (data.current_question_set.questions || []).map(q => new RiskAssessmentQuestionModel(q));
      if (data.current_question_set.survey) {
        this.name = data.current_question_set.survey.name;
      }
    } else if (data.question_set) {
      this.questionSetId = data.question_set.id;
      this.version = data.question_set.version;
      this.questions = (data.question_set.questions || []).map(q => new RiskAssessmentQuestionModel(q));
      if (data.question_set.survey) {
        this.name = data.question_set.survey.name;
      }
    }

    if (data.question_responses) {
      data.question_responses.forEach(({ question_id, response }) => {
        const question = this.questions.find(q => q.id === question_id);
        if (question) {
          question.setFormValue(response);
        }
      });
    }
  }

  get riskLevel() {
    if (this.risk_score > riskScoreLevelHigh) {
      return 'High';
    }
    if (this.risk_score > riskScoreLevelMedium) {
      return 'Medium';
    }
    return 'Low';
  }

  get riskLevelClass() {
    if (this.risk_score === null) {
      return '';
    }
    if (this.risk_score > riskScoreLevelHigh) {
      return 'risk-high';
    }
    if (this.risk_score > riskScoreLevelMedium) {
      return 'risk-med';
    }
    return 'risk-low';
  }

  get status() {
    if (this.submitted) {
      return 'completed';
    }
    return 'draft';
  }

  get displayStatus() {
    if (this.submitted) {
      let status = `Completed by ${this.submission_user.fullName}`;

      if (this.company && Vue.prototype.$globals.selectedDashboard === 'affiliate') {
        status += ` for ${this.company.name}`;
      }

      return status;
    }
    return titleCase(this.status);
  }

  get title() {
    return this.name;
  }

  get descriptionHTML() {
    return this.getHtmlDisplayValue(this.description);
  }

  get numberOfQuestions() {
    const questionCount = this.questions.filter(q => !q.question_type.startsWith('ui')).length;
    return `${questionCount} Question${questionCount === 1 ? '' : 's'}`;
  }

  get companyName() {
    return this.company?.name || '';
  }

  get submissionUserFullName() {
    return this.submission_user?.fullName || '';
  }

  get formData() {
    const data = {};
    this.questions.forEach((q) => {
      const val = q.getFormValue();
      data[q.id] = val;
    });
    return data;
  }
}
