import BaseModel from './BaseModel';
import FileModel from './FileModel';
import { formatPhoneNumber } from '../filters/formatters.js';

export default class VendorCompanyModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.city = data.city || '';
    this.id = data.id || '';
    if (typeof data.logo === 'string') {
      this.logo = new FileModel({
        preview_url: data.logo,
      });
    } else {
      this.logo = data.logo ? new FileModel(data.logo) : null;
    }
    this.name = data.name || '';
    this.state = data.state || '';
    this.description = data.description || '';

    this.email = data.email || '';
    this.hours_of_operation = data.hours_of_operation || '';
    this.location = data.location || '';
    this.website = data.website || '';
    this.phone = data.phone || '';
    this.phone_ext = data.phone_ext || '';
    this.street_address = data.street_address || '';
    this.street_address_line_2 = data.street_address_line_2 || '';
    this.zip = data.zip || '';

    this.latitude = data.latitude || (data.location && data.location.latitude) || 0;
    this.longitude = data.longitude || (data.location && data.location.longitude) || 0;

    this.vendor_hub_featured = 'vendor_hub_featured' in data ? data.vendor_hub_featured : false;
    this.vendor_hub_special_offer_title = data.vendor_hub_special_offer_title || '';
    this.vendor_hub_special_offer = data.vendor_hub_special_offer || '';
  }

  get cityStateDisplay() {
    if (this.city && this.state) {
      return `${this.city}, ${this.state}`;
    }

    return this.city || this.state || '';
  }

  get display_label() {
    return this.name;
  }

  get formattedPhone() {
    return formatPhoneNumber(this.phone) || '';
  }

  get fullPhone() {
    if (this.phone_ext) {
      return `${this.formattedPhone} x${this.phone_ext}`;
    }

    return this.formattedPhone;
  }

  get hoursJson() {
    if (typeof this.hours_of_operation === 'object') {
      return this.hours_of_operation;
    }
    return this.hours_of_operation && JSON.parse(this.hours_of_operation);
  }

  get position() {
    if (!this.latitude && !this.longitude) {
      return null;
    }

    return {
      lat: this.latitude,
      lng: this.longitude,
    };
  }
}
