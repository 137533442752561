import BaseModel from './BaseModel';
import CopyableMixin from './CopyableMixin';

export default class RFPEventModel extends CopyableMixin(BaseModel) {
  init(data) {
    this.created = data.created ? new Date(data.created) : null;
    this.event_type = data.event_type || '';
    this.extra = data.extra || {};
  }

  hasNonOtherReason() {
    return this.reason && this.reason.toLowerCase() !== 'other';
  }

  get reason() {
    return this.extra.reason || '';
  }

  get reasonDetails() {
    return this.extra.reason_details || '';
  }
}
