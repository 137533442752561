import BaseModel from './BaseModel';

export default class AffiliateManagerModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.userId = data.user_id || 0;
    this.email = data.email || '';
    this.first_name = data.first_name || '';
    this.last_name = data.last_name || '';

    this.can_administer_affiliate_managers = data.can_administer_affiliate_managers || false;
    this.can_administer_companies = data.can_administer_companies || false;
    this.can_verify_equipment_data = data.can_verify_equipment_data || false;
    this.is_risk_manager = data.is_risk_manager || false;
    this.can_download_company_data = data.can_download_company_data || false;
    this.can_download_property_data = data.can_download_property_data || false;
    this.can_download_user_data = data.can_download_user_data || false;
  }

  get display_label() {
    return `${this.first_name} ${this.last_name}`;
  }
}
