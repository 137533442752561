import BaseModel from './BaseModel';
import CompanyModel from './CompanyModel';

export default class QuotedCompanyModel extends BaseModel {
  init(data) {
    this.id = data.id || data.company_id || 0;
    this.name = data.name || '';
    this.reason = data.reason || '';
    this.reason_details = data.reason_details || '';
    this.status = data.status || '';
    this.last_status_update = data.last_status_update ? new Date(data.last_status_update) : null;

    this.company = data.company ? new CompanyModel(data.company) : null;

    if (!this.id && this.company) {
      this.id = this.company.id;
    }
  }

  get company_id() {
    if (this.company) {
      return this.company.id;
    }
    return this.id;
  }

  get displayName() {
    if (this.name) {
      return this.name;
    }
    return (this.company && this.company.name) || '';
  }

  get display_label() {
    return this.displayName;
  }

  get email() {
    if (this.company && this.company.contact_email) {
      return this.company.contact_email;
    }
    return (this.company && (this.company.email_address || this.company.first_admin_email)) || '';
  }

  get quoteStatus() {
    if (this.status === 'matched') {
      return 'Matched';
    }

    if (this.status === 'quoted') {
      return 'Quoted';
    }

    if (this.status === 'sp_declined') {
      return 'Vendor Declined';
    }

    if (['eo_canceled', 'owner_canceled'].includes(this.status)) {
      return 'Client Canceled';
    }

    if (this.status === 'eo_declined' || this.status === 'owner_declined' || this.status === 'other_sp_accepted') {
      return 'Client Declined';
    }

    if (this.status === 'rejected') {
      return 'Rejected';
    }

    if (this.status === 'accepted') {
      return 'Accepted';
    }
    return '';
  }

  get declined() {
    return ['sp_declined', 'eo_declined', 'owner_declined', 'rejected', 'other_sp_accepted', 'eo_canceled', 'owner_canceled'].indexOf(this.status) !== -1;
  }

  get accepted() {
    return this.status === 'accepted';
  }
}
