import Vue from 'vue';
import BaseAPIClient from './base/BaseAPIClient';
import NotificationModel from '../models/NotificationModel';
import NotificationPreferencesModel from '../models/NotificationPreferencesModel';
import ListResponseModel from '../models/ListResponseModel';
import AnnouncementModel from '../models/AnnouncementModel';

export default class NotificationClient extends BaseAPIClient {
  getCurrentUserNotifications({
    offset,
    limit,
    read,
    seen,
    cleared,
    dateAfter,
    dateBefore,
    filterGroup,
  }) {
    const params = {};

    if (typeof offset !== 'undefined') {
      params.offset = offset;
    }

    if (typeof limit !== 'undefined') {
      params.limit = limit;
    }

    if (typeof read !== 'undefined') {
      params.read = read;
    }

    if (typeof seen !== 'undefined') {
      params.seen = seen;
    }

    if (typeof cleared !== 'undefined') {
      params.cleared = cleared;
    }

    if (dateAfter) {
      params.date_after = dateAfter;
    }

    if (dateBefore) {
      params.date_before = dateBefore;
    }

    if (typeof filterGroup !== 'undefined') {
      params.filter_group = filterGroup;
    }

    return this._get('notifications/', params, { fromOwner: Vue.prototype.$globals.selectedDashboard === 'owner' }).then(m => new ListResponseModel(m, NotificationModel));
  }

  getBatchNotification(id) {
    return this._get(`notifications/batch_records/${id}/`).then(x => new NotificationModel(x));
  }

  getNotificationCounts() {
    return this._get('notifications/counts/', null, { fromOwner: Vue.prototype.$globals.selectedDashboard === 'owner' }).then(m => m.unseen_count || 0);
  }

  notificationsSeen() {
    const opts = { fromOwner: Vue.prototype.$globals.selectedDashboard === 'owner' };
    return this._post('notifications/seen/', null, opts);
  }

  clearAllNotifications() {
    const opts = { fromOwner: Vue.prototype.$globals.selectedDashboard === 'owner' };
    return this._post('notifications/cleared/', null, opts);
  }

  markNotificationRead(id) {
    return this._patch(`notifications/${id}/`, { read: true });
  }

  setNotificationCleared(id, cleared) {
    return this._patch(`notifications/${id}/`, { cleared });
  }

  getAnnouncements(offset, limit) {
    return this._get('announcements/', {
      offset,
      limit,
    }).then(m => new ListResponseModel(m, AnnouncementModel));
  }

  getFeaturedAnnouncement() {
    return this._get('announcements/featured/').then(m => new AnnouncementModel(m));
  }

  clearAnnouncement(id) {
    return this._post(`announcements/${id}/dismiss/`);
  }

  getNotificationFilterGroups() {
    return this._get('notifications/groups/');
  }

  getNotificationPreferences() {
    return this._get('company/notification_preferences/').then(p => new NotificationPreferencesModel(p));
  }

  setNotificationPreferences(field_name, value) {
    const data = {};
    data[field_name] = value;

    return this._patch('company/notification_preferences/', data).then(p => new NotificationPreferencesModel(p));
  }
}
