import BaseModel from './BaseModel';

export default class SettingsModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.identifier = data.identifier || '';
    this.description = data.description || '';
    this.boolean_value = data.boolean_value || false;

    // not used yet
    this.integer_value = data.integer_value || 0;
    this.string_value = data.string_value || '';
  }
}
