import BaseModel from './BaseModel';
import { PropertyLocationModel } from './LocationModels';

export default class LocationEquipmentModel extends BaseModel {
  init(data) {
    this.location = data.location ? PropertyLocationModel.factory(data.location) : null;
    this.equipment_count = data.equipment_count || 0;
  }

  get chartData() {
    const label = this.location.display_label.length >= 20
      ? `${this.location.display_label.substr(0, 19)}...`
      : this.location.display_label;
    return [label, this.equipment_count];
  }
}
