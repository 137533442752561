import BaseModel from './BaseModel';
import { PropertyLocationModel } from './LocationModels';
import { nullableEpochDate } from '../util';

class SensorDeviceAlertModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.alert_id = data.alert_id || '';
    this.name = data.name || '';
    this.timestamp = nullableEpochDate(data.timestamp);
    this.status = data.status || '';
  }
}

export default class SensorDeviceModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.device_id = data.device_id || 0;
    this.name = data.name || '';
    this.activated = 'activated' in data ? data.activated : false;
    this.active = 'active' in data ? data.active : false;
    this.offline = 'offline' in data ? data.offline : false;
    this.location = data.location ? PropertyLocationModel.factory(data.location) : null;
    this.location_name = data.location_name || '';
    this.has_low_signal = 'has_low_signal' in data ? data.has_low_signal : false;
    this.has_low_battery = 'has_low_battery' in data ? data.has_low_battery : false;
    this.battery_value = data.battery_value || null;
    this.last_check_in = nullableEpochDate(data.last_check_in);
    this.next_check_in = nullableEpochDate(data.next_check_in);
    this.folder = data.folder || {};
    this.use_case = data.use_case || '';
    this.is_gateway = 'is_gateway' in data ? data.is_gateway : false;
    this.is_sensor = 'is_sensor' in data ? data.is_sensor : false;
    this.alerts = (data.alerts || []).map(a => new SensorDeviceAlertModel(a));
  }

  get cleanedLocationName() {
    // temporary bandaid, this should be cleaned up API side
    return this.location_name.replace(/^[’'"]+|[’'"]+$/g, '');
  }

  get folderName() {
    return this.folder?.name || '';
  }

  get locationName() {
    return (this.location && this.location.fullAncestryPath) || '';
  }

  get deviceType() {
    return this.is_sensor ? 'Sensor' : (this.is_gateway ? 'Gateway' : 'Unknown');
  }

  get display_label() {
    return this.name;
  }
}
