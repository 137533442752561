import { formatPhoneNumber } from '@/filters/formatters';
import BaseModel from './BaseModel';

export default class RelatedCompanyContactModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.name = data.name || '';
    this.title = data.title || '';
    this.cell = data.cell || '';
    this.email = data.email || '';
    this.fax = data.fax || '';
    this.phone = data.phone || '';
    this.phone_ext = data.phone_ext || '';

    this.deleted = false;
    this.dirty = false;
  }

  get formattedPhone() {
    let phone = formatPhoneNumber(this.phone) || '';
    if (phone && this.phone_ext) {
      phone += ` x${this.phone_ext}`;
    }
    return phone;
  }

  get formattedCell() {
    return formatPhoneNumber(this.cell) || '';
  }

  get display_label() {
    return this.name;
  }

  get phoneIcon() {
    if (this.cell) {
      return 'phone-square';
    }
    if (this.phone) {
      return 'fax';
    }
    return null;
  }

  matchesSearch(search) {
    const lowerName = this.name.toLowerCase();
    return !search || lowerName.includes(search.toLowerCase());
  }
}
