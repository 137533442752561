import CompanyEquipmentModel from './CompanyEquipmentModel';

export default class PartModel extends CompanyEquipmentModel {
  init(data) {
    super.init(data);
    // optional
    this.part_number = data.part_number || '';
    this.description = data.description || '';
    this.quantity_on_hand = data.quantity_on_hand != null ? parseInt(data.quantity_on_hand) : null;
    this.target_quantity = data.target_quantity != null ? parseInt(data.target_quantity) : null;
    this.purchase_cost = data.purchase_cost ? parseFloat(data.purchase_cost) : null;

    this.equipments_requiring_part = (data.equipments_requiring_part || []).map(e => new CompanyEquipmentModel(e));

    this.quantity = parseInt(data.quantity) || 1;// for edit form

    this.href = `${document.location.origin}/part/${this.id}`;

    this.has_inventory_below_target = data.has_inventory_below_target || false;
    this.archived = 'archived' in data ? data.archived : false;
  }

  get _quantity_on_hand() {
    return (this.quantity_on_hand || '').toString();
  }

  set _quantity_on_hand(v) {
    this.quantity_on_hand = v;
  }

  get _quantity() {
    return this.quantity.toString();
  }

  set _quantity(v) {
    this.quantity = v;
  }

  get _target_quantity() {
    return (this.target_quantity || '').toString();
  }

  set _target_quantity(v) {
    this.target_quantity = v;
  }

  get descriptionHtml() {
    return this.getHtmlDisplayValue(this.description);
  }

  get display_label() {
    return this.name;
  }

  get hasLowInventory() {
    return !!this.lowInventoryTableLabel;
  }

  get lowInventoryTableLabel() {
    if (this.quantity_on_hand < this.target_quantity) {
      return 'Low Inventory';
    }

    if (this.has_inventory_below_target) {
      return 'Bin(s) w/ Low Inventory';
    }

    return '';
  }

  get lowInventoryIconClass() {
    if (this.quantity_on_hand < this.target_quantity) {
      return 'red';
    }

    if (this.has_inventory_below_target) {
      return 'yellow';
    }

    return '';
  }
}
