import Vue from 'vue';

import CachedCompanyData from '@/cache/CachedCompanyData';
import { PropertyLocationModel } from '@/models/LocationModels';

export default class CachedLocationTreeUiData extends CachedCompanyData {
  async retrieveData() {
    const response = await Vue.prototype.$globals.companyClient.getCompanyLocationTreeUiData();
    return response.items;
  }

  getCacheExpiration() {
    return 10 * 60 * 1000;
  }

  locationAdded(location) {
    if (!this.cachedData) return;

    if (this.cachedData.find(l => l.id === location.id)) {
      this.locationUpdated(location);
      return;
    }
    this.cachedData.push(new PropertyLocationModel(location));
  }

  locationUpdated(location) {
    if (!this.cachedData) return;

    // if the location was archived, remove it from the list
    if (location.archived) {
      const index = this.cachedData.map(e => e.id).indexOf(location.id);
      if (index >= 0) {
        this.cachedData.splice(index, 1);
      }
      return;
    }

    const existingLocation = this.cachedData.find(l => l.id === location.id);
    if (!existingLocation) {
      this.locationAdded(location);
    }
    existingLocation.name = location.name;
    existingLocation.parent_id = location.parent_id;
  }
}
