import Vue from 'vue';
import FileModel from './FileModel';
import CompanyModel from './CompanyModel';
import BaseModel from './BaseModel';
import CopyableMixin from './CopyableMixin';

export default class InvoiceModel extends CopyableMixin(BaseModel) {
  init(data) {
    this.id = data.id || 0;
    this.is_final_invoice = data.is_final_invoice || false;

    this.rfp = data.rfp || null; // {id, name, reference_number }

    this.reference_number = data.reference_number || '';
    this.invoice_number = data.invoice_number || '';
    this.service_description = data.service_description || '';

    this.provider = data.provider ? new CompanyModel(data.provider) : null;
    this.customer = data.customer ? new CompanyModel(data.customer) : null;

    this.subtotal_price = data.subtotal_price ? parseFloat(data.subtotal_price) : null;
    this.shipping_price = data.shipping_price ? parseFloat(data.shipping_price) : null;
    this.tax_price = data.tax_price ? parseFloat(data.tax_price) : null;

    this.terms = data.terms || '';
    this.terms_other = data.terms_other || '';

    if (this.terms_other) {
      this.terms = 'other';
    }

    this.helix_fee_percentage = parseFloat(data.helix_fee_percentage) || 0;

    this.attachments = (data.attachments || []).map(f => new FileModel(f));

    this.created = data.created ? new Date(data.created) : null;

    this.charge = data.charge || null;

    this.canceled = data.canceled || data.cancelled || false;

    this.direct_payment_link = data.direct_payment_link || '';

    // CC is 2.9% + 30c
    // return ((this.quotedTotal * 0.029) + 0.3) || 0;

    // ACH is 0.8% (5$ cap)
    // const fee = (this.quotedTotal * 0.008) || 0;
    // return fee > 5 ? 5 : fee;

    this.stripe_fee = data.stripe_fee || 0; // logged after a payment completes via webhook

    this.due_date = data.due_date || '';

    this.notes = data.notes || '';
  }

  get serviceDescriptionHtml() {
    return this.getHtmlDisplayValue(this.service_description);
  }

  get notesHTML() {
    return this.getHtmlDisplayValue(this.notes);
  }

  get dueDateDate() {
    return this.stringToDate(this.due_date);
  }

  copyFromQuote(data) {
    this.invoice_number = data.proposal_number || '';
    this.service_description = data.service_description || '';
    this.subtotal_price = data.subtotal_price || null;
    this.shipping_price = data.shipping_price || null;
    this.tax_price = data.tax_price || null;
    this.terms = data.terms || '';
    this.terms_other = data.terms_other || '';
  }

  get hasCustomPaymentLink() {
    return !!(this.direct_payment_link || (this.provider && this.provider.third_party_payment_info));
  }

  get customPaymentLink() {
    if (!this.hasCustomPaymentLink) {
      return '';
    }

    return this.direct_payment_link || (this.provider.third_party_payment_info.payment_link) || '';
  }

  get service_provider() {
    return this.provider && this.provider.name;
  }

  get client() {
    return this.customer && this.customer.name;
  }

  get statusDisplay() {
    return this.status;
  }

  get application_fee_amount() {
    return (this.charge && this.charge.application_fee_amount) || 0;
  }

  get charge_stripe_fee() {
    return (this.charge && this.charge.stripe_fee) || 0;
  }

  get canPay() {
    return !this.paid && !this.canceled;
  }

  get canCancel() {
    return !this.paid && !this.canceled;
  }

  get canEdit() {
    return this.canceled;
  }

  get canResend() {
    return this.canceled;
  }

  get displayTerms() {
    if (this.terms_other) {
      return this.terms_other;
    }

    if (this.terms && this.terms === 'payment_in_advance') {
      return 'Payment in Advance';
    }

    if (this.terms && this.terms !== 'other') {
      const spl = this.terms.split('_');
      if (spl.length > 1) {
        return `${spl[0].toUpperCase()} ${spl[1]}`;
      }
    }
    return '';
  }

  get rfpId() {
    if (this.rfp && this.rfp.reference_number) {
      return this.rfp.reference_number;
    }

    return this.rfp;
  }

  get blockPayment() {
    // EO can not pay on stripe, but can use third party link
    return this.charge && this.charge.offline_payment;
  }

  get status() {
    let statusName = 'Not Paid';

    if (this.charge) {
      if (this.charge.status === 'paid' || this.charge.status === 'paid_offline') {
        statusName = 'Paid';
      } else if (this.charge.status === 'unpaid') {
        statusName = 'Unpaid';
      } else if (this.charge.status === 'payment_pending') {
        statusName = Vue.prototype.$globals.selectedDashboard === 'sp' ? 'Payment Processing' : 'Payment Sent';
      } else {
        statusName = (this.charge.status || '');
      }
    }

    if (this.canceled) {
      statusName += ' - Canceled';
    }

    return statusName;
  }

  get paid() {
    return this.charge && (this.charge.status === 'paid' || this.charge.status === 'paid_offline' || this.charge.status === 'payment_pending');
  }

  get paymentDate() {
    if (this.charge && this.charge.payment_date) {
      return new Date(this.charge.payment_date);
    }
    return null;
  }

  get quotedSubTotal() {
    if (this.subtotal_price < 0) {
      return 0;
    }

    return this.subtotal_price || 0;
  }

  get quotedShipping() {
    if (this.shipping_cost_unknown || this.shipping_price < 0) {
      return 0;
    }
    return this.shipping_price || 0;
  }

  get quotedTax() {
    if (this.tax_cost_unknown || this.tax_price < 0) {
      return 0;
    }
    return this.tax_price || 0;
  }

  get quotedTotal() {
    return this.quotedSubTotal + this.quotedShipping + this.quotedTax;
  }

  calculateFee(fee) {
    return this.quotedSubTotal * (fee / 100);
  }

  calculateNet(fee) {
    return (this.quotedTotal - this.calculateFee(fee) - this.stripe_fee) || 0;
  }
}
