import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/constants/routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    if (to && from && to.name === from.name) {
      return null;
    }

    return { x: 0, y: 0 };
  },
});

// override push function to ignore navigation errors
// since the beforeEach is called after this error throws we can't check for to === from and call next(false); like you may expect ..
const originalPush = router.push;
router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }

  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      return err;
    }

    return Promise.reject(err);
  });
};

router.beforeEach(async (to, from, next) => {
  if (Vue.prototype.$globals.hasNewVersion && from.name && to.name !== from.name) {
    window.location.href = to.fullPath;
    return;
  }

  const isAuthorized = !!Vue.prototype.$globals.user();
  const loadingApp = Vue.prototype.$globals.showSiteLoader;

  const anonymousAccessPages = routes.filter(r => r.allowAnonymous).map(r => r.name);

  if (!loadingApp && !isAuthorized && anonymousAccessPages.indexOf(to.name) === -1) {
    Vue.prototype.$globals.clearUser();
    router.replace('/login');
    return;
  }

  await Vue.prototype.$globals.onRouteChange(to, from);
  Vue.prototype.$globals.routeRequiresAuth = !to.allowAnonymous;

  if (Vue.prototype.$globals.routeRequiresAuth) {
    const currentPath = (to && to.fullPath) || '';
    const isActivating = currentPath.indexOf('/reset/') !== -1 || currentPath.indexOf('/verify/') !== -1 || currentPath.indexOf('/invite/') !== -1 || currentPath.indexOf('/confirm/') !== -1 || currentPath.indexOf('onboarding/complete') !== -1 || currentPath.indexOf('users/plugin') !== -1;
    Vue.prototype.$globals.routeRequiresAuth = !isActivating;
  }

  if (to.matched.length) {
    if (to.meta.title) {
      document.title = `HelixIntel | ${to.meta.title}`;
    } else {
      document.title = 'HelixIntel';
    }

    next();
  }

  if (Vue.prototype.$gtm && Vue.prototype.$gtm.trackView) {
    Vue.prototype.$gtm.trackView(to.name, to.fullPath);
  }

  setTimeout(() => {
    Vue.prototype.$globals.trackPage();
  }, 300);
});

export default router;
