import BaseModel from './BaseModel';
import CompanyModel from './CompanyModel';
import UserModel from './UserModel';
import FileModel from './FileModel';
import { titleCase } from '../util';
import PaymentMethodModel from './PaymentMethodModel';
import { IncentiveLocationModel } from './LocationModels';
import IncentiveReportPromoCodeModel from './IncentiveReportPromoCodeModel';

export default class IncentiveReportModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.paid = data.paid || false;
    this.property_count = data.property_count || 0;
    this.created = data.created ? new Date(data.created) : null;
    this.submission_date = data.submission_date ? new Date(data.submission_date) : null;
    this.user = data.user ? new UserModel(data.user) : null;

    // draft / submitted / processing / preliminary_results / complete
    this.status = data.status || '';

    this.payment_status = data.payment_status || '';
    this.external_id = data.external_id || '';
    this.deleted = data.deleted || false;

    this.promo_code = data.promo_code ? new IncentiveReportPromoCodeModel(data.promo_code) : null;

    this.total_price = data.total_price || 0;

    // admin route only
    this.company = data.company ? new CompanyModel(data.company) : null;

    // single only
    this.properties = (data.properties || []).map(property => new IncentiveLocationModel(property));
    this.customer_first_name = data.customer_first_name || '';
    this.customer_last_name = data.customer_last_name || '';
    this.customer_job_title = data.customer_job_title || '';
    this.customer_email = data.customer_email || '';
    this.customer_phone = data.customer_phone || '';
    this.customer_phone_ext = data.customer_phone_ext || '';
    this.billing_street_address = data.billing_street_address || '';
    this.billing_street_address_line_2 = data.billing_street_address_line_2 || '';
    this.billing_city = data.billing_city || '';
    this.billing_state = data.billing_state || '';
    this.billing_zip = data.billing_zip || '';
    this.billing_phone = data.billing_phone || '';
    this.billing_phone_ext = data.billing_phone_ext || '';

    this.request_xlsx = data.request_xlsx ? new FileModel(data.request_xlsx) : null;
    this.report_file = data.report_file ? new FileModel(data.report_file) : null;
    this.external_report_url = data.external_report_url || '';

    this.payment_method = data.payment_method ? new PaymentMethodModel(data.payment_method) : null;
    this.potential_savings = data.potential_savings || null;
    this.payment_date = data.payment_date ? new Date(data.payment_date) : null;

    this.is_free = data.is_free ?? false;
    this.free_message = data.free_message ?? '';
  }

  get name() {
    return this.external_id;
  }

  get itemNumber() {
    return this.name;
  }

  get amount() {
    return this.total_price;
  }

  get displayStatus() {
    return titleCase(this.status);
  }

  get displayPaymentStatus() {
    if (this.payment_status === 'invoice_requested') {
      return 'Invoice Requested';
    }
    if (this.payment_status === 'invoice_sent') {
      return 'Invoice Sent';
    }
    if (this.payment_status === 'invoice_paid') {
      return 'Invoice Paid';
    }
    if (this.payment_status === 'paid') {
      return 'Paid via Stripe';
    }
    return 'None';
  }

  get isPaid() {
    return ['invoice_paid', 'paid'].includes(this.payment_status);
  }

  get canComplete() {
    const isApiReport = this.properties.some(p => p.incentive_data);
    const allowCompleteForApiReport = isApiReport && (this.status === 'preliminary_results' || this.status === 'complete');
    return this.isPaid && (this.status === 'processing' || allowCompleteForApiReport);
  }

  get location() {
    return new IncentiveLocationModel({
      street_address: this.billing_street_address,
      street_address_line_2: this.billing_street_address_line_2,
      city: this.billing_city,
      state: this.billing_state,
      zip: this.billing_zip,
      phone: this.billing_phone,
      phone_ext: this.billing_phone_ext,
    });
  }
}
