export default class ListResponseModel {
  constructor(data, ResultsModel, constructor_args = {}) {
    if (!data) data = {};

    // if we end up with an array, just bail on the paging
    if (data.length) {
      this.results = (data || []).map(r => (ResultsModel.factory ? ResultsModel.factory(r) : new ResultsModel(r, constructor_args)));
      this.count = this.results.length;
      this.next = '';
      this.previous = '';
    } else {
      this.count = data.count || 0;
      this.next = data.next || '';
      this.previous = data.previous || '';
      this.results = (data.results || []).map(r => (ResultsModel.factory ? ResultsModel.factory(r) : new ResultsModel(r, constructor_args)));
    }

    const metaKeys = Object.keys(data).filter(c => !['count', 'next', 'previous', 'results'].includes(c));

    if (metaKeys) {
      this.meta = {};
      metaKeys.forEach(k => this.meta[k] = data[k]);
    }
  }

  get items() {
    return this.results;
  }
}
