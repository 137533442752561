import moment from 'moment';
import Vue from 'vue';
import { applyEquipmentTypeFilterToApiParams } from '@/util';
import BaseAPIClient from './base/BaseAPIClient';
import ListResponseModel from '../models/ListResponseModel';
import EquipmentStatusModel from '../models/EquipmentStatusModel';
import EquipmentTypeModel from '../models/EquipmentTypeModel';

import AffiliateModel from '../models/AffiliateModel';
import AffiliateCompanyModel from '../models/AffiliateCompanyModel';
import AffiliateEquipmentModel from '../models/AffiliateEquipmentModel';
import AffiliateUserModel from '../models/AffiliateUserModel';
import AffiliateFilterModel from '../models/AffiliateFilterModel';
import { AffiliatePropertyLocationModel } from '../models/LocationModels';
import AffiliateManagerModel from '../models/AffiliateManagerModel';
import AffiliateReportModel from '../models/AffiliateReportModel';
import { AffiliateKnowledgeCenterModel } from '../models/AffiliateKnowledgeCenterModel';
import RiskAssessmentSurveyModel from '../models/RiskAssessmentSurveyModel';
import { AffiliateUiSettingsModel } from '../models/UiSettingsModels';

export default class AffiliateClient extends BaseAPIClient {
  getAffiliates(search = '', ordering = '-id', limit = 50, offset = 0) {
    const params = {
      search,
      ordering,
      limit,
      offset,
    };
    return this._get('company/affiliates/', params).then(m => new ListResponseModel(m, AffiliateModel));
  }

  updateAffiliate(affiliateId, {
    color,
    login_logo_width,
  }) {
    const params = {
      color,
      login_logo_width,
    };

    return this._patch(`company/affiliates/${affiliateId}/`, params).then(a => new AffiliateModel(a));
  }

  updateAffiliateLogo(affiliateId, logo, type = 'admin_logo_upload') {
    const accessToken = Vue.prototype.$globals.accessToken || '';
    const url = `${Vue.prototype.$globalEnv.baseUrl}/company/affiliates/${affiliateId}/`;

    const data = new FormData();
    data.append(type, logo);

    // explicitly not setting the Content-Type header, the http client will automagically
    // set multipart and boundary header like so:
    // 'Content-Type': `multipart/form-data; boundary=xyz`,
    return fetch(url, {
      method: 'PATCH',
      body: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'helix-company-id': Vue.prototype.$globals.companyId,
      },
    });
  }

  getAffiliateCompanies(affiliateId, { search = '', ordering = '-id', format = '', limit = 50, offset = 0, filters = {}, companyFilters = [], requiresAffiliateDataVerification = null }) {
    const params = {
      search,
      ordering,
      limit,
      offset,
      ...filters,
    };

    if (requiresAffiliateDataVerification !== null) {
      params.requires_affiliate_data_verification = requiresAffiliateDataVerification;
    }

    let uri = `company/affiliates/${affiliateId}/companies/`;

    if (format) {
      params.format = format;
      if (format == 'csv') {
        uri += 'csv/';
      }
    }

    const options = {
      affiliateCompanyOverride: companyFilters,
    };

    const promise = this._get(uri, params, options);

    return format !== 'csv'
      ? promise.then(m => new ListResponseModel(m, AffiliateCompanyModel))
      : promise;
  }

  getAffiliateEquipment(affiliateId, { search = '', ordering = '-id', format = '', limit = 50, offset = 0, status = '', equipmentType = '', requiresAffiliateDataVerification = null }) {
    const params = {
      search,
      ordering,
      limit,
      offset,
    };

    if (status) {
      params.status = status;
    }

    applyEquipmentTypeFilterToApiParams(params, equipmentType);

    if (requiresAffiliateDataVerification !== null) {
      params.company__requires_affiliate_data_verification = requiresAffiliateDataVerification;
    }

    let uri = `company/affiliates/${affiliateId}/companies/equipment/`;

    if (format) {
      params.format = format;
      if (format == 'csv') {
        uri += 'csv/';
      }
    }

    const promise = this._get(uri, params);

    return format !== 'csv'
      ? promise.then(m => new ListResponseModel(m, AffiliateEquipmentModel))
      : promise;
  }

  getAffiliateUsers(affiliateId, search = '', ordering = '-id', format = '', limit = 50, offset = 0, filters = {}, companyFilters = []) {
    const params = {
      search,
      ordering,
      limit,
      offset,
      ...filters,
    };

    let uri = `company/affiliates/${affiliateId}/companies/members/`;

    if (format) {
      params.format = format;
      if (format == 'csv') {
        uri += 'csv/';
      }
    }

    const options = {
      affiliateCompanyOverride: companyFilters,
    };

    const promise = this._get(uri, params, options);

    return format !== 'csv'
      ? promise.then(m => new ListResponseModel(m, AffiliateUserModel))
      : promise;
  }

  getAffiliateLocations(
    affiliateId,
    {
      search = '',
      ordering = '-id',
      format = '',
      limit = 50,
      offset = 0,
      hasParent,
      requiresAffiliateDataVerification,
      mapBoundsFilter,
    },
  ) {
    const params = {
      search,
      ordering,
      limit,
      offset,
    };

    if (hasParent !== null) {
      params.has_parent = hasParent;
    }

    if (requiresAffiliateDataVerification !== null) {
      params.company__requires_affiliate_data_verification = requiresAffiliateDataVerification;
    }

    if (mapBoundsFilter) {
      params.map_bounds = mapBoundsFilter;
    }

    let uri = `company/affiliates/${affiliateId}/companies/locations/`;

    if (format) {
      params.format = format;
      if (format == 'csv') {
        uri += 'csv/';
      }
    }

    const promise = this._get(uri, params);

    return format !== 'csv'
      ? promise.then(m => new ListResponseModel(m, AffiliatePropertyLocationModel))
      : promise;
  }

  getAffiliateMapLocations(affiliateId, search = '', hasParent = undefined) {
    const params = {
      search,
    };

    if (hasParent !== undefined) {
      params.has_parent = hasParent;
    }

    return this._get(`company/affiliates/${affiliateId}/companies/locations/map/`, params).then(l => new ListResponseModel(l, AffiliatePropertyLocationModel));
  }

  getAffiliateManagers(affiliateId, search = '', ordering = '-id', limit = 50, offset = 0) {
    const params = {
      search,
      ordering,
      limit,
      offset,
    };
    return this
      ._get(`company/affiliates/${affiliateId}/managers/`, params)
      .then(m => new ListResponseModel(m, AffiliateManagerModel));
  }

  inviteAffiliateManager(affiliateId, { email, firstName, lastName, canAdministerAffiliateManagers = null, canVerifyEquipmentData = null, canAdministerCompanies = null, isRiskManger = null, canDownloadCompanies = false, canDownloadProperties = false, canDownloadUsers = false }) {
    const data = {
      email,
      first_name: firstName,
      last_name: lastName,
    };

    if (canVerifyEquipmentData !== null) {
      data.can_verify_equipment_data = canVerifyEquipmentData;
    }

    if (canAdministerCompanies !== null) {
      data.can_administer_companies = canAdministerCompanies;
    }

    if (canAdministerAffiliateManagers !== null) {
      data.can_administer_affiliate_managers = canAdministerAffiliateManagers;
    }

    if (isRiskManger !== null) {
      data.is_risk_manager = isRiskManger;
    }

    if (canDownloadCompanies !== null) {
      data.can_download_company_data = canDownloadCompanies;
    }

    if (canDownloadProperties !== null) {
      data.can_download_property_data = canDownloadProperties;
    }

    if (canDownloadUsers !== null) {
      data.can_download_user_data = canDownloadUsers;
    }

    return this._post(`company/affiliates/${affiliateId}/managers/`, data);
  }

  updateAffiliateManager(affiliateId, managerId, { canAdministerAffiliateManagers = false, canVerifyEquipmentData = false, canAdministerCompanies = false, isRiskManger = false, canDownloadCompanies = false, canDownloadProperties = false, canDownloadUsers = false }) {
    const data = {};

    if (canVerifyEquipmentData !== null) {
      data.can_verify_equipment_data = canVerifyEquipmentData;
    }

    if (canAdministerCompanies !== null) {
      data.can_administer_companies = canAdministerCompanies;
    }

    if (canAdministerAffiliateManagers !== null) {
      data.can_administer_affiliate_managers = canAdministerAffiliateManagers;
    }

    if (isRiskManger !== null) {
      data.is_risk_manager = isRiskManger;
    }

    if (canDownloadCompanies !== null) {
      data.can_download_company_data = canDownloadCompanies;
    }

    if (canDownloadProperties !== null) {
      data.can_download_property_data = canDownloadProperties;
    }

    if (canDownloadUsers !== null) {
      data.can_download_user_data = canDownloadUsers;
    }

    return this._patch(`company/affiliates/${affiliateId}/managers/${managerId}/`, data);
  }

  removeAffiliateManager(affiliateId, managerId) {
    return this._delete(`company/affiliates/${affiliateId}/managers/${managerId}/`);
  }

  getAffiliate(slug) {
    return this._get(`company/affiliates/subdomain/${slug}/`).then(d => new AffiliateModel(d));
  }

  getAffiliateById(id) {
    return this._get(`company/affiliates/${id}/`).then(d => new AffiliateModel(d));
  }

  getAffiliateFilterOptions(id) {
    return this._get(`company/affiliates/${id}/filter_options/`).then(d => new AffiliateFilterModel(d));
  }

  getLocation(affiliateId, id) {
    return this._get(`company/affiliates/${affiliateId}/companies/locations/${id}/`).then(e => AffiliatePropertyLocationModel.factory(e));
  }

  getEquipment(affiliateId, id) {
    return this._get(`company/affiliates/${affiliateId}/companies/equipment/${id}/`).then(e => new AffiliateEquipmentModel(e));
  }

  getMetricFilters(status, equipmentTypeId) {
    const params = {};
    if (status) {
      params.status = status;
    }

    applyEquipmentTypeFilterToApiParams(params, equipmentTypeId);

    return params;
  }

  getEquipmentStatusMetrics(affiliateId, { status = '', equipmentTypeId = '' }) {
    const params = this.getMetricFilters(status, equipmentTypeId);
    return this._get(`company/affiliates/${affiliateId}/companies/equipment/charts/status/`, params).then(m => new ListResponseModel(m, EquipmentStatusModel));
  }

  getEquipmentTypeMetrics(affiliateId, { status = '', equipmentTypeId = '' }) {
    const params = this.getMetricFilters(status, equipmentTypeId);
    return this._get(`company/affiliates/${affiliateId}/companies/equipment/charts/equipment_type/`, params).then(m => new ListResponseModel(m, EquipmentTypeModel));
  }

  getEquipmentAverageAgeMetrics(affiliateId, { status = '', equipmentTypeId = '' }) {
    const params = this.getMetricFilters(status, equipmentTypeId);
    return this._get(`company/affiliates/${affiliateId}/companies/equipment/average_age/`, params).then(r => r);
  }

  getAffiliateReport(id) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      let userReport = {
        users_count: 0,
        users_active: 0,
        users_invited: 0,
        users_registered: 0,
      };
      let companyReport = {
        companies_count: 0,
        companies_active: 0,
        companies_invited: 0,
        companies_registered: 0,
        companies_with_equipment: 0,
      };
      let registeredEquipmentCount = 0;
      let unregisteredEquipmentCount = 0;
      let locationsCount = 0;

      let equipmentStatusReport = {};
      let equipmentTypeReport = {};

      const ninetyDaysAgo = moment().subtract(90, 'days').toISOString();

      try {
        userReport = await this._get(`company/affiliates/${id}/stats/users/`);
      } catch (ex) {
        console.log(ex);
      }

      try {
        companyReport = await this._get(`company/affiliates/${id}/stats/companies/`, {
          last_activity_after: ninetyDaysAgo,
        });
      } catch (ex) {
        console.log(ex);
      }

      try {
        const equipmentStatsResponse = await this._get(`company/affiliates/${id}/companies/stats/equipment/`);
        registeredEquipmentCount = equipmentStatsResponse.registered_company_equipment_count || 0;
        unregisteredEquipmentCount = equipmentStatsResponse.non_registered_company_equipment_count || 0;
      } catch (ex) {
        console.log(ex);
      }

      try {
        const locationsResponse = await this._get(`company/affiliates/${id}/companies/locations/`, {
          limit: 0,
          has_parent: false,
        });
        locationsCount = locationsResponse.count || 0;
      } catch (ex) {
        console.log(ex);
      }

      try {
        equipmentTypeReport = await this._get(`company/affiliates/${id}/companies/equipment/charts/equipment_type/`);
      } catch (ex) {
        console.log(ex);
      }

      try {
        equipmentStatusReport = await this._get(`company/affiliates/${id}/companies/equipment/charts/status/`);
      } catch (ex) {
        console.log(ex);
      }

      resolve(new AffiliateReportModel({
        companies_count: companyReport.companies_count,
        users_count: userReport.users_count,

        companies_invited: companyReport.companies_invited,
        users_invited: userReport.users_invited,

        companies_registered: companyReport.companies_registered,
        users_registered: userReport.users_registered,

        companies_active: companyReport.companies_active,
        users_active: userReport.users_active,

        companies_with_equipment: companyReport.companies_with_equipment,

        registeredEquipmentCount,
        unregisteredEquipmentCount,
        locations_count: locationsCount,

        equipment_status: equipmentStatusReport,
        equipment_type: equipmentTypeReport,
      }));
    });
  }

  viewedVerificationData() {
    return this._post('company/affiliates/viewed_data_verification/');
  }

  setDataVerified() {
    // update the local copy of the company so that it does not try to ask the user to verify again
    const company = Vue.prototype.$globals.company();
    if (company) {
      company.requires_affiliate_data_verification = false;
    }
    return this._post('company/affiliates/data_verified/');
  }

  batchSetDataVerified(affiliateId) {
    return this._post(`company/affiliates/${affiliateId}/batch_data_verified/`);
  }

  getAffiliateKnowledgeCenter(knowledgeCenterId) {
    return this._get(`affiliate_knowledge_center/${knowledgeCenterId}/`).then(a => new AffiliateKnowledgeCenterModel(a));
  }

  getRiskAssessmentSurveys(id, limit = 50, offset = 0) {
    const params = {
      limit,
      offset,
    };
    return this._get(`company/affiliates/${id}/risk_assessment/surveys/`, params).then(m => new ListResponseModel(m, RiskAssessmentSurveyModel));
  }

  getRiskAssessmentSurvey(id, surveyId) {
    return this._get(`company/affiliates/${id}/risk_assessment/surveys/${surveyId}/`).then(m => new RiskAssessmentSurveyModel(m));
  }

  getRiskAssessmentSurveyResponses(id, surveyId) {
    return this._get(`company/affiliates/${id}/risk_assessment/surveys/${surveyId}/responses/`).then(m => new ListResponseModel(m, RiskAssessmentSurveyModel));
  }

  getRiskAssessmentSurveyResponse(id, responseId) {
    return this._get(`company/affiliates/${id}/risk_assessment/surveys/response/${responseId}/`).then(m => new RiskAssessmentSurveyModel(m));
  }

  createRiskAssessmentSurveyResponse(id, surveyId, question_responses) {
    return this._post(`company/affiliates/${id}/risk_assessment/surveys/${surveyId}/responses/`, {
      question_responses, // { question_id, response }
    });
  }

  editRiskAssessmentSurveyResponse(id, responseId, question_responses) {
    return this._patch(`company/affiliates/${id}/risk_assessment/surveys/response/${responseId}/`, {
      question_responses, // { question_id, response }
    });
  }

  submitRiskAssessmentSurveyResponse(id, responseId) {
    return this._post(`company/affiliates/${id}/risk_assessment/surveys/response/${responseId}/submit/`);
  }

  getAffiliateCompaniesRiskAssessmentSurveys(id) {
    return this._get(`company/affiliates/${id}/companies/risk_assessment/surveys/`).then(m => new ListResponseModel(m, RiskAssessmentSurveyModel));
  }

  getAffiliateCompaniesRiskAssessmentSurvey(id, surveyId) {
    return this._get(`company/affiliates/${id}/companies/risk_assessment/surveys/${surveyId}/`).then(m => new RiskAssessmentSurveyModel(m));
  }

  getAffiliateCompaniesRiskAssessmentSurveyResponses(id, surveyId) {
    return this._get(`company/affiliates/${id}/companies/risk_assessment/surveys/${surveyId}/responses/most_recent/`).then(s => new ListResponseModel(s, RiskAssessmentSurveyModel));
  }

  getAffiliateCompaniesRiskAssessmentSurveyResponse(id, responseId) {
    return this._get(`company/affiliates/${id}/companies/risk_assessment/surveys/response/${responseId}/`).then(m => new RiskAssessmentSurveyModel(m));
  }

  getAffiliateCompaniesCsvExport(affiliateId, { search, ordering, affiliate_status_filter = null }) {
    return this._get(`company/affiliates/${affiliateId}/companies/csv_export/`, {
      search,
      ordering,
      affiliate_status_filter,
    });
  }

  getSavedFilters(id) {
    return this._get(`company/affiliates/${id}/ui_settings/`).then(f => new AffiliateUiSettingsModel(f));
  }

  updateFilters(id, {
    company_filters = null,
    child_affiliate_filters = null,
  }) {
    const data = {
      company_filters: company_filters || [],
      child_affiliate_filters: child_affiliate_filters || [],
    };

    return this._patch(`company/affiliates/${id}/ui_settings/`, data).then(f => new AffiliateUiSettingsModel(f));
  }
}
