<template>
  <div class="empty-layout">
    <main>
      <slot />
    </main>
  </div>
</template>

<script>
export default {
  name: 'Empty',
};
</script>

<style lang="scss" scoped>
</style>
