import BaseModel from './BaseModel';
import UserModel from './UserModel';
import FileModel from './FileModel';
import CompanyModel from './CompanyModel';

export default class OwnerSuggestionResponseModel extends BaseModel {
  init(data) {
    this.user = data.user ? new UserModel(data.user) : null;
    this.company = data.company ? new CompanyModel(data.company) : null;

    this.created = new Date(data.created);

    this.files = (data.files || []).map(f => new FileModel(f));
    this.response = data.response || '';
  }

  get id() {
    return `${this.created.getTime()}_${this.user.id}`;
  }
}
