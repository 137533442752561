import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueCurrencyInput from 'vue-currency-input';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueGtm from '@gtm-support/vue2-gtm';
import LoadScript from 'vue-plugin-load-script';

import VueResource from 'vue-resource';
import lineClamp from 'vue-line-clamp';

// Layouts
import DefaultLayout from '@/layouts/Default.vue';
import EmptyLayout from '@/layouts/Empty.vue';
import SideBarLayout from '@/layouts/SideBarLayout.vue';
import ProfileEditorLayout from '@/layouts/ProfileEditorLayout.vue';

import DefaultText from '@/components/DefaultText';

import VTooltip from 'v-tooltip';
import { datadogRum } from '@datadog/browser-rum';

import { createPinia, PiniaVuePlugin } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

import fontAwesomeIcons from './constants/fontAwesomeIcons';

import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'vue-search-select/dist/VueSearchSelect.css';

import App from './App.vue';
import router from './router';
import filters from './filters';

import globals from './global.js';

const isProd = import.meta.env.NODE_ENV === 'production';

Vue.use(PiniaVuePlugin);
const pinia = createPinia();
pinia.use(() => ({ debug: !isProd }));
pinia.use(piniaPluginPersistedstate);

const globalStore = new Vue(globals);
Vue.prototype.$globals = globalStore;
Vue.prototype.$globals.router = router;

Vue.config.productionTip = false;

Vue.prototype.$globalEnv = {
  baseUrl: import.meta.env.VITE_API_BASE_URL || '',
  captcha: import.meta.env.VITE_CAPTCHA || '',
  webBaseUrl: import.meta.env.VITE_BASE_URL || '',
  webBrandUrl: import.meta.env.VITE_BRAND_URL || '',
  stripeKey: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY || '',
  googleSsoClientId: import.meta.env.VITE_GOOGLE_SSO_CLIENT_ID || '',
  googleSsoRedirectUrl: import.meta.env.VITE_GOOGLE_SSO_REDIRECT_URL || '',
  microsoftSsoClientId: import.meta.env.VITE_MICROSOFT_SSO_CLIENT_ID || '',
  microsoftSsoRedirectUrl: import.meta.env.VITE_MICROSOFT_SSO_REDIRECT_URL || '',
};

datadogRum.init({
  applicationId: '84fa4ccc-a3c5-46cd-8496-b37fdb5bf706',
  clientToken: 'pubbef8a2c8f4edafe5a28569e02cdb6657',
  site: 'datadoghq.com',
  service: 'helix-web',
  version: import.meta.env.VITE_DATADOG_VERSION || 'localhost',
  env: import.meta.env.VITE_DATADOG_ENV,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  allowedTracingUrls: (import.meta.env.VITE_COOKIE_SITE != 'localhost')
    ? [url => url.startsWith(import.meta.env.VITE_API_BASE_URL)]
    : [],
  traceSampleRate: 100,
  defaultPrivacyLevel: 'mask-user-input',
  enableExperimentalFeatures: ['feature_flags'],
});

library.add(fontAwesomeIcons);

Vue.use(BootstrapVue);
Vue.use(VueResource); // provides the global `this.$http`
Vue.use(VTooltip);
Vue.use(VueCurrencyInput);
Vue.use(lineClamp);
Vue.use(LoadScript);

Vue.component('DefaultLayout', DefaultLayout);
Vue.component('EmptyLayout', EmptyLayout);
Vue.component('SidebarLayout', SideBarLayout);
Vue.component('ProfileEditorLayout', ProfileEditorLayout);
Vue.component('DefaultText', DefaultText);

if (import.meta.env.VITE_GTM_KEY) {
  Vue.use(VueGtm, {
    id: import.meta.env.VITE_GTM_KEY,
    enabled: isProd,
    debug: !isProd,
    loadScript: true,
  });
}

if (import.meta.env.VITE_GOOGLE_MAP_KEY) {
  Vue.use(VueGoogleMaps, {
    load: {
      key: import.meta.env.VITE_GOOGLE_MAP_KEY,
      libraries: 'places',
      loading: 'async',
    },
  });
}

Vue.component('FontAwesomeIcon', FontAwesomeIcon);

// after we're on vue 3, this line will need to change to:
// app.config.globalProperties.$filters = filters;
Vue.prototype.$filters = filters;

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  Vue.prototype.$globals.route = to;
  const nearestWithTitle = to.matched && to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  const nearestWithMeta = to.matched && to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  Array.from(document.querySelectorAll('[data-vue-router-controlled]'))
    .map(el => el.parentNode.removeChild(el));

  if (!nearestWithMeta) return next();

  nearestWithMeta.meta.metaTags.map((tagDef) => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach((key) => {
      tag.setAttribute(key, tagDef[key]);
    });

    // Track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  }).forEach(tag => document.head.appendChild(tag));

  next();
  return true;
});

new Vue({
  pinia,
  router,
  render: h => h(App),
}).$mount('#app');
