import ObjectLogModel from './ObjectLogModel';
import { getEquipmentStatusLabel } from '../constants/options.js';

export default class CompanyEquipmentLogModel extends ObjectLogModel {
  init(data) {
    super.init(data);

    // do some cleanup so that the data is easier to use
    if (this.field == 'status') {
      this.old_value_display = getEquipmentStatusLabel(this.old_value);
      this.new_value_display = getEquipmentStatusLabel(this.new_value);
    } else if (this.field == 'location_id') {
      this.field = 'location';
    }
  }

  get log_display() {
    if (!this.field) return this.log_message;

    switch (this.field) {
      case 'self':
        return 'created the equipment.';
      case 'images':
      case 'user_manual_files':
      case 'equipment_files':
      case 'existing_service_contract_files':
        if (this.old_value_display) return `removed an attachment: <strong>${this.old_value_display}</strong>`;
        if (this.new_value_display) return `added an attachment: <strong>${this.new_value_display}</strong>`;
        break;
      case 'name':
      case 'status':
      case 'location':
        if (this.old_value_display && this.new_value_display) {
          return `updated the equipment ${this.field} from <strong>${this.old_value_display}</strong> to <strong>${this.new_value_display}</strong>`;
        }
        if (this.old_value_display) {
          return `removed the equipment ${this.field}: <strong>${this.old_value_display}</strong>`;
        }
        if (this.new_value_display) {
          return `set the equipment ${this.field}: <strong>${this.new_value_display}</strong>`;
        }
        break;
      default:
        return this.log_message;
    }
    return this.log_message;
  }
}
