import Vue from 'vue';

import CachedCompanyData from '@/cache/CachedCompanyData';

export default class CachedTaskCategories extends CachedCompanyData {
  async retrieveData() {
    const response = await Vue.prototype.$globals.companyClient.getCompanyTaskCategories();
    return response;
  }

  getCacheExpiration() {
    return 100000;
  }
}
