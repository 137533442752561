import BaseModel from './BaseModel';

export default class AffiliateCompanyModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.logo = data.logo || '';
    this.name = data.name || '';

    this.first_login = data.first_login || '';

    this.phone = data.phone || '';
    this.phone_ext = data.phone_ext || '';

    this.street_address = data.street_address || '';
    this.street_address_line_2 = data.street_address_line_2 || '';
    this.city = data.city || '';
    this.state = data.state || '';
    this.zip = data.zip || '';

    this.naic_code = data.naic_code || null; // { code, name }

    this.client_company_number = data.client_company_number || '';
    this.client_policy_number = data.client_policy_number || '';
    this.affiliate_policy_number = data.affiliate_policy_number || '';
  }

  get display_label() {
    return this.name;
  }

  get fullPhone() {
    if (this.phone_ext) {
      return `${this.phone} x${this.phone_ext}`;
    }

    return this.phone;
  }

  get fullAddress() {
    if (this.street_address) {
      return `${this.street_address} ${this.city}, ${this.state} ${this.zip}`;
    }
    return '';
  }

  get naicDisplay() {
    if (this.naic_code) {
      return this.naic_code.name;
    }
    return '';
  }
}
