import BaseModel from './BaseModel';

class AffiliateKnowledgeCenterCardModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.title = data.title || '';
    this.description = data.description || '';
    this.summary = data.description || '';
    this.link = data.link || '';
    this.icon = data.icon || '';
    this.sort_order = data.sort_order || 0;
  }
}

class AffiliateKnowledgeCenterModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.background_image_url = data.background_image_url || '';
    this.cards = (data.cards || []).map(c => new AffiliateKnowledgeCenterCardModel(c));
  }
}

export { AffiliateKnowledgeCenterModel, AffiliateKnowledgeCenterCardModel };
