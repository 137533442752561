import RFPModel from '@/models/RFPModel';
import BaseModel from './BaseModel';

export default class RFPQuoteRequestModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.rfp = new RFPModel(data.rfp);
    this.status = data.status || '';
    this.created = data.created ? new Date(data.created) : null;
    this.submitted_quote_count = data.submitted_quote_count || 0;
    this.last_status_update = data.last_status_update ? new Date(data.last_status_update) : null;
    this.reason = data.reason || '';
    this.reason_details = data.reason_details || '';
  }

  get name() {
    return this.rfp.name || '';
  }

  get reference_number() {
    return this.rfp?.reference_number || '';
  }

  get rfp_status() {
    return this.rfp?.status || '';
  }

  get companyName() {
    return this.rfp?.company?.name || '';
  }

  get rfpTypeDisplay() {
    return this.rfp?.displayType || '';
  }

  get statusDisplay() {
    switch (this.status) {
      case 'quoted':
        return 'Quoted';
      case 'sp_declined':
        return 'Vendor Declined';
      case 'eo_declined':
      case 'owner_declined':
      case 'other_sp_accepted':
        return 'Client Declined';
      case 'owner_canceled':
      case 'eo_canceled':
        return 'Client Canceled';
      case 'rejected':
        return 'Quote Rejected';
      case 'accepted':
        switch (this.rfp_status) {
          case 'service_in_progress':
            return 'Service in Progress';
          case 'complete':
            return 'Completed';
          default:
            return 'Accepted';
        }
      default:
        return 'Matched';
    }
  }

  hasNonOtherReason() {
    return this.reason?.toLowerCase() !== 'other';
  }
}
