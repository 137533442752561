import BaseModel from './BaseModel';

export default class NotificationPreferencesModel extends BaseModel {
  init(data) {
    this.notification_preferences_ui_data = (data.notification_preferences_ui_data || []).map(n => new NotificationTypeModel(n));

    this.data = { ...data };

    // don't store the same data multiple times
    Object.keys(this).forEach((key) => {
      delete this.data[key];
    });
  }

  get uiData() {
    return this.notification_preferences_ui_data;
  }

  get uiDataFlattenedFields() {
    return this.uiData.reduce((acc, curr) => ([...acc, ...curr.fields]), []);
  }

  getPreferenceField(field_name) {
    return this.uiDataFlattenedFields.find(f => f.field_name === field_name) || {};
  }

  getPreferenceValue(field_name) {
    return (field_name in this.data) ? this.data[field_name] : [];
  }

  hasNotificationType(field_name, notification_type) {
    try {
      const field = this.uiDataFlattenedFields.find(f => f.field_name === field_name);
      const value = this.getPreferenceValue(field_name);

      return field.input_type === 'notification_types'
        ? value.includes(notification_type)
        : value;
    } catch (ex) {
      console.log(`Could not retrieve value for ${field_name}`);
    }
  }
}

class NotificationTypeModel extends BaseModel {
  init(data) {
    this.label = data.label || '';
    this.title = data.title || '';
    this.description = data.description || '';
    this.fields = (data.fields || []).map(f => new FieldModel(f));
  }

  get itemType() {
    return this.label.toLowerCase();
  }
}

class FieldModel extends BaseModel {
  init(data) {
    this.field_name = data.field_name || '';
    this.label = data.label || '';
    this.description = data.description || '';
    this.input_type = data.input_type || '';
    this.empty_allowed = data.empty_allowed || false;
    this.value = data.value || [];
  }
}
