import BaseModel from './BaseModel';
import TerritoryModel from './TerritoryModel';
import FileModel from './FileModel';
import TagModel from './TagModel';
import OptionModel from './OptionModel';
import RelatedCompanyContactModel from './RelatedCompanyContactModel';
import VendorCompanyModel from './VendorCompanyModel';
import VendorMediaFileModel from './VendorMediaFileModel';
import VendorHubCategoryModel from './VendorHubCategoryModel';
import CopyableMixin from './CopyableMixin';
import { vendorAttachmentTypes } from '../constants/options';

export default class MyRelatedCompanyModel extends CopyableMixin(BaseModel) {
  // user added vendor or client model
  init(data) {
    this.id = data.id || 0;
    this.tags = (data.tags || []).map(t => new TagModel(t)).filter(t => t.active);
    this.services_you_provide = (data.services_you_provide || []).map(m => new OptionModel(m));
    this.equipment_manufacturers_serviced = (data.equipment_manufacturers_serviced || []).map(m => new OptionModel(m));
    this.equipment_categories_serviced = (data.equipment_categories_serviced || []).map(m => new OptionModel(m));
    this.contacts = (data.contacts || []).map(c => new RelatedCompanyContactModel(c));
    this.company_info = data.company_info ? new VendorCompanyModel(data.company_info) : null;
    this.can_delete = data.can_delete || false;
    this.notes = data.notes || '';

    if (data.company_name && !this.company_info) {
      // just grab the name here to allow new vendor modal inside the RFP modal to show the selected name
      // without having to re-get the model
      this.company_info = new VendorCompanyModel({
        name: data.company_name,
      });
    }

    this.selected = false;
    this.rowInView = true;

    this.on_platform = data.company_info && 'on_platform' in data.company_info ? data.company_info.on_platform : false;
    this.can_make_off_platform_vendor_request = 'can_make_off_platform_vendor_request' in data ? data.can_make_off_platform_vendor_request : false;

    this.territories = (data.territories || []).map(c => new TerritoryModel(c));

    this.related_company = data.related_company || null;
    this.canDeleteRelatedCompany = data.related_company?.can_delete || false;
    this.media = (data.media || []).map(m => new VendorMediaFileModel(m));
    this.vendor_hub_categories = (data.vendor_hub_categories || []).map(x => new VendorHubCategoryModel(x));

    this._updateAttachmentFiles(data);
  }

  _getAttachmentTypeMap() {
    return Object.fromEntries(Object.entries(vendorAttachmentTypes).map(
      ([k, v]) => [k, v.api_field],
    ));
  }

  _updateAttachmentFiles(data) {
    const attachmentTypes = this._getAttachmentTypeMap();

    Object.keys(attachmentTypes).forEach((file_type) => {
      if (Object.hasOwn(attachmentTypes, file_type)) {
        const data_files = (data[attachmentTypes[file_type]] || []);
        this[attachmentTypes[file_type]] = data_files.map(f => new FileModel({ ...f, related_record_context: file_type }));
      }
    });
  }

  get allAttachments() {
    // TODO: refactor to something data driven
    return [...this.contract_files, ...this.compliance_files, ...this.financial_files, ...this.insurance_files, ...this.communication_files, ...this.attachments];
  }

  get groupedTerritories() {
    const stateNames = [...new Set(this.territories.map(t => t.state_name || t.t.state_code))].sort();
    const counties = stateNames.map((stateName) => {
      const countiesPerState = this.territories.filter(c => c.state_name === stateName || c.state_code === stateName).map(c => c.name).sort();
      return {
        name: stateName,
        counties: countiesPerState,
      };
    });
    return counties;
  }

  friendlyTerritoryNames(allOptions) {
    // check for all states
    if (this.territories.length === allOptions.length) {
      return [{
        name: 'All US States',
        counties: [],
      }];
    }

    // within each state, check for all counties
    const states = this.groupedTerritories;
    return states.map(({ name, counties }) => {
      const hasAllCounties = allOptions.filter(x => x.state_name === name).length === counties.length;

      if (hasAllCounties) {
        return {
          name: `${name} (All Counties)`,
          counties: [],
        };
      }

      const label = counties.length === 1 ? 'County' : 'Counties';
      return {
        name: `${name} (${counties.length} ${label})`,
        counties,
      };
    });
  }

  get additionalTags() {
    // skipping the first tag
    if (this.tags?.length > 1) {
      return this.tags.slice(1).map(t => t.name).join(', ');
    }
    return '';
  }

  get display_label() {
    return this.company_info?.name || '';
  }

  get name() {
    return this.company_info?.name || '';
  }

  set name(v) {
    this.company_info.name = v;
  }

  get description() {
    return this.company_info?.description || '';
  }

  set description(v) {
    this.company_info.description = v;
  }

  get companyId() {
    return this.company_info?.id || 0;
  }

  get allCategories() {
    return this.equipment_categories_serviced.map(x => x.full_path_display_name).sort();
  }

  get allServicesProvided() {
    return this.services_you_provide.map(x => x.name).sort().join(', ');
  }

  get allMfcServiced() {
    return this.equipment_manufacturers_serviced.map(x => x.name).sort().join(', ');
  }

  get location() {
    return this.company_info?.cityStateDisplay;
  }

  get logo() {
    return this.company_info?.logo || null;
  }

  set logo(v) {
    this.company_info.logo = v;
  }

  get company_logo() {
    return this.company_info?.logo || null;
  }

  set company_logo(v) {
    this.company_info.logo = v;
  }
}
