import Vue from 'vue';

import CachedCompanyData from '@/cache/CachedCompanyData';

export default class CachedCompanyTags extends CachedCompanyData {
  async retrieveData() {
    const resp = await Vue.prototype.$globals.companyClient.getTags();
    return resp.items.filter(t => t.active);
  }

  getCacheExpiration() {
    return 3000;
  }
}
