import BaseModel from './BaseModel';

class MarketplaceServiceCTAModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.cta_type = data.cta_type || '';
    this.button_label = data.button_label || '';
    this.sort_order = data.sort_order || 0;
    this.cta_url = data.cta_url || '';
    this.cta_service_card = data.cta_service_card || 0;
    this.cta_rfp_form = data.cta_rfp_form || 0;
    this.cta_lead_form = data.cta_lead_form || 0;
  }
}

const CTAType = {
  OPEN_SERVICE_CARD: 'open_service_card',
  OPEN_URL: 'open_url',
  OPEN_RFP_FORM: 'open_rfp_form',
  OPEN_LEAD_FORM: 'open_lead_form',
};

export { MarketplaceServiceCTAModel, CTAType };
