import BaseModel from '@/models/BaseModel';
import PartModel from './PartModel';
import ProjectModel from './ProjectModel';
import CompanyEquipmentModel from './CompanyEquipmentModel';
import { PropertyLocationModel } from './LocationModels';
import RFPModel from './RFPModel';
import TaskStatusModel from './TaskStatusModel';
import UserModel from './UserModel';
import SensorDeviceModel from './SensorDeviceModel';
import { ReservationModel } from './ReservationModel';

class ProjectsFilterModel extends BaseModel {
  constructor(data, args) {
    super();

    this.init(data || {});
    this.useTaskSourceFilterV2 = args?.useTaskSourceFilterV2 || false;
  }

  init(data) {
    this.isCalendarView = false;
    this.search = data.search || '';
    this.taskArchivedFilter = 'task_archived_filter' in data ? data.task_archived_filter : false;
    this.taskOverdueFilter = false;
    this.projectFilter = (data.project_filters || []).map(p => new ProjectModel(p));
    this.categoryFilter = (data.category_filters || []).map(c => parseInt(c.id || c));
    this.priorityFilter = data.priority_filter ? data.priority_filter.split(',').map(p => parseInt(p)) : [];
    this.statusFilter = (data.status_filters || []).map(s => new TaskStatusModel(s));
    this.equipmentFilter = (data.equipment_filters || []).map(e => new CompanyEquipmentModel(e));
    this.partFilter = (data.part_filters || []).map(p => new PartModel(p));
    this.locationFilter = (data.location_filters || []).map(l => new PropertyLocationModel(l));
    this.rfpFilter = (data.rfp_filters || []).map(r => new RFPModel(r));
    this.unassignedFilter = 'unassigned_filter' in data ? data.unassigned_filter : false;
    this.assigneeIgnoreChecklists = 'assignee_ignore_checklists' in data ? data.assignee_ignore_checklists : false;
    this.assigneeFilterUsers = (data.assignee_filter_users || []).map(u => new UserModel(u));
    this.workTypeFilter = data.work_type_filter || null;
    this.taskSourceFilter = 'work_order_request_source_filter' in data ? data.work_order_request_source_filter : null;
    this.taskSourceFilterV2 = data.task_source_filter || null;
    this.dueDateAfter = data.due_date_after || null;
    this.dueDateBefore = data.due_date_before || null;
    this.completedDateAfter = data.completed_date_after || null;
    this.completedDateBefore = data.completed_date_before || null;
    this.uiFoldedProjectIds = data.ui_folded_project_ids || [];
    this.sensorFilter = (data.sensor_filters || []).map(s => new SensorDeviceModel(s));
    this.reservationFilter = (data.reservation_filters || []).map(s => new ReservationModel(s));
  }

  shouldFoldProject(projectId) {
    return this.uiFoldedProjectIds.includes(projectId);
  }

  projectFoldUpdated(projectId, folded) {
    if (!folded) {
      this.uiFoldedProjectIds = this.uiFoldedProjectIds.filter(p => p !== projectId);
    } else if (!this.shouldFoldProject(projectId)) {
      this.uiFoldedProjectIds.push(projectId);
    }
  }

  get isCalendarView() {
    return this._isCalendarView;
  }

  set isCalendarView(val) {
    this._isCalendarView = val;
  }

  clearModalFilters() {
    // props that should not be cleared from the "Clear All" filter modal
    const assignees = this.assigneeFilterUsers;
    const searchPreserved = this.search;
    const foldedProjects = this.uiFoldedProjectIds;

    this.init({
      search: searchPreserved,
      ui_folded_project_ids: foldedProjects,
    });

    this.assigneeFilterUsers = assignees;
  }

  get projectFilterIds() {
    return this.projectFilter ? this.projectFilter.map(p => (p.id || p)) : [];
  }

  get locationFilterIds() {
    return this.locationFilter ? this.locationFilter.map(loc => (loc.id || loc)) : [];
  }

  get rfpFilterIds() {
    return this.rfpFilter ? this.rfpFilter.map(rfp => (rfp.id || rfp)) : [];
  }

  get workTypeFilterId() {
    return this.workTypeFilter;
  }

  get partFilterIds() {
    return this.partFilter ? this.partFilter.map(part => (part.id || part)) : [];
  }

  get statusFilterIds() {
    return this.statusFilter ? this.statusFilter.map(status => (status.id || status)) : [];
  }

  get equipmentFilterIds() {
    return this.equipmentFilter ? this.equipmentFilter.map(equip => (equip.id || equip)) : [];
  }

  get sensorFilterIds() {
    return this.sensorFilter ? this.sensorFilter.map(sensor => (sensor.id || sensor)) : [];
  }

  get reservationFilterIds() {
    return this.reservationFilter ? this.reservationFilter.map(reservation => (reservation.id || reservation)) : [];
  }

  get assigneeFilterUserIds() {
    return this.assigneeFilterUsers ? this.assigneeFilterUsers.map(user => (user.id || user)) : [];
  }

  get appliedFilterCount() {
    let count = this.filterModalAppliedCount;
    if (this.assigneeFilterUsers?.length) count++;
    if (this.unassignedFilter) count++;

    return count;
  }

  get filterModalAppliedCount() {
    let count = 0;
    if (this._isCalendarView && this.projectFilter?.length) count++;
    if (this.categoryFilter?.length) count++;
    if (this.equipmentFilter?.length) count++;
    if (this.partFilter?.length) count++;
    if (this.rfpFilter?.length) count++;
    if (this.priorityFilter?.length) count++;
    if (this.taskArchivedFilter) count++;
    if (this.taskOverdueFilter) count++;
    if (this.statusFilter?.length) count++;
    if (this.locationFilter?.length) count++;
    if (this.workTypeFilter) count++;
    if (this.taskSourceFilter !== null) count++;
    if (this.taskSourceFilterV2 !== null && this.taskSourceFilter == null) count++;
    if (this.sensorFilter?.length) count++;
    if (this.reservationFilter?.length) count++;
    if (!this._isCalendarView && this.dueDateAfter) count++;
    if (!this._isCalendarView && this.dueDateBefore) count++;
    if (!this._isCalendarView && this.completedDateAfter) count++;
    if (!this._isCalendarView && this.completedDateBefore) count++;

    return count;
  }

  getParamsObj() {
    const params = {
      search: this.search,
      taskArchived: this.taskArchivedFilter,
      taskOverdue: this.taskOverdueFilter,
      taskAssigneeCount: this.unassignedFilter ? 0 : null,
      taskAssigneeIgnoreChecklists: this.assigneeIgnoreChecklists,
      taskDueDateAfter: this.dueDateAfter,
      taskDueDateBefore: this.dueDateBefore,
      taskCompletedDateAfter: this.completedDateAfter,
      taskCompletedDateBefore: this.completedDateBefore,
      taskEquipmentId: this.equipmentFilterIds?.[0] || [],
      taskCategoryIds: this.categoryFilter || [],
      taskPriorityIds: this.priorityFilter || [],
      taskStatusIds: this.statusFilterIds || [],
      taskLocationId: this.locationFilterIds?.[0] || [],
      taskPartId: this.partFilterIds?.[0] || [],
      taskAssignees: this.assigneeFilterUserIds || [],
      taskSensorId: this.sensorFilterIds?.[0] || [],
      taskReservationId: this.reservationFilterIds?.[0] || [],
    };

    if (this.useTaskSourceFilterV2) {
      params.taskSourceV2 = this.taskSourceFilterV2;
    } else {
      params.taskWorkTypeId = this.workTypeFilterId || null;
      params.taskSource = this.taskSourceFilter;
    }

    return params;
  }

  get hasFiltersApplied() {
    return this.appliedFilterCount > 0;
  }

  get hasSearch() {
    return !!this.search;
  }

  get shouldApplySearchAndFilters() {
    return this.hasSearch || this.hasFiltersApplied;
  }

  get hasCompletedStatusFilter() {
    return this.statusFilter?.length && !!this.statusFilter.find(s => s.is_complete_state);
  }

  addEquipmentFilter(data) {
    this.equipmentFilter.push(new CompanyEquipmentModel(data));
  }

  addSensorFilter(data) {
    this.sensorFilter.push(new SensorDeviceModel(data));
  }

  addPartFilter(data) {
    this.partFilter.push(new PartModel(data));
  }

  addLocationFilter(data) {
    this.locationFilter.push(new PropertyLocationModel(data));
  }

  addStatusFilter(status) {
    this.statusFilter.push(status);
  }

  addArchivedFilter() {
    this.taskArchivedFilter = true;
  }
}

class PriorityFilterModel {
  static get Priorities() {
    return new Map([
      ['Urgent', 'DB162F'],
      ['High', 'FFB320'],
      ['Normal', '335E8D'],
      ['Low', '000000ab'],
      ['None', 'CCCCCC'],
    ]);
  }
}

export { ProjectsFilterModel, PriorityFilterModel };
