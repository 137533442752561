import moment from 'moment';

import BaseModel from '@/models/BaseModel';
import RFPModel from '@/models/RFPModel';
import TagModel from '@/models/TagModel';
import { PropertyLocationModel } from '@/models/LocationModels';
import ProjectModel from './ProjectModel';
import CompanyEquipmentModel from './CompanyEquipmentModel';
import UserModel from './UserModel';

class UiSettingsModel extends BaseModel {
  init(data) {
    this.search = data.search || '';
    this.sort = data.sort || 'name';
    this.tag_filters = (data.tag_filters || []).map(x => new TagModel(x));
    this.ui_selected_fields = data.ui_selected_fields || [];
    this.ui_view_type = data.ui_view_type || 'grid';
  }

  get is_grid_view() {
    if (this.ui_view_type) {
      return this.ui_view_type === 'grid';
    }
    return true;
  }

  set is_grid_view(v) {
    this.ui_view_type = v ? 'grid' : 'table';
  }

  get locationComputed() {
    return this.locations?.length
      ? this.locations[0]
      : 0;
  }

  set locationComputed(val) {
    if (!this.locations) this.locations = [];

    if (typeof this.locationFilterUpdated === 'function') {
      this.locationFilterUpdated(this.locations[0] || 0, val);
    } else {
      this.locations[0] = val;
    }
  }

  reset() {
    this.search = '';

    // only reset if there are selected tags, otherwise watcher will fire needlessly
    if (this.tag_filters.length) {
      this.tag_filters = [];
    }
  }
}

class PMScheduleUiSettingsModel extends UiSettingsModel {
  init(data) {
    super.init(data);

    this.task_categories = data.task_categories || []; // id/name
    this.assignees = (data.assignees || []).map(u => new UserModel(u));
    this.priorities = data.priorities ? data.priorities.map(p => parseInt(p)) : [];
    this.projects = (data.projects || []).map(p => new ProjectModel(p));
    this.locations = (data.locations || []).map(l => new PropertyLocationModel(l));
    this.equipment = (data.equipment || []).map(e => new CompanyEquipmentModel(e));
    this.active = 'active' in data ? (data.active ?? '').toString() : null;
    this.schedule = data.schedule || '';
  }

  reset(includeAssignees = false) {
    super.reset();

    if (includeAssignees) {
      this.assignees = [];
    }

    this.task_categories = [];
    this.priorities = [];
    this.projects = [];
    this.locations = [];
    this.equipment = [];
    this.active = null;
    this.schedule = '';
  }

  get assigneeUserIds() {
    return this.assignees ? this.assignees.map(user => (user.id || user)) : [];
  }
}

class EquipmentUiSettingsModel extends UiSettingsModel {
  init(data) {
    super.init(data);

    this.location_filters = (data.location_filters || []).map(x => new PropertyLocationModel(x));
    this.rfp_filters = (data.rfp_filters || []).map(x => new RFPModel(x));
    this.equipment_category_filters = data.equipment_category_filters || [];
    this.status_filters = data.status_filters || [];
    this.qr_code_assigned_filter = data.qr_code_assigned_filter;
  }

  get rfp_filter() {
    const rfp = this.rfp_filters.length ? this.rfp_filters[0] : null;
    return rfp?.id || rfp;
  }

  set rfp_filter(v) {
    this.rfp_filters = v ? [v] : [];
  }

  get status_filter() {
    return this.status_filters.length ? this.status_filters[0] : null;
  }

  set status_filter(v) {
    this.status_filters = v ? [v] : [];
  }

  get location_filter() {
    const location = this.location_filters.length ? this.location_filters[0] : null;
    return location?.id || location || 0;
  }

  set location_filter(v) {
    this.location_filters = v ? [v] : [];
  }

  get equipment_type() {
    const equipmentType = this.equipment_category_filters.length ? this.equipment_category_filters[0] : null;
    return equipmentType?.id || equipmentType || 0;
  }

  set equipment_type(v) {
    this.equipment_category_filters = v ? [v] : [];
  }

  reset() {
    super.reset();

    this.rfp_filter = null;
    this.status_filter = null;
    this.location_filter = null;
    this.equipment_type = '';
    this.qr_code_assigned_filter = null;
  }
}

class LocationUiSettingsModel extends UiSettingsModel {
  init(data) {
    super.init(data);

    this.location_category = data.location_category?.id ?? data.location_category ?? 0;
  }

  reset() {
    super.reset();

    this.location_category = 0;
  }
}

class PartUiSettingsModel extends UiSettingsModel {
  init(data) {
    super.init(data);

    this.equipment = data.equipment?.id ?? data.equipment ?? 0;
    this.location = data.location?.id ?? data.location ?? 0;
  }

  reset() {
    super.reset();

    this.equipment = 0;
    this.location = 0;
  }
}

class PartContainerUiSettingsModel extends UiSettingsModel {
  init(data) {
    super.init(data);

    this.location = data.location?.id ?? data.location ?? 0;
  }

  reset() {
    super.reset();

    this.location = 0;
  }
}

class WorkOrderRequestUiSettingsModel extends UiSettingsModel {
  init(data) {
    super.init(data);

    this.statuses = data.statuses || null;
    this.categories = data.task_categories || []; // id/name
    this.priorities = data.priorities || [];
    this.requester = data.requester ? new UserModel(data.requester) : null;
    this.locations = (data.locations || []).map(l => l.id);
    this.created_after = data.created_date_after || null;
    this.created_before = data.created_date_before || null;
    this.requested_completion_date_after = data.completion_date_after || null;
    this.requested_completion_date_before = data.completion_date_before || null;
    this.reviewers = (data.reviewers ?? []).map(u => new UserModel(u));
    this.no_assigned_reviewer = data.no_assigned_reviewer ?? null;

    this.locationFilterUpdated = data.locationFilterUpdated ?? null;
  }

  reset(resetReviewerFilters = false) {
    super.reset();

    this.statuses = null;
    this.categories = null;
    this.priorities = [];
    this.requester = 0;
    this.locations = [];
    this.created_after = null;
    this.created_before = null;
    this.requested_completion_date_after = null;
    this.requested_completion_date_before = null;

    if (resetReviewerFilters) {
      this.reviewers = [];
      this.no_assigned_reviewer = null;
    }
  }

  getParamsObj(page, limit, uiViewType = 'all', showManagerView = false) {
    const params = {
      search: this.search,
      ordering: this.sort,
      limit,
      offset: (page - 1) * limit,
      categories: (this.categories || []).map(c => c.id).join(',') || null,
      priorities: this.priorities?.join(',') || null,
      location__ancestor_hierarchy__ancestor_id: this.locations?.length ? this.locations[0] : null,
      created_after: this.created_after,
      created_before: this.created_before,
      requested_completion_date_after: this.requested_completion_date_after,
      requested_completion_date_before: this.requested_completion_date_before,
    };

    if (showManagerView) {
      params.requester = this.requester?.id || null;

      const reviewFilter = (this.reviewers || []).map(u => u.id).join(',') || null;

      if (uiViewType === 'all') {
        params.reviewed_by = reviewFilter;
      } else {
        params.no_assigned_reviewer = this.no_assigned_reviewer ?? null;
        params.reviewers = reviewFilter;
      }
    }

    let statuses = (this.statuses || []).filter(s => s.id).map(s => s.id) || null;
    // ensure that the status filter is set correctly based on the current view
    if (uiViewType === 'pending') {
      // only show pending_review
      statuses = ['pending_review'];
    } else if (showManagerView && statuses?.length) {
      // do not show pending_review to managers if not on that view
      statuses = statuses.filter(s => s !== 'pending_review');
    }

    params.statuses = (statuses || []).join(',') || null;

    // restrict pending when manager viewing "processed requests"
    if (showManagerView && uiViewType === 'all') {
      params.no_pending_status = true;
    }

    return params;
  }
}

class AffiliateUiSettingsModel extends UiSettingsModel {
  init(data) {
    super.init(data);

    this.company_filters = data.company_filters || [];
    this.child_affiliate_filters = data.child_affiliate_filters || [];
  }

  reset() {
    super.reset();
    this.company_filters = [];
    this.child_affiliate_filters = [];
  }
}

class TaskTemplateUiSettingsModel extends UiSettingsModel {
  init(data) {
    super.init(data);

    this.categories = data.task_categories || []; // id/name
  }

  reset() {
    super.reset();

    this.categories = null;
  }

  getParamsObj(page, limit, archived = false) {
    return {
      search: this.search,
      ordering: this.sort,
      offset: (page - 1) * limit,
      limit,
      categories: (this.categories || []).map(c => c.id).join(',') || null,
      archived,
    };
  }
}

class ReservationRequestUiSettingsModel extends UiSettingsModel {
  init(data) {
    super.init(data);

    this.requester = data.requester ? new UserModel(data.requester) : null;
    this.reviewed_statuses = data.statuses || null;
    this.locations = (data.locations || []).map(l => l.id);
    this.date_after = data.date_after || null;
    this.date_before = data.date_before || null;
    this.reviewers = (data.reviewers ?? []).map(u => new UserModel(u));
    this.no_assigned_reviewer = data.no_assigned_reviewer ?? null;

    this.locationFilterUpdated = null;
  }

  reset(resetReviewerFilters = false) {
    super.reset();

    this.requester = 0;
    this.reviewed_statuses = null;
    this.locations = [];
    this.date_after = null;
    this.date_before = null;

    if (resetReviewerFilters) {
      this.reviewers = [];
      this.no_assigned_reviewer = null;
    }
  }

  getParamsObj(page, limit, uiViewType = 'all', showManagerView = false) {
    const dateAfter = (!this.date_after || typeof this.date_after === 'string') ? this.date_after : moment(this.date_after).format('YYYY-MM-DDTHH:mm:ss[Z]');
    const dateBefore = (!this.date_before || typeof this.date_before === 'string') ? this.date_before : moment(this.date_before).format('YYYY-MM-DDTHH:mm:ss[Z]');

    const params = {
      search: this.search,
      ordering: this.sort,
      offset: (page - 1) * limit,
      limit,
      location: this.locations?.length ? this.locations[0] : null,
      date_after: dateAfter,
      date_before: dateBefore,
      no_assigned_reviewer: this.no_assigned_reviewer,
    };

    if (uiViewType === 'all') {
      params.status = 'canceled,reviewed';
    } else if (uiViewType === 'pending') {
      params.status = 'pending_review';
    }

    if (this.reviewed_statuses) {
      params.reviewed_statuses = this.reviewed_statuses.filter(s => s.id).map(s => s.id);
    }

    if (showManagerView) {
      params.requester = this.requester?.id || null;

      const reviewFilter = (this.reviewers || []).map(u => u.id).join(',') || null;

      if (uiViewType === 'all') {
        params.reviewed_by = reviewFilter;
      } else {
        params.no_assigned_reviewer = this.no_assigned_reviewer ?? null;
        params.reviewers = reviewFilter;
      }
    }

    return params;
  }
}

class ReservationUiSettingsModel extends UiSettingsModel {
  init(data) {
    super.init(data);

    this.locations = (data.locations || []).map(l => l.id);
    this.date_after = data.date_after || null;
    this.date_before = data.date_before || null;
    this.requesters = (data.requesters ?? []).map(u => new UserModel(u));

    this.locationFilterUpdated = null;
  }

  reset(resetReservedByFilter = false) {
    super.reset();

    this.locations = [];
    this.date_after = null;
    this.date_before = null;

    if (resetReservedByFilter) {
      this.requesters = [];
    }
  }

  getParamsObj(page, limit, archived = false) {
    const dateAfter = (!this.date_after || typeof this.date_after === 'string') ? this.date_after : moment(this.date_after).format('YYYY-MM-DDTHH:mm:ss[Z]');
    const dateBefore = (!this.date_before || typeof this.date_before === 'string') ? this.date_before : moment(this.date_before).format('YYYY-MM-DDTHH:mm:ss[Z]');

    const params = {
      search: this.search,
      ordering: this.sort,
      offset: (page - 1) * limit,
      limit,
      location: this.locations?.length ? this.locations[0] : null,
      date_after: dateAfter,
      date_before: dateBefore,
      archived,
    };

    if (this.requesters && this.requesters.length > 0) {
      params.requesters = this.requesters.map(u => u.id).join(',');
    }

    return params;
  }
}

export {
  AffiliateUiSettingsModel,
  EquipmentUiSettingsModel,
  LocationUiSettingsModel,
  PartUiSettingsModel,
  PartContainerUiSettingsModel,
  PMScheduleUiSettingsModel,
  ReservationRequestUiSettingsModel,
  ReservationUiSettingsModel,
  TaskTemplateUiSettingsModel,
  UiSettingsModel,
  WorkOrderRequestUiSettingsModel,
};
