import Vue from 'vue';

export default {
  props: ['value', 'filter'],
  render(h) {
    return h('span', { class: this.getClasses() }, [this.getValue()]);
  },
  methods: {
    getClasses() {
      const cls = ['default-text'];
      if (!this.value || this.value === Vue.prototype.$globals.defaultDisplayValue) {
        cls.push('empty');
      }
      return this.filter ? this.filter(cls) : cls;
    },
    getValue() {
      return (this.value && this.value !== '') ? this.value : (Vue.prototype.$globals.defaultDisplayValue ?? '—');
    },
  },
};
