import DashboardSuggestionModel from './DashboardSuggestionModel';

export default class OwnerDashboardSuggestionModel extends DashboardSuggestionModel {
  init(data) {
    super.init(data);

    this.internal_name = data.internal_name || '';
    this.active = data.active || false;
    this.viewed_users_count = data.viewed_users_count || 0;
    this.responded_users_count = data.responded_users_count || 0;
    this.dismissed_users_count = data.dismissed_users_count || 0;
  }
}
