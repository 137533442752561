import moment from 'moment';
import BaseModel from './BaseModel';
import UserModel from './UserModel';
import { convertSecondsToHMS } from '../util';

export default class TaskTimeModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.time_in_seconds = data.time_in_seconds || 0;
    this.start_time = data.start_time ? new Date(data.start_time) : null;
    this.end_time = data.end_time ? new Date(data.end_time) : null;
    this.manual_entry_date = data.manual_entry_date || '';
    this.user = data.user ? new UserModel(data.user) : null;
    this.task_id = data.task_id || 0;
    this.task_instance_id = data.task_instance_id || 0;
    this.task_name = data.task_name || '';
  }

  get elapsed_seconds() {
    if (this.start_time && !this.end_time) {
      const start = new Date(this.start_time);
      return Math.ceil(moment().diff(start) / 1000);
    }
    return this.time_in_seconds;
  }

  get display_value() {
    const duration = convertSecondsToHMS(this.elapsed_seconds);
    const roundedSeconds = Math.round(duration.seconds / 60);
    let minutes = duration.minutes + roundedSeconds;
    while (minutes >= 60) {
      minutes -= 60;
      duration.hours++;
    }

    if (duration.hours) {
      const val = `${duration.hours}h`;
      return minutes ? `${val} ${minutes}m` : val;
    }

    if (duration.minutes) {
      return `${minutes}m`;
    }

    if (duration.seconds) {
      return `${duration.seconds}s`;
    }

    return '0m';
  }

  get manualEntryDateDate() {
    return this.stringToDate(this.manual_entry_date);
  }
}
