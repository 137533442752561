import BaseModel from './BaseModel';
import OptionTypeModel from './OptionTypeModel';

export default class RiskAssessmentQuestionModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.question_text = data.question_text || '';
    this.question_type = data.question_type || '';
    this.options = data.options || [];
    this.required = data.required || false;
    this.sort_order = data.sort_order || 0;
    this.placeholder_text = data.placeholder_text || 'Your Answer';

    if (this.options.length) {
      // convert list of strings to OptionTypeModel
      this.options = this.options.map(display => new OptionTypeModel({
        code_name: display,
        display_name: display,
      }));
    }

    this.value = data.value || '';
    this.tagSearch = '';
    this.selected_options = [];
  }

  get formId() {
    return `questionMeta${this.id}`;
  }

  get questionTextHtml() {
    return this.getHtmlDisplayValue(this.question_text);
  }

  get questionTextHeader() {
    return this.getHtmlDisplayValue(this.question_text.split('\n')[0]);
  }

  get questionTextSubText() {
    const lines = this.question_text.split('\n');
    if (lines.length <= 1) {
      return null;
    }
    lines.shift();
    return this.getHtmlDisplayValue(lines.join('\n'));
  }

  get hasValue() {
    if (this.question_type === 'ratings') {
      return this.options.some(o => o.value);
    }
    if (this.question_type === 'multi_select') {
      return this.selected_options.length > 0;
    }
    return !!this.value;
  }

  getFormValue() {
    if (this.question_type === 'ratings') {
      if (this.hasValue) {
        const dict = {};
        this.options.forEach((o) => {
          dict[o.code_name] = o.value;
        });
        return dict;
      }
      return null;
    }
    if (this.question_type === 'multi_select') {
      return this.selected_options.map(o => o.id);
    }
    return this.value;
  }

  setFormValue(response) {
    if (!response) {
      return;
    }

    if (this.question_type === 'ratings') {
      this.options.forEach((opt) => {
        opt.value = (response.value && response.value[opt.code_name]) || '';
      });
    }
    if (this.question_type === 'multi_select') {
      this.selected_options = response.value?.map(r => ({
        id: r,
        text: r,
        name: r,
        value: r,
      }));
    }
    this.value = response.value;
  }

  get optionsForTags() {
    return this.options.map(o => ({ id: o.display_name, text: o.display_name, role_name: o.display_name }));
  }
}
