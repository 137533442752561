import BaseModel from './BaseModel';

export default class IncentiveReportPromoCodeModel extends BaseModel {
  init(data) {
    this.percentage_discount = data.percentage_discount ? parseFloat(data.percentage_discount) : 0;
    this.flat_rate_per_property = data.flat_rate_per_property ? parseFloat(data.flat_rate_per_property) : 0;
    this.code = data.code || '';
  }

  get marketingMessage() {
    if (this.percentage_discount) {
      return `You are saving ${this.percentage_discount}% with promo code ${this.code}!`;
    }

    if (this.flat_rate_per_property) {
      return `Enjoy a flat fee of $${this.flat_rate_per_property} per property with promo code ${this.code}!`;
    }

    return '';
  }

  get appliedSuccessfullyMessage() {
    if (this.percentage_discount) {
      return `You are now saving ${this.percentage_discount}% with promo code ${this.code}!`;
    }

    if (this.flat_rate_per_property) {
      return `Fee per property is now a flat fee of $${this.flat_rate_per_property} with promo code ${this.code}!`;
    }

    return '';
  }
}
