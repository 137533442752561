import moment from 'moment';
import Vue from 'vue';

import { getCurrentDateNoTime, getNumberOfDays, friendlyDayNameFromInt, getFormattedDate } from '@/util.js';
import { getTaskPriorityLabel, taskWorkTypeOptions } from '../constants/options.js';
import { PropertyLocationModel } from './LocationModels';
import CompanyEquipmentModel from './CompanyEquipmentModel';
import SensorDeviceModel from './SensorDeviceModel';
import PartModel from './PartModel';
import RFPModel from './RFPModel';
import UserModel from './UserModel';
import FileModel from './FileModel';
import TaskInstanceModel from './TaskInstanceModel';
import OptionModel from './OptionModel';
import BaseModel from './BaseModel';
import CopyableMixin from './CopyableMixin';
import ProjectModel from './ProjectModel.js';
import WorkOrderRequestModel from './WorkOrderRequestModel.js';
import TaskTemplateModel from './TaskTemplateModel.js';
import PMScheduleModel from './PMScheduleModel.js';
import { ReservationModel } from './ReservationModel.js';

const placeholderText = '-';

class TaskModel extends CopyableMixin(BaseModel) {
  static RelatedItemType = {
    Location: 'location',
    Equipment: 'equipment',
    Parts: 'part',
    Sensor: 'sensor',
    Request: 'RFP',
    Reservation: 'reservation',
  };

  static WorkType = {
    Task: 'task',
    WorkOrder: 'work_order',
    Schedule: 'pm_schedule',
  };

  constructor(data, args) {
    super();

    this.init(data || {});
    this.groupListTask = args?.groupListTask || false;
  }

  init(data) {
    this.id = data.id || 0;
    this.archived = data.archived || false;
    this.priority = data.priority || 0;

    this.name = data.name || '';
    this.due_date = data.due_date || '';
    this.description = data.description || '';
    this.completed = data.completed || false;
    this.created = data.created ? new Date(data.created) : null;
    this.user = data.user ? new UserModel(data.user) : null;
    this.work_order_request = data.work_order_request ? new WorkOrderRequestModel(data.work_order_request) : null;
    this.pm_schedule = data.pm_schedule ? new PMScheduleModel(data.pm_schedule) : null;

    this.project = data.project ? new ProjectModel(data.project) : null;
    this.category = data.category ? new OptionModel(data.category) : null;
    this.work_type = data.work_type ? taskWorkTypeOptions.find(option => option.id === data.work_type) : null;
    this.equipment = data.equipment ? new CompanyEquipmentModel(data.equipment) : null;
    this.part = data.part ? new PartModel(data.part) : null;
    this.sensor = data.sensor ? new SensorDeviceModel(data.sensor) : null;
    this.rfp = data.rfp ? new RFPModel(data.rfp) : null;
    this.reservation = data.reservation ? new ReservationModel(data.reservation, { depthSingle: false }) : null;

    this.location = data.location ? PropertyLocationModel.factory(data.location) : null;
    this.computed_location = data.computed_location ? PropertyLocationModel.factory(data.computed_location) : this.location;

    this.assignees = (data.assignees || []).map(a => new UserModel(a));
    this.watchers = (data.watchers || []).map(w => new UserModel(w));
    this.attachments = (data.attachments || []).map(f => new FileModel(f));
    this.last_synced_template_attachment_ids = data.last_synced_template_attachment_ids || [];

    this.template = data.template ? new TaskTemplateModel(data.template) : null;

    // recurrance
    this.recurring_end_date = data.recurring_end_date || ''; // 2020-01-01

    this.recurring_every_x_days = data.recurring_every_x_days || null;

    this.recurring_every_x_weeks = data.recurring_every_x_weeks || null;
    this.recurring_days_of_week = data.recurring_days_of_week || [];

    this.recurring_every_x_months = data.recurring_every_x_months || null;
    this.recurring_days_of_month = data.recurring_days_of_month || [];

    // if set, more tasks can be generated
    this.recurring_next_due_date = data.recurring_next_due_date || null;

    this.notify_days_before_due_date = data.notify_days_before_due_date || null;

    this.repeat_type = '';
    if (this.recurring_every_x_days) {
      this.repeat_type = 'day';
    } else if (this.recurring_every_x_weeks) {
      this.repeat_type = 'week';
    } else if (this.recurring_every_x_months) {
      this.repeat_type = 'month';
    }

    this.has_description_history = 'has_description_history' in data ? data.has_description_history : false;
    this.has_time_tracking_data = data.has_time_tracking_data || false;
    this.deleted = 'deleted' in data ? data.deleted : false;
    this.restored = 'restored' in data ? data.restored : false;
    this.exporting = false;

    this.current_instance = data.current_instance ? new TaskInstanceModel(data.current_instance) : null;
    if (this.current_instance) {
      this.current_instance.is_current_instance = true; // always default this flag. API doesn't set on GET detail calls
    }

    this.commentCount = data.comment_count || 0;
    this.unreadCommentCount = data.unread_comment_count || 0;

    // recurring checkboxes
    this.recurringOnMonday = this.recurring_days_of_week.indexOf(0) !== -1;
    this.recurringOnTuesday = this.recurring_days_of_week.indexOf(1) !== -1;
    this.recurringOnWednesday = this.recurring_days_of_week.indexOf(2) !== -1;
    this.recurringOnThursday = this.recurring_days_of_week.indexOf(3) !== -1;
    this.recurringOnFriday = this.recurring_days_of_week.indexOf(4) !== -1;
    this.recurringOnSaturday = this.recurring_days_of_week.indexOf(5) !== -1;
    this.recurringOnSunday = this.recurring_days_of_week.indexOf(6) !== -1;

    this.rowInView = true;
  }

  static fromWorkOrderRequest(workOrderReq) {
    const task = new TaskModel();
    task.work_order_request = workOrderReq;
    task.name = workOrderReq.name;
    task.description = workOrderReq.description;
    task.priority = workOrderReq.priority;
    task.location = workOrderReq.location;
    task.equipment = workOrderReq.equipment;
    task.part = workOrderReq.part;
    task.due_date = workOrderReq.requested_completion_date;
    task.attachments = workOrderReq.attachments;
    task.category = workOrderReq.category ? new OptionModel({ id: workOrderReq.category }) : null;
    task.reviewers = workOrderReq.reviewers;
    task.project = workOrderReq.project;
    return task;
  }

  // did this model come from a MinSerializer? if true we have a
  // partially hydrated model
  get isMinimalTask() {
    return this.groupListTask;
  }

  get priorityDisplay() {
    return getTaskPriorityLabel(this.priority);
  }

  get creator() {
    if (this.user) {
      return `Created by ${this.user.fullName}`;
    }
    return 'System Generated';
  }

  get canComplete() {
    return this.current_instance?.canComplete || false;
  }

  get simpleCreator() {
    if (this.user) {
      return this.user.fullName;
    }
    return 'System Generated';
  }

  get completedBy() {
    return this.current_instance?.completedByDisplay || BaseModel.defaultDisplayValue;
  }

  get completedDate() {
    return this.current_instance?.completedDate || '';
  }

  get completedDateDate() {
    return this.current_instance?.completedDateDate || '';
  }

  get dueDateDate() {
    return this.stringToDate(this.due_date);
  }

  get dueDateFormattedStr() {
    if (!this.due_date) {
      return null;
    }
    const dateParts = this.due_date.split('-');
    if (dateParts.length === 3) {
      const month = parseInt(dateParts[1]);
      const day = parseInt(dateParts[2]);
      return `${month >= 10 ? month : `0${month}`}/${day >= 10 ? day : `0${day}`}/${dateParts[0]}`;
    }
    return '';
  }

  get endDateDate() {
    return this.stringToDate(this.recurring_end_date);
  }

  get taskId() {
    return this.id;
  }

  get isFromTemplate() {
    return Boolean(this.template?.id);
  }

  get isFromPMSchedule() {
    return Boolean(this.pm_schedule?.id);
  }

  assignedToUser(userId) {
    return !!this.assignees.find(a => a.id == userId);
  }

  getRecurringDaysOfWeek() {
    const days = [];

    if (this.recurringOnMonday) {
      days.push(0);
    }

    if (this.recurringOnTuesday) {
      days.push(1);
    }

    if (this.recurringOnWednesday) {
      days.push(2);
    }

    if (this.recurringOnThursday) {
      days.push(3);
    }

    if (this.recurringOnFriday) {
      days.push(4);
    }

    if (this.recurringOnSaturday) {
      days.push(5);
    }

    if (this.recurringOnSunday) {
      days.push(6);
    }

    return days;
  }

  get taskTypeDisplay() {
    let displayLabel = 'Task';

    if (this.work_order_request) {
      displayLabel = 'Work Order';
    } else if (this.pm_schedule) {
      displayLabel = 'PM Work Order';
    }

    return displayLabel;
  }

  get taskTypeDisplayLower() {
    return this.taskTypeDisplay.toLowerCase();
  }

  get dueInDaysClass() {
    if (this.due_date && !this.completed && !this.archived) {
      const dueDate = this.dueDateDate;
      const now = new Date();

      if (dueDate > now) {
        return '';
      }
      return 'red';
    }
    return '';
  }

  get overdue() {
    if (this.due_date && !this.completed && !this.archived) {
      return this.dueDateDate < getCurrentDateNoTime();
    }
    return false;
  }

  get daysOverdue() {
    if (!this.overdue) {
      return 0;
    }
    return Math.abs(moment(this.dueDateDate).diff(moment(), 'days'));
  }

  get dueInDays() {
    if (this.due_date && !this.completed && !this.archived) {
      const dueDate = this.dueDateDate;
      const now = getCurrentDateNoTime();

      if (dueDate > now) {
        // in days
        const numberDays = getNumberOfDays(now, dueDate);
        return `${numberDays} Day${numberDays === 1 ? '' : 's'}`;
      }
      return 'Overdue';
    }

    return '';
  }

  get assignment() {
    if (this.assignees.length) {
      return this.assignees.map(a => a.fullName).join(', ');
    }
    return 'Unassigned';
  }

  get watcherUserIds() {
    return (this.watchers || []).map(w => w.id);
  }

  get statusClass() {
    if (this.overdue) {
      return 'inactive';
    }
    return this.completed ? 'complete' : 'active';
  }

  get displayStatus() {
    if (this.overdue) {
      return 'Overdue';
    }
    return this.completed ? 'Completed' : 'Active';
  }

  get instanceStatus() {
    return this.current_instance?.status || {};
  }

  set instanceStatus(v) {
    if (this.current_instance) {
      this.current_instance.status = v;
    }
  }

  get equipmentName() {
    return (this.equipment && this.equipment.name) || placeholderText;
  }

  get partName() {
    return (this.part && this.part.name) || placeholderText;
  }

  get proposalName() {
    return (this.rfp && this.rfp.name) || placeholderText;
  }

  get location_name() {
    return (this.location && this.location.name) || '';
  }

  get projectName() {
    return (this.project && this.project.name) || BaseModel.defaultDisplayValue;
  }

  getCompanyAttachments(companyId) {
    if (!companyId) {
      companyId = Vue.prototype.$globals.companyId;
    }
    return [...this.attachments.filter(i => !i.displayCreatorOnly || i.company_id === companyId)];
  }

  get completionInfo() {
    if (this.current_instance?.completed_by && this.current_instance?.completedDate) {
      const date = this.stringToDate(this.current_instance.completedDate);
      return `This task was completed by <b>${this.completedBy}</b> on <b>${getFormattedDate(date)}</b>.`;
    }
    return '';
  }

  get repeatSummary() {
    let untilText = '';

    if (this.repeat_type && this.recurring_end_date) {
      let date = this.recurring_end_date;

      if (typeof date === 'string') {
        const dateParts = date.split('-');
        if (dateParts.length === 3) {
          date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
        }
      }
      date = getFormattedDate(date);

      untilText = ` until ${date}`;
    }

    if (this.repeat_type === 'day' && this.recurring_every_x_days) {
      return `Task repeats every${this.recurring_every_x_days === 1 ? '' : ` ${this.recurring_every_x_days} `}day${this.recurring_every_x_days === 1 ? '' : 's'}${untilText}.`;
    }

    if (this.repeat_type === 'week' && this.recurring_every_x_weeks) {
      let weekText = '';
      const daysOfWeek = this.getRecurringDaysOfWeek();

      if (daysOfWeek.length) {
        weekText = ` on ${daysOfWeek.map(day => friendlyDayNameFromInt(day + 1)).join(', ')}`;
      }

      return `Task repeats every${this.recurring_every_x_weeks === 1 ? '' : ` ${this.recurring_every_x_weeks}`} week${this.recurring_every_x_weeks === 1 ? '' : 's'}${weekText}${untilText}.`;
    }

    if (this.repeat_type === 'month' && this.recurring_every_x_months) {
      let monthText = '';
      const daysOfMonth = [];
      for (let i = 1; i <= 31; i++) {
        daysOfMonth.push({
          id: `day_of_month_${i}`,
          index: i,
          checked: this.recurring_days_of_month.indexOf(i) !== -1,
        });
      }

      const selectedDays = daysOfMonth.filter(d => d.checked).map(d => d.index);

      if (selectedDays.length) {
        monthText = ` on day${selectedDays.length === 1 ? '' : 's'} ${selectedDays.join(', ')}`;
      }

      return `Task repeats every${this.recurring_every_x_months === 1 ? '' : ` ${this.recurring_every_x_months}`} month${this.recurring_every_x_months === 1 ? '' : 's'}${monthText}${untilText}.`;
    }

    return 'Task will not repeat';
  }

  addWatcherUsers(users) {
    if (!users?.length) return;
    const currentWatcherIds = this.watcherUserIds;
    const newWatchers = users.filter(u => !currentWatcherIds.includes(u.id)).map(w => new UserModel(w));
    this.watchers = this.watchers.concat(newWatchers);
  }

  removeWatcherUsers(users) {
    if (!users?.length) return;
    const removedWatcherIds = users.map(u => u.id);
    this.watchers = this.watchers.filter(w => !removedWatcherIds.includes(w.id));
  }

  equals(model) {
    const newModel = JSON.stringify(new TaskModel(model));
    const thisModel = JSON.stringify(new TaskModel(this));
    return newModel === thisModel;
  }
}

export default TaskModel;
