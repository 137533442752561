import CachedCompanyTags from '@/cache/CachedCompanyTags';
import CachedCompanyStatuses from '@/cache/CachedCompanyStatuses';
import CachedTerritories from '@/cache/CachedTerritories';
import CachedEquipmentCategories from '@/cache/CachedEquipmentCategories';
import CachedTaskCategories from '@/cache/CachedTaskCategories';
import CachedLocationCategories from '@/cache/CachedLocationCategories';
import CachedLocationTreeUiData from '@/cache/CachedLocationTreeUiData';

export default class CachedDataHelper {
  constructor() {
    this.cachedTags = null;
    this.cachedStatuses = null;
    this.cachedTerritories = null;
    this.cachedEquipmentCategories = null;
    this.cachedTaskCategories = null;
    this.cachedLocationCategories = null;
    this.cachedLocationTreeUiData = null;
  }

  async getActiveCompanyTags(forceRefresh = false) {
    if (!this.cachedTags) {
      this.cachedTags = new CachedCompanyTags();
    }

    const tags = await this.cachedTags.getData(forceRefresh);
    return tags;
  }

  async getCompanyTaskStatuses(forceRefresh = false) {
    if (!this.cachedStatuses) {
      this.cachedStatuses = new CachedCompanyStatuses();
    }

    this.companyTaskStatuses = await this.cachedStatuses.getData(forceRefresh);
    return this.companyTaskStatuses;
  }

  async getCompanyTaskCategories(forceRefresh = false) {
    if (!this.cachedTaskCategories) {
      this.cachedTaskCategories = new CachedTaskCategories();
    }

    const categories = await this.cachedTaskCategories.getData(forceRefresh);
    return categories;
  }

  async getAllTerritories() {
    if (!this.cachedTerritories) {
      this.cachedTerritories = new CachedTerritories();
    }

    const territories = await this.cachedTerritories.getData();
    return territories;
  }

  async getEquipmentCategories() {
    if (!this.cachedEquipmentCategories) {
      this.cachedEquipmentCategories = new CachedEquipmentCategories();
    }
    const categories = await this.cachedEquipmentCategories.getData();
    return categories;
  }

  async getLocationCategories() {
    if (!this.cachedLocationCategories) {
      this.cachedLocationCategories = new CachedLocationCategories();
    }
    const categories = await this.cachedLocationCategories.getData();
    return categories;
  }

  async getLocationTreeUiData() {
    if (!this.cachedLocationTreeUiData) {
      this.cachedLocationTreeUiData = new CachedLocationTreeUiData();
    }
    const locations = await this.cachedLocationTreeUiData.getData();
    return locations || [];
  }

  processPubSubEvent(event_type, data) {
    switch (event_type) {
      case 'location_created':
        this.cachedLocationTreeUiData?.locationAdded(data);
        break;
      case 'location_tree_updated':
        this.cachedLocationTreeUiData?.locationUpdated(data);
        break;
      default:
        break;
    }
  }
}
