import BaseModel from './BaseModel';

export default class CompanyRoleModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.code_name = data.code_name || '';
    this.display_name = data.display_name || '';
    this.permissions = data.permissions || [];
    this.description = data.description || this.descriptions[this.code_name];
    this.member_count = data.member_count || 0;
  }

  get displayName() {
    return this.display_name;
  }

  get fullDisplayText() {
    if (!this.displayName && !this.description) return '';
    if (this.displayName && !this.description) return `<b>${this.displayName}</b>`;
    return `<b>${this.displayName}</b> ${this.description}`;
  }

  get descriptions() {
    return {
      admin: 'Can see and do everything in the account. Has full capabilities.',
      standard: 'Can add and edit equipment, properties, tasks, requests, payment methods, etc. Cannot manage users or cancel unpaid invoices.',
      read_only: 'Can view select company data, but cannot take any actions.',
      work_order_requester: 'Can submit and view work requests.',
      custom: 'Permissions determined by Administrators; modifiable on a user by user basis.',
    };
  }

  get name() {
    return this.display_name;
  }

  get display_label() {
    return this.name;
  }

  get permissionsCount() {
    return this.permissions?.length || 0;
  }

  get canDelete() {
    return this.member_count < 1;
  }
}
