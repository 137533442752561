import BaseModel from './BaseModel';
import { PropertyLocationModel } from './LocationModels';

export default class WeatherAlertModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.headline = data.headline || '';
    this.description = data.description || '';
    this.area_description = data.area_description || '';
    this.event = data.event || '';
    this.severity = data.severity || '';
    this.certainty = data.certainty || '';
    this.urgency = data.urgency || '';
    this.my_affected_properties = (data.my_affected_properties || []).map(p => new PropertyLocationModel(p));
  }
}
