import Vue from 'vue';
import UserModel from './UserModel';
import BaseModel from './BaseModel';
import CopyableMixin from './CopyableMixin';

const USER = 'user_';

export default class CommentModel extends CopyableMixin(BaseModel) {
  static getValue(comment, fragments) {
    let message = comment || '';

    if (fragments) {
      Object.keys(fragments).forEach((pattern) => {
        const object = fragments[pattern];
        message = message.replace(`@${pattern}`, `<${USER}${object.id}>`);
      });
    }

    return message;
  }

  static convertForDisplay(comment, taggedUsers, wrapperTag = '') {
    let message = (comment || '');
    const prefixTag = wrapperTag ? `<${wrapperTag}>` : '';
    const suffixTag = wrapperTag ? `</${wrapperTag}>` : '';

    taggedUsers.forEach((user) => {
      message = message.replace(`<${USER}${user.id}>`, `${prefixTag}@${user.fullName}${suffixTag}`);
    });

    return message;
  }

  init(data) {
    this.id = data.id || 0;
    this.message = data.message || '';
    this.created = data.created ? new Date(data.created) : null;

    this.user = new UserModel(data.user);
    this.tagged_users = (data.tagged_users || []).map(t => new UserModel(t));

    this.active = data.active || false;
    this.was_edited = data.was_edited || false;
  }

  get messageDisplay() {
    return this.getHtmlDisplayValue(CommentModel.convertForDisplay(this.message, this.tagged_users, 'b'));
  }

  get messageEdit() {
    return CommentModel.convertForDisplay(this.message, this.tagged_users);
  }

  currentUserOwnsComment() {
    return this.user?.id === Vue.prototype.$globals.user().id;
  }
}
