import Vue from 'vue';
import _ from 'lodash';
import RFPQuoteRequestModel from '@/models/RFPQuoteRequestModel';
import VendorModel from './VendorModel';
import CompanyModel from './CompanyModel';
import QuotedCompanyModel from './QuotedCompanyModel';
import UserModel from './UserModel';
import CompanyEquipmentModel from './CompanyEquipmentModel';
import PartModel from './PartModel';
import QuoteModel from './QuoteModel';
import FileModel from './FileModel';
import TaskModel from './TaskModel';
import ServiceModel from './ServiceModel';
import { PropertyLocationModel } from './LocationModels';
import RFPEventModel from './RFPEventModel';
import BaseModel from './BaseModel';
import CopyableMixin from './CopyableMixin';
import filters from '../filters';

const titleCase = (str) => {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
};

export default class RFPModel extends CopyableMixin(BaseModel) {
  init(data) {
    this.id = data.id || 0;

    this.status = data.status || ''; // submitted | draft | complete
    this.archived = data.archived || false;
    this.archived_by_sp = data.archived_by_sp || false;

    this.date_submitted = data.date_submitted ? new Date(data.date_submitted) : null;
    this.quote_due_date = data.quote_due_date || '';
    this.quote_accepted_date = data.quote_accepted_date ? new Date(data.quote_accepted_date) : null;

    // default to submitted date ..
    this.created = this.date_submitted;

    this.name = data.name || '';

    this.reference_number = data.reference_number || '';

    // used for list-view
    this.type = data.type || '';
    this.has_final_invoice = data.has_final_invoice || false;

    this.company = data.company ? new CompanyModel(data.company) : null;
    this.accepted_quote = data.quote ? new QuoteModel(data.quote) : null;
    this.user = data.user ? new UserModel(data.user) : null;
    this.matched_service_provider = data.matched_service_provider ? new CompanyModel(data.matched_service_provider) : null;
    this.eo_requested_vendors = (data.eo_requested_vendors || []).map(c => new CompanyModel(c));
    this.quote_requested_companies = (data.quote_requested_companies || []).map(c => new QuotedCompanyModel(c));
    this.quote_requested_company_count = data.quote_requested_company_count || this.quote_requested_companies.length || 0;

    this.helix_fee_percentage = parseFloat(data.helix_fee_percentage) || parseFloat(data.requesting_provider_helix_fee_percentage) || 0;

    this.location = data.location ? PropertyLocationModel.factory(data.location) : null;

    // set after load
    this.quotes = [];
    this.invoices = [];

    this.service_provider_assignees = (data.service_provider_assignees || []).map(p => new UserModel(p));

    this.scheduled_services = (data.scheduled_services || []).map(s => new ServiceModel(s));

    this.installation_requested = data.installation_requested || false;
    this.eo_can_manually_mark_as_complete = data.eo_can_manually_mark_as_complete || false;

    // todo: remove most of these that aren't used anymore

    this.service_agreement_type = data.service_agreement_type || '';
    this.priority = data.priority || '';
    this.proposal_type = data.proposal_type || '';

    this.preferred_vendor = data.preferred_vendor ? new VendorModel(data.preferred_vendor) : null;
    this.preferred_vendor_other = data.preferred_vendor_other || '';

    this.new_equipment_category = data.new_equipment_category || '';
    this.new_equipment_description = data.new_equipment_description || '';
    this.energy_assessment_category = data.energy_assessment_category || '';
    this.statement_to_energy_assessment_provider = data.statement_to_energy_assessment_provider || '';
    this.energy_savings_category = data.energy_savings_category || '';
    this.statement_to_energy_savings_provider = data.statement_to_energy_savings_provider || '';
    this.rental_equipment_category = data.rental_equipment_category || '';
    this.length_of_rental_equipment_term = data.length_of_rental_equipment_term || '';

    this.assets = (data.assets || []).map(a => new CompanyEquipmentModel(a));
    this.tasks = (data.my_company_tasks || data.tasks || []).map(a => new TaskModel(a));
    this.parts = (data.parts || []).map(partResponse => new PartModel({
      ...partResponse.part,
      quantity: partResponse.quantity,
    }));

    this.estimated_run_hours_per_year = data.estimated_run_hours_per_year || '';

    this.length_of_service_agreement_term_years = data.length_of_service_agreement_term_years || 5;

    this.project_timeline = data.project_timeline || '';
    this.additional_comments = data.additional_comments || '';

    this.attachments = (data.attachments || []).map(f => new FileModel(f));

    if (typeof data.replace_existing_unit !== 'undefined') {
      this.replace_existing_unit = data.replace_existing_unit || false;
    } else {
      this.replace_existing_unit = null;
    }

    this.quote_requested_company_count = data.quote_requested_company_count || 0;
    this.quote_received_company_count = data.quote_received_company_count || 0;
    this.my_sent_quote_count = data.my_sent_quote_count || 0; // for SP get
    this.requesting_provider_info = data.requesting_provider_info ? new RFPQuoteRequestModel(data.requesting_provider_info) : null;
    this.point_of_contact = data.point_of_contact ? new UserModel(data.point_of_contact) : null;

    this.my_quote_request = data.quote_request ? new RFPQuoteRequestModel(data.quote_request) : null;

    this.cancellation_event = data.cancellation_event ? new RFPEventModel(data.cancellation_event) : null;
  }

  get requesting_provider_status() {
    return this.requesting_provider_info?.status || '';
  }

  get simpleCreator() {
    if (this.user) {
      return this.user.fullName;
    }
    return '';
  }

  get companyId() {
    return (this.company && this.company.id) || 0;
  }

  get wasRejected() {
    const rejectedStatuses = [
      'sp_declined',
      'eo_declined',
      'owner_declined',
      'rejected',
      'eo_canceled',
      'owner_canceled',
      'other_sp_accepted',
    ];
    return this.requesting_provider_status && rejectedStatuses.indexOf(this.requesting_provider_status) !== -1;
  }

  get rejectedErrorMessage() {
    if (this.status === 'canceled') {
      if (this.cancellation_event) {
        return `The RFP was canceled by the client on ${filters.dateOnly(this.cancellation_event.created)}.`;
      }
      return 'The RFP was canceled by the client.';
    }

    switch (this.requesting_provider_status) {
      case 'owner_declined':
      case 'eo_declined':
      case 'other_sp_accepted':
        if (this.requesting_provider_info?.last_status_update) {
          return `The client declined service on ${filters.dateOnly(this.requesting_provider_info.last_status_update)}.`;
        }
        return 'The client declined service.';
      case 'sp_declined':
        if (this.requesting_provider_info?.last_status_update) {
          return `You declined this RFP on ${filters.dateOnly(this.requesting_provider_info.last_status_update)}.`;
        }
        return 'You declined this RFP.';
      default:
        return 'Your quote has been rejected!';
    }
  }

  get quotedSPHtml() {
    let info = '';

    if (this.status === 'matched') {
      this.quote_requested_companies.forEach((c) => {
        if (c.display_label) {
          info += `${c.display_label} (${c.quoteStatus}) <br>`;
        }
      });
    }

    return info;
  }

  get additionalCommentsHtml() {
    if (!this.additional_comments) {
      return BaseModel.defaultDisplayValue;
    }
    return this.getHtmlDisplayValue(this.additional_comments);
  }

  get dueDateDate() {
    return this.stringToDate(this.quote_due_date);
  }

  get task() {
    return this.tasks.length ? this.tasks[0] : null;
  }

  get askAboutInstallation() {
    return this.type === 'parts_order' || this.type === 'new_equipment' || this.type === 'rental_equipment';
  }

  get service_provider() {
    if (this.matched_service_provider) {
      return this.matched_service_provider && this.matched_service_provider.name;
    }
    return '';
  }

  get client() {
    return this.company && this.company.name;
  }

  get display_label() {
    return `${this.name} (${this.statusDisplay})`;
  }

  get displayType() {
    return titleCase(this.type.replace(/_/g, ' '));
  }

  get timelineDisplay() {
    if (this.project_timeline === 'flexible') {
      return 'Flexible';
    } if (this.project_timeline === 'asap') {
      return 'ASAP';
    }
    return '';
  }

  get allImageUrls() {
    return [
      ..._.flatten(this.assets.map(a => a.images)),
      ..._.flatten(this.parts.map(a => a.images)),
    ];
  }

  copy(model) {
    super.copy(model);

    // convert models to strings for edit form
    this.preferred_vendor = (model.preferred_vendor && model.preferred_vendor.id) || '';

    if (this.preferred_vendor_other) {
      this.preferred_vendor = 'other';
    }
  }

  isAssigned(id) {
    return this.service_provider_assignees.filter(a => a.id === id).length > 0;
  }

  get statusDisplayForSP() {
    if (this.status === 'matched' && this.my_sent_quote_count) {
      return `Submitted ${this.my_sent_quote_count} Quote${this.my_sent_quote_count === 1 ? '' : 's'}`;
    }
    return this.statusDisplay;
  }

  get isCompleted() {
    return this.status == 'complete';
  }

  get statusDisplay() {
    if (this.status === 'draft') {
      return 'Draft';
    }

    if (this.status === 'submitted') {
      return 'Submitted';
    }

    if (this.status === 'canceled') {
      return 'Canceled';
    }

    if (this.status === 'matched') {
      if (this.quote_requested_company_count && Vue.prototype.$globals.selectedDashboard === 'owner') {
        return `${this.quote_received_company_count} of ${this.quote_requested_company_count} Quoted`;
      }

      if (Vue.prototype.$globals.selectedDashboard === 'eo' && this.quote_received_company_count) {
        return `${this.quote_received_company_count} of ${this.quote_requested_company_count} Quoted`;
      }

      return 'Matched';
    }

    if (this.status === 'quoted') {
      return 'Quoted';
    }
    if (this.status === 'requoting') {
      return 'Requested a Requote';
    }
    if (this.status === 'service_in_progress') {
      return 'Service in Progress';
    }
    if (this.status === 'complete') {
      return 'Completed';
    }
    return this.status || 'Submitted'; // todo: can we add status string to task.rfp ?
  }

  // allow input-select integration to bool
  get _replace_existing_unit() {
    if (this.replace_existing_unit === null) {
      return '';
    }

    return this.replace_existing_unit ? 'true' : 'false';
  }

  set _replace_existing_unit(v) {
    this.replace_existing_unit = v === 'true';
  }

  get equipments() {
    return this.assets;
  }

  set equipments(v) {
    this.assets = v;
  }

  get _length_of_service_agreement_term_years() {
    return `${this.length_of_service_agreement_term_years}`;
  }

  set _length_of_service_agreement_term_years(v) {
    this.length_of_service_agreement_term_years = parseInt(v, 0);
  }

  get canBeCanceled() {
    return ['draft', 'submitted', 'matched'].includes(this.status);
  }

  get canBeMatchedToVendor() {
    return ['submitted', 'matched'].includes(this.status);
  }

  get isCanceled() {
    return this.status === 'canceled';
  }

  get providerMatchedDate() {
    return this.my_quote_request ? this.my_quote_request.created : this.date_submitted;
  }
}
