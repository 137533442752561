import BaseModel from './BaseModel';

export default class OptionModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.name = data.name || '';

    this.code_name = data.code_name || '';
    this.state_code = data.state_code || '';
    // use parent_id to link category options to equip type
    this.parent_category_id = data.parent_category_id || data.parent_category || 0;
    // other models just use parent_id, so store that variable as well for component re-use
    this.parent_id = data.parent_id || this.parent_category_id;
    this.icon = data.icon || '';
    this.icon_prefix = data.icon_prefix || '';

    this.full_path_display_name = data.full_path_display_name || this.name;
    this.root_id = data.root_id || null;
  }

  get displayName() {
    return this.name;
  }

  get display_label() {
    return this.name;
  }
}
