import BaseModel from './BaseModel';
import FileModel from './FileModel';

export default class VendorHubModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.name = data.name || '';
    this.description = data.description || '';
    this.vendor_hub_featured = 'vendor_hub_featured' in data ? data.vendor_hub_featured : false;
    this.vendor_hub_special_offer_title = data.vendor_hub_special_offer_title || '';
    this.vendor_hub_special_offer = data.vendor_hub_special_offer || '';
    this.logo = data.logo ? new FileModel(data.logo) : null;
    this.primary_vendor_hub_media = data.primary_vendor_hub_media || '';
    this.vendor_hub_categories = data.vendor_hub_categories ?? [];
    this.on_platform = 'on_platform' in data ? data.on_platform : false;
  }

  get services() {
    return this.vendor_hub_categories;
  }

  get currentOffer() {
    return {
      title: 'Great Savings Here',
      description: 'Partner with us today to save up to 25% off your next HVAC service appointment.',
    };
  }
}
