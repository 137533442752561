import { youtubeParser } from '@/util.js';
import BaseModel from './BaseModel';
import FileModel from './FileModel';

export default class VendorMediaFileModel extends BaseModel {
  init(data) {
    this.deleted = false;
    this.external_url = data.external_url || '';
    this.file = data.file ? new FileModel(data.file) : null;

    if (this.external_url.includes('youtube.com')) {
      const videoId = youtubeParser(this.external_url);

      this.file = new FileModel({
        preview_url: `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`,
        videoThumbnail: true,
        external_url: this.external_url,
      });
    }
  }
}
