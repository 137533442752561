import BaseModel from './BaseModel';

const iconsByMime = {
  'application/pdf': 'fa fa-file-pdf-o',
  'application/msword': 'fa fa-file-word-o',
  'application/vnd.ms-word': 'fa fa-file-word-o',
  'application/vnd.oasis.opendocument.text': 'fa fa-file-word-o',
  'application/vnd.openxmlformats-officedocument.wordprocessingml': 'fa fa-file-word-o',
  'application/vnd.ms-excel': 'fa fa-file-excel-o',
  'application/vnd.openxmlformats-officedocument.spreadsheetml': 'fa fa-file-excel-o',
  'application/vnd.oasis.opendocument.spreadsheet': 'fa fa-file-excel-o',
  'application/vnd.ms-powerpoint': 'fa fa-file-powerpoint-o',
  'application/vnd.openxmlformats-officedocument.presentationml': 'fa fa-file-powerpoint-o',
  'application/vnd.oasis.opendocument.presentation': 'fa fa-file-powerpoint-o',
  'text/plain': 'fa fa-file-text-o',
  'text/html': 'fa fa-file-code-o',
  'application/json': 'fa fa-file-code-o',
};

export default class FileModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.virus_scan_status = (data.virus_scan_status || '').toLowerCase(); // virus scanning status
    this.company_id = data.company_id || 0;
    this.signed_url = data.signed_url || '';
    this.preview_url = data.preview_url || data.url || '';
    this.thumbnail_url = data.thumbnail_url || '';

    // handle youtube
    this.videoThumbnail = data.videoThumbnail || false;
    this.external_url = data.external_url || '';

    this.download_signed_url = data.download_signed_url || '';
    this.filename = data.filename || data.name || '';
    this.filesize = data.filesize || data.size || '';
    this.filetype = data.filetype || data.type || '';
    this.related_record_context = data.related_record_context || ''; // used for "type" of attachment file

    this.created = data.created ? new Date(data.created) : new Date();
    this.user = data.user || null; // { id, email, first_name, last_name }
    this.company = data.company || null; // { id, name }

    if (this.company && !this.company_id) {
      this.company_id = this.company.id;
    }

    if (this.user && this.user.name) {
      // the ui checks for first_name, last_name
      this.user.first_name = this.user.name;
    }
  }

  getTransformedImageUrl(named_transform = 'thumbnail') {
    if (!this.thumbnail_url) return this.url;

    const baseUrl = this.thumbnail_url.split('?')[0];
    return `${baseUrl}?tr=n-${named_transform}`;
  }

  get displayCreatorOnly() {
    if (!this.company_id) {
      // do not block display of admin files
      return false;
    }
    return !this.virus_scan_status || ['infected', 'error', 'in_progress'].indexOf(this.virus_scan_status) !== -1;
  }

  get url() {
    if (this.virus_scan_status === 'infected') {
      // no link, virus..
      return '';
    }

    const viewUrl = this.preview_url || this.signed_url || '';

    if (viewUrl) {
      return viewUrl.replace('ufs/AmazonS3:Uploads', 'file-upload');
    }
    return viewUrl;
  }

  get downloadUrl() {
    if (this.virus_scan_status === 'infected') {
      // no link, virus..
      return '';
    }

    return this.download_signed_url || this.url;
  }

  get isImage() {
    return this.filetype.indexOf('image') !== -1;
  }

  get isAudio() {
    return this.filetype.indexOf('audio') !== -1;
  }

  get isVideo() {
    return this.filetype.indexOf('video') !== -1;
  }

  get fileIcon() {
    if (this.isImage) {
      return 'fa fa-file';
    }

    if (this.isAudio) {
      return 'fa fa-audio';
    }

    if (this.isVideo) {
      return 'fa fa-video';
    }

    return iconsByMime[this.filetype] || 'fa fa-file';
  }

  get lightBoxData() {
    if (this.isVideo || this.videoThumbnail) {
      return {
        title: '',
        description: '',
        thumb: this.url,
        src: this.external_url,
      };
    }
    return this.url;
  }

  static BaseFilesProps = () => ({
    saving: false,
    files: [], // [FileModel,...]
  });
}
