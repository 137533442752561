const RelatedCompanyContactClientMixin = SuperClass => class extends SuperClass {
  getRelatedCompanyContactParams({
    name,
    title,
    email,
    phone,
    phone_ext,
    cell,
    fax,
  }) {
    return {
      name,
      title: title || null,
      email: email || null,
      phone: (phone || '').replace(/[^0-9+]/g, '') || null,
      phone_ext: (phone_ext || null),
      cell: (cell || '').replace(/[^0-9+]/g, '') || null,
      fax: (fax || '').replace(/[^0-9+]/g, '') || null,
    };
  }
};

export default RelatedCompanyContactClientMixin;
