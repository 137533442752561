import BaseAPIClient from './base/BaseAPIClient';
import ListResponseModel from '../models/ListResponseModel';
import VendorHubModel from '../models/VendorHubModel';
import VendorHubCategoryModel from '../models/VendorHubCategoryModel';
import VendorHubResultModel from '../models/VendorHubResultModel';
import MyRelatedCompanyModel from '../models/MyRelatedCompanyModel';
import CommentModel from '../models/CommentModel';
import RelatedCompanyContactModel from '../models/RelatedCompanyContactModel';
import RelatedCompanyContactClientMixin from './RelatedCompanyContactClientMixin';

class SimpleModel {
  constructor(data) {
    this.id = data.id || 0;
    this.display_name = data.display_name || '';
  }

  get display_label() {
    return this.display_name || '';
  }
}

export default class VendorHubClient extends RelatedCompanyContactClientMixin(BaseAPIClient) {
  getCategories() {
    return this._get('vendor_hub/categories/').then(m => new ListResponseModel(m, VendorHubCategoryModel));
  }

  getCategory(id) {
    return this._get(`vendor_hub/categories/${id}/`).then(m => new VendorHubCategoryModel(m));
  }

  getCities(query) {
    const params = {
      limit: 25,
      offset: 0,
      search: query,
    };

    return this._get('vendor_hub/cities/', params).then(m => new ListResponseModel(m, SimpleModel));
  }

  getCity(id) {
    return this._get(`vendor_hub/cities/${id}/`).then(m => new SimpleModel(m));
  }

  getClosestCity() {
    return this._get('vendor_hub/cities/closest/').then(m => new SimpleModel(m));
  }

  getVendors({ limit = 50, offset = 0, ordering, city_id, vendor_hub_category_id }) {
    const params = {
      limit,
      offset,
      ordering,
      city_id,
      vendor_hub_category_id,
    };

    return this._get('vendor_hub/vendors/', params).then(m => new ListResponseModel(m, VendorHubModel));
  }

  getSearchResults(search) {
    return this._get('vendor_hub/search/autocomplete/', { search }).then(m => new ListResponseModel(m, VendorHubResultModel));
  }

  getVendor(id) {
    return this._get(`vendor_hub/vendors/${id}/`).then(m => new MyRelatedCompanyModel(m));
  }

  setVendorHubMedia(items) {
    return this._post('vendor_hub/vendors/media/', {
      media: items.map((i) => {
        if (i.file?.id) {
          return {
            file: i.file.id,
          };
        }

        return {
          external_url: i.external_url,
        };
      }),
    });
  }

  editVendorContact(contactId, data) {
    return this._patch(`vendor_hub/vendors/contacts/${contactId}/`, this.getRelatedCompanyContactParams(data)).then(c => new RelatedCompanyContactModel(c));
  }

  addVendorContact(vendorId, data) {
    return this._post(`vendor_hub/vendors/${vendorId}/contacts/`, this.getRelatedCompanyContactParams(data)).then(c => new RelatedCompanyContactModel(c));
  }

  deleteVendorContact(contactId) {
    return this._delete(`vendor_hub/vendors/contacts/${contactId}/`);
  }

  getVendorComments(id, limit = 50, offset = 0) {
    const params = {
      limit,
      offset,
      active: true,
    };
    return this._get(`vendor_hub/vendors/${id}/comments/`, params).then(m => new ListResponseModel(m, CommentModel));
  }

  addVendorComment(id, message) {
    return this._post(`vendor_hub/vendors/${id}/comments/`, {
      message,
    }).then(res => new CommentModel(res));
  }

  requestVendorOnPlatform(id) {
    return this._post(`vendor_hub/vendors/${id}/off_platform_request/`);
  }
}
