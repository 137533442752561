import Vue from 'vue';

import CommentModel from './CommentModel';
import ObjectLogModel from './ObjectLogModel';
import UserModel from './UserModel';

export default class ReservationLogModel extends ObjectLogModel {
  init(data) {
    super.init(data);

    this.reservation_id = data.reservation_id || 0;
    this.comment = data.comment ? new CommentModel(data.comment) : null;
    this.related_user = data.related_user ? new UserModel(data.related_user) : null;

    this.router = null;
  }

  get userDisplayName() {
    if (!this.user || this.field === 'create') {
      return '';
    }

    const loggedInUserId = Vue.prototype.$globals.user()?.id || 0;
    return this.user && this.user.id !== loggedInUserId
      ? this.user.fullName
      : 'You';
  }

  get log_display() {
    const createLogMsg = () => {
      const msg = 'Request approved and reservation created';

      if (!this.router) return msg;

      const requestRoute = this.router.resolve({
        name: 'reservation-requests',
        params: { id: this.old_value },
      });
      return `${msg}: <strong><a href="${requestRoute.href}">View Request</a></strong>`;
    };

    const logMessageMap = new Map([
      ['create', createLogMsg],
    ]);

    return logMessageMap.has(this.field)
      ? logMessageMap.get(this.field)()
      : this.log_message;
  }
}
