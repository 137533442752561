import BaseModel from './BaseModel';
import { MarketplaceServiceCTAModel } from './MarketplaceServiceCTAModel';

export default class MarketplaceServiceModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.title = data.title || '';
    this.summary = data.summary || '';
    this.icon = data.icon || ''; // shown on small summary card
    this.logo_url = data.image_url || ''; // shown in detail view, renaming for consistency
    this.business_solution = 'business_solution' in data ? data.business_solution : false;
    this.body = data.body || '';
    this.cta_header = data.cta_header || '';
    this.navigate_to_local_path = data.navigate_to_local_path || '';
    this.ctas = (data.ctas || []).map(c => new MarketplaceServiceCTAModel(c));
    this.can_add_to_my_vendors = data.can_add_to_my_vendors || false;
    this.my_vendors_matched_company_id = data.my_vendors_matched_company_id || 0;
    this.header_image_url = data.header_image_url || '';
    this.navigate_to_local_path = data.navigate_to_local_path || '';
  }

  get isService() {
    return true;
  }

  get hasImage() {
    return this.logo_url !== '';
  }

  get hasHeaderImage() {
    return this.header_image_url !== '';
  }
}
