import Vue from 'vue';

import CachedCompanyData from '@/cache/CachedCompanyData';

export default class CachedTerritories extends CachedCompanyData {
  async retrieveData() {
    const response = await Vue.prototype.$globals.companyClient.getAllTerritories();
    return response.results;
  }

  getCacheExpiration() {
    return 12 * 60 * 60 * 1000;
  }

  get invalidateCacheOnCompanyChange() {
    return false;
  }
}
