import Vue from 'vue';
import BaseAPIClient from './base/BaseAPIClient';
import { featureFlags } from '../constants/featureFlags';

import UserModel from '../models/UserModel';
import FileModel from '../models/FileModel';
import LoginResponseModel from '../models/LoginResponseModel';

export default class UserClient extends BaseAPIClient {
  login({
    email,
    password,
    mfa_token,
    recaptcha_response,
  }) {
    return this._basicAuthPost('users/token/', email, password, { mfa_token, recaptcha_response }).then(r => new LoginResponseModel(r));
  }

  googleLogin({ sso_token, mfa_token }) {
    return this._post('users/sso/login/google/', { sso_token, mfa_token }).then(r => new LoginResponseModel(r));
  }

  getRemoteUser(companyId = null) {
    return this._get('users/me/', null, {
      companyIdOverride: companyId,
    }).then(user => new UserModel(user));
  }

  putCurrentUserUpdatePassword(old_password, password) {
    return this._put('users/me/password/', {
      old_password,
      password,
    });
  }

  editAccount({ first_name, last_name, phone = null, phone_ext = null, mobile_phone = null, enabled_sms, weather_alert_notifications }) {
    const data = {
      first_name,
      last_name,
      enabled_sms,
      weather_alert_notifications,
    };

    if (phone !== null) {
      data.phone = phone.replace(/[^0-9+]/g, '');
    }

    if (phone_ext !== null) {
      data.phone_ext = phone_ext;
    }

    if (mobile_phone !== null) {
      data.mobile_phone = mobile_phone;
    }

    return this._patch('users/profile/', data).then(user => new UserModel(user));
  }

  setUserPushToken(device_token, device_id, platform) {
    return this._post('users/mobile_device/', {
      device_token,
      device_id,
      platform,
    });
  }

  removeUserPushToken(mobile_device_id) {
    return this._delete(`users/mobile_device/${mobile_device_id}/`);
  }

  setDefaultCompany(id) {
    const data = {
      default_company_portal_id: id,
    };

    return this._patch('users/profile/', data);
  }

  setDefaultAffiliate(id) {
    const data = {
      default_affiliate_portal_id: id,
    };

    return this._patch('users/profile/', data);
  }

  acceptTerms() {
    return this._post('users/accept_terms/');
  }

  askSMSLater() {
    return this._patch('users/profile/', {
      enabled_sms: false,
    });
  }

  getCompanyProfile() {
    return this._get('company/users/me/');
  }

  editCompanyProfile(job_title) {
    return this._patch('company/users/me/', {
      job_title,
    });
  }

  setProfileImage(profile_picture) {
    const data = {
      profile_picture,
    };

    return this._patch('users/profile/', data);
  }

  register({
    email,
    first_name,
    last_name,
    password,
    invite_token,
    recaptcha_response,
    affiliate,
    jobTitle,
    phone,
    phoneExt,
    mobilePhone,
  }) {
    const data = {
      email,
      first_name,
      last_name,
      password,
      recaptcha_response,
    };

    if (invite_token) {
      data.invite_token = invite_token;
    }

    if (affiliate) {
      data.affiliate = affiliate;
    }

    if (jobTitle) {
      data.job_title = jobTitle;
    }

    if (phone) {
      data.phone = phone;
    }

    if (phoneExt) {
      data.phone_ext = phoneExt;
    }

    if (mobilePhone) {
      data.mobile_phone = mobilePhone;
    }

    return this._post('users/register/', data);
  }

  confirmUser(confirmation_token) {
    return this._post('users/confirm_email/', {
      confirmation_token,
    });
  }

  forgotPassword(email, recaptcha_response, affiliate) {
    const params = {
      email,
      recaptcha_response,
    };

    if (affiliate) {
      params.affiliate = affiliate;
    }

    return this._post('users/forgot_password/', params);
  }

  resetPassword(token, password) {
    return this._post('users/reset_password/', {
      token,
      password,
    }).then(r => new LoginResponseModel(r));
  }

  submitContact({
    email,
    firstName,
    lastName,
    companyName = null,
    callbackNumber,
    message,
    type,
    recaptcha_response,
  }) {
    return this._post('users/contact_us/', {
      email,
      first_name: firstName,
      last_name: lastName,
      company_name: companyName,
      preferred_callback_number: callbackNumber,
      message,
      type,
      recaptcha_response,
    });
  }

  getInviteToken(token) {
    return this._get(`company/invites/${token}/`);
  }

  acceptInvite(token) {
    return this._post(`company/invites/${token}/accept/`);
  }

  getFeatureFlags(flags = '') {
    if (flags === '') {
      flags = Object.keys(featureFlags).join(',');
    }
    return this._get('feature_flag_values/', {
      flags,
    }).then(r => r.results);
  }

  // files

  getSignedUploadUrl(file, related_record_context = '', fromOwner = false) {
    const data = {};

    if (file) {
      data.filename = file.name || '';
      data.filesize = file.size || '';
      data.filetype = file.type || '';
    }

    if (related_record_context) {
      data.related_record_context = related_record_context;
    }

    // heic will be forced into png
    if (data.filetype === 'image/heic') {
      data.filetype = 'image/png';
    }

    return this._post('file_uploads/uploads/', data, { fromOwner: fromOwner || Vue.prototype.$globals.selectedDashboard === 'owner' }).then(r => new FileModel(r));
  }

  saveFile(url, file) {
    return UserClient.saveFile(url, file);
  }

  static async saveFile(url, file) {
    if (file && file.type === 'image/heic') {
      const { default: heic2any } = await import('heic2any');
      const png = await heic2any({
        blob: file,
        toType: 'image/png',
      }).catch((ex) => {
        console.log(ex);
      });

      if (png) {
        if (file.lastModified) {
          png.lastModified = file.lastModified;
        }

        if (file.lastModifiedDate) {
          png.lastModifiedDate = file.lastModifiedDate;
        }

        if (file.name) {
          png.name = file.name.replace('.heic', '.png');
        }

        return fetch(url, {
          method: 'PUT',
          body: png,
          headers: {
            'Content-type': (png && png.type) || '',
          },
        });
      }
    }

    return fetch(url, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-type': (file && file.type) || '',
      },
    });
  }

  getUserSettings(keys = []) {
    const data = {};
    if (keys.length) {
      data.keys = keys.join(',');
    }
    return this._get('users/key_value/', data).then(s => s);
  }

  setUserSetting(key, value) {
    if (!key && !value) {
      return;
    }
    return this._post('users/key_value/', { key, value }).then(s => s);
  }

  createMfaSecret() {
    return this._post('users/mfa/secret/create/');
  }

  verifyMfaSecret(mfa_token) {
    return this._post('users/mfa/secret/verify/', {
      mfa_token,
    });
  }

  disableTwoFactorAuthentication(mfa_token) {
    return this._post('users/mfa/disable/', {
      mfa_token,
    });
  }

  getPubSubToken() {
    return this._post('users/pubsub/token/');
  }
}
