import EquipmentFieldModel from './EquipmentFieldModel';
import OptionModel from './OptionModel';

export default class CompanyEquipmentCategoryModel extends OptionModel {
  init(data) {
    super.init(data);
    this.ancestor_data = null;
    this.category_specific_fields = (data.category_specific_fields || []).map(f => new EquipmentFieldModel(f));
  }

  get fieldIds() {
    return this.category_specific_fields.map(f => f.id);
  }

  hasDifferingFields(otherCategory) {
    if (!otherCategory || this.fieldIds.length !== otherCategory?.fieldIds?.length) return true;
    return this.fieldIds.sort().join(',') !== otherCategory.fieldIds.sort().join(',');
  }
}
