<template>
  <div
    v-if="$globals.AppLoader"
    id="app"
    class="full-page-loader"
  >
    <Loader />
  </div>
  <div
    v-else
    class="side-bar-layout"
    :class="{'has-top-alert': !!$globals.topAlertText || showShadowBanner}"
    @click="$globals.closeUserMenu()"
  >
    <profile-side-bar />

    <main
      :class="{ 'sidebar-closed': !$globals.SideBarOpen }"
      @click="$globals.closeSidebar()"
    >
      <div
        v-if="$globals.topAlertText"
        class="top-alert"
      >
        {{ $globals.topAlertText }}
        <button
          v-if="$globals.topAlertCtaText"
          class="btn txt-btn"
          @click="$globals.topAlertCtaCallback()"
        >
          {{ $globals.topAlertCtaText }}
        </button>
      </div>
      <div
        class="top-alert shadow-login"
        v-else-if="showShadowBanner"
      >
        <div
          v-if="$globals.shadowCompany && $globals.company()"
          class="left"
        >
          You are currently viewing as <b>{{ $globals.company().name }}</b>
        </div>
        <div
          v-else-if="$globals.shadowUser && $globals.user()"
          class="left"
        >
          You are currently logged in as <b>{{ $globals.user().fullName }} ({{ $globals.user().email }})</b>
        </div>
        <div
          v-else-if="$globals.shadowAffiliate && $globals.selectedAffiliate"
          class="left"
        >
          You are currently viewing the <b>{{ $globals.selectedAffiliate.name }} Affiliate Portal</b>
        </div>
        <div class="right">
          <button
            class="txt-btn"
            type="button"
            @click="$globals.exitShadowLoginMode()"
          >
            <span class="d-none d-md-inline">
              Return to {{ exitShadowLoginPortalType }} Portal
            </span>
            <span class="d-md-none">
              Exit
            </span>
          </button>
        </div>
      </div>
      <helix-header />
      <div style="cursor: not-allowed;">
        <slot />
      </div>
    </main>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
  name: 'ProfileEditorLayout',
  components: {
    ProfileSideBar: defineAsyncComponent(() => import('@/components/ProfileSideBar.vue')),
    Loader: defineAsyncComponent(() => import('@/components/Loader.vue')),
    HelixHeader: defineAsyncComponent(() => import('@/components/HelixHeader.vue')),
  },
  computed: {
    mainClass() {
      return `${this.$route.meta.theme || 'light'}-bg`;
    },
    showShadowBanner() {
      return this.$globals.inShadowLoginMode();
    },
    exitShadowLoginPortalType() {
      const currentShadowInfo = this.$globals.currentShadowInfo;
      if (currentShadowInfo?.origin === 'affiliate') {
        let portalName = currentShadowInfo.originName || '';
        if (portalName) {
          portalName += ' ';
        }
        portalName += 'Affiliate';
        return portalName;
      }
      return 'Owner';
    },
  },
  methods: {
    getClass() {
      return {
        'new-user': !this.$globals.currentUser,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.side-bar-layout {
  main {
    will-change: padding-left;
    display: inline-block;
    vertical-align: top;
    padding-left: 360px;
    margin-left: 0;
    background: $off-gray;
    padding-top: 32px;
    width: 100%;
    font-size: 16px;
    min-height: 100vh;
    transition: padding-left 300ms ease, margin-left 300ms ease;
    margin-top: 80px;

    ::v-deep .header {
      width: 100% !important;
      max-width: calc(100% - 360px);
      transition: max-width 300ms ease;
    }

    &.sidebar-closed {
      padding-left: 20px;

      ::v-deep .header {
        max-width: calc(100% - 80px);

        @media (max-width: $md-max) {
          max-width: 100%;
        }
      }
    }

    @media (max-width: $md-max) {
      padding-left: 0 !important;

      ::v-deep .header {
        max-width: 100%;
      }
    }

    @media (max-width: $xs-max) {
      margin-left: 0 !important;
      width: 100%;
      padding-top: 45px;
    }

    .alert {
      display: none;
      max-width: none;
      left: auto;
      width: calc(100% - 385px);
      transition: width ease 300ms;
      will-change: width;
      z-index: 2;

      &.sidebar-closed {
        width: calc(100% - 165px);
      }

      @media (max-width: $md-max) {
        width: calc(100% - 140px);

        &.sidebar-closed {
          width: calc(100% - 140px);
        }
      }

      @media (max-width: $sm-max) {
        right: 20px;
        width: calc(100% - 105px);

        &.sidebar-closed {
          width: calc(100% - 105px);
        }
      }

      @media (max-width: $xs-max) {
        right: 20px;
        left: 20px;
        width: calc(100% - 40px);
        top: auto;
        bottom: 20px;
      }
    }

    &.new-user {
      padding-left: 0;
    }

    ::v-deep h3 {
      font-size: 24px;
    }
  }
}
</style>
