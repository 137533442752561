import BaseModel from './BaseModel';

export default class AnnouncementModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.title = data.title || '';
    this.body = data.body || '';
    this.featured_display = data.featured_display || '';
    this.created = new Date(data.created);
  }
}
