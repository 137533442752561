import BaseAPIClient from './base/BaseAPIClient';
import ListResponseModel from '../models/ListResponseModel';
import CommentModel from '../models/CommentModel';
import ProspectiveCompanyModel from '../models/ProspectiveCompanyModel';
import MyRelatedCompanyModel from '../models/MyRelatedCompanyModel';
import RelatedCompanyContactModel from '../models/RelatedCompanyContactModel';
import RelatedCompanyContactClientMixin from './RelatedCompanyContactClientMixin';

export default class RelatedCompaniesClient extends RelatedCompanyContactClientMixin(BaseAPIClient) {
  getCompanyVendors({
    search = '',
    ordering = 'name',
    format = '',
    limit = 50,
    offset = 0,
    companyId = 0,
    tags = null,
  }) {
    const params = {
      search,
      ordering,
      limit,
      offset,
      relation_type: 'vendor',
    };

    if (tags && tags.length) {
      params.tags__or = tags.map(t => t.id).join(',');
    }

    if (format) {
      params.format = format;
    }

    const options = {};
    if (companyId) {
      options.companyIdOverride = companyId;
    }

    return this._get('related_companies/', params, options).then(m => new ListResponseModel(m, ProspectiveCompanyModel));
  }

  getCompanyVendorsV2({
    search = '',
    ordering = 'name',
    format = '',
    limit = 50,
    offset = 0,
    companyId = 0,
    tags = null,
    vendor_hub_category_id = '',
    archived = false,
    relation_type = 'vendor',
  }) {
    const params = {
      search,
      ordering,
      limit,
      offset,
      relation_type,
      archived,
    };

    if (tags?.length) {
      params.tags__or = tags.map(t => t.id).join(',');
    }

    if (vendor_hub_category_id) {
      params.vendor_hub_category_id = vendor_hub_category_id;
    }

    if (format) {
      params.format = format;
    }

    const options = {};
    if (companyId) {
      options.companyIdOverride = companyId;
    }

    return this._get('v2/related_companies/', params, options).then(m => new ListResponseModel(m, MyRelatedCompanyModel));
  }

  updateFileData(data, {
    attachments,
    contract_files,
    compliance_files,
    financial_files,
    insurance_files,
    communication_files,
  }) {
    if (attachments?.length) {
      data.attachments = attachments.map(f => f.id);
    }

    if (contract_files?.length) {
      data.contract_files = contract_files.map(f => f.id);
    }

    if (compliance_files?.length) {
      data.compliance_files = compliance_files.map(f => f.id);
    }

    if (financial_files?.length) {
      data.financial_files = financial_files.map(f => f.id);
    }

    if (insurance_files?.length) {
      data.insurance_files = insurance_files.map(f => f.id);
    }

    if (communication_files?.length) {
      data.communication_files = communication_files.map(f => f.id);
    }
  }

  createVendorV2({
    company_name,
    company_email,
    company_phone,
    company_phone_ext,
    website,
    street_address,
    street_address_line_2,
    latitude,
    longitude,
    city,
    state,
    zip,
    location,
    company_logo,
    notes,
    tags,
    service_marketplace_card_id,
    equipment_manufacturers,
    service_types,
    equipment_categories,
    territories_serviced,
    relation_type = 'vendor',
    attachments,
    contract_files,
    compliance_files,
    financial_files,
    insurance_files,
    communication_files,
  }, companyId) {
    const data = {
      relation_type,
    };
    const options = {};

    if (company_name) {
      data.company_name = company_name;
    }
    if (website) {
      data.website = website;
    }
    if (company_email) {
      data.company_email = company_email;
    }
    if (company_phone) {
      data.company_phone = company_phone.replace(/[^0-9+]/g, '');
    }
    if (company_phone_ext) {
      data.company_phone_ext = company_phone_ext;
    }
    if (street_address) {
      data.street_address = street_address;
    }
    if (street_address_line_2) {
      data.street_address_line_2 = street_address_line_2;
    }
    if (latitude) { data.latitude = latitude; }
    if (longitude) { data.longitude = longitude; }
    if (city) {
      data.city = city;
    }
    if (state) {
      data.state = state;
    }
    if (zip) {
      data.zip = zip;
    }
    if (location) {
      data.location = location;
    }
    if (company_logo) {
      data.company_logo = company_logo;
    }
    if (notes) {
      data.notes = notes;
    }
    if (tags) {
      data.tags = tags;
    }
    if (service_marketplace_card_id) {
      data.service_marketplace_card_id = service_marketplace_card_id;
    }

    if (equipment_manufacturers) {
      data.equipment_manufacturers = equipment_manufacturers;
    }
    if (service_types) {
      data.service_types = service_types;
    }
    if (equipment_categories) {
      data.equipment_categories = equipment_categories;
    }
    if (territories_serviced) {
      data.territories_serviced = territories_serviced;
    }

    this.updateFileData(data, {
      attachments,
      contract_files,
      compliance_files,
      financial_files,
      insurance_files,
      communication_files,
    });

    if (companyId) {
      options.companyIdOverride = companyId;
    }

    return this._post('v2/related_companies/', data, options).then(c => new MyRelatedCompanyModel(c));
  }

  editVendorV2(id, data) {
    this.updateFileData(data, { ...data });

    return this._patch(`v2/related_companies/${id}/`, data).then(c => new MyRelatedCompanyModel(c));
  }

  deleteVendorV2(id) {
    return this._patch(`v2/related_companies/${id}/`, { archived: true });
  }

  restoreVendorV2(id) {
    return this._patch(`v2/related_companies/${id}/`, { archived: false });
  }

  getVendorV2(id) {
    return this._get(`v2/related_companies/${id}/`).then(c => new MyRelatedCompanyModel(c));
  }

  getCompanyCustomersV2(data) {
    return this.getCompanyVendorsV2({ ...data, relation_type: 'customer' });
  }

  createCustomerV2(data, companyId) {
    return this.createVendorV2({ ...data, relation_type: 'customer' }, companyId);
  }

  editCustomerV2(id, data) {
    return this.editVendorV2(id, data);
  }

  deleteCustomerV2(id) {
    return this.deleteVendorV2(id);
  }

  restoreCustomerV2(id) {
    return this.restoreVendorV2(id);
  }

  getCustomerV2(id) {
    return this.getVendorV2(id);
  }

  getRelatedCompanyComments(id, limit, offset) {
    const params = {
      limit,
      offset,
      active: true,
    };
    return this._get(`related_companies/${id}/comments/`, params).then(m => new ListResponseModel(m, CommentModel));
  }

  addRelatedCompanyComment(id, message) {
    return this._post(`related_companies/${id}/comments/`, {
      message,
    }).then(res => new CommentModel(res));
  }

  editRelatedCompanyContact(contactId, data) {
    return this._patch(`v2/related_companies/contacts/${contactId}/`, this.getRelatedCompanyContactParams(data)).then(c => new RelatedCompanyContactModel(c));
  }

  addRelatedCompanyContact(vendorId, data) {
    return this._post(`v2/related_companies/${vendorId}/contacts/`, this.getRelatedCompanyContactParams(data)).then(c => new RelatedCompanyContactModel(c));
  }

  deleteRelatedCompanyContact(contactId) {
    return this._delete(`v2/related_companies/contacts/${contactId}/`);
  }

  addExistingVendor(helix_company_id, service_marketplace_card_id) {
    const params = {
      relation_type: 'vendor',
      helix_company_id,
    };

    if (service_marketplace_card_id) {
      params.service_marketplace_card_id = service_marketplace_card_id;
    }

    return this._post('v2/related_companies/', params).then(c => new MyRelatedCompanyModel(c));
  }

  createRelatedCompany({
    company_name,
    contact_name,
    category,
    phone,
    phone_type = 'phone',
    phone_2,
    phone_2_type = 'phone',
    email,
    relation_type,
    helix_company_id = null,
    preferred_contact_method,
    notes,
    tags,
    service_marketplace_card_id,
  }, companyId) {
    const options = {};
    const data = {
      company_name,
      contact_name,
      category,
      relation_type,
      preferred_contact_method,
      tags: tags || [],
    };

    if (email) {
      data.email = email;
    }

    if (phone) {
      data.phone = phone;
      data.phone_type = phone_type;
    }

    if (phone_2) {
      data.phone_2 = phone_2;
      data.phone_2_type = phone_2_type;
    }

    if (notes) {
      data.notes = notes;
    }

    if (!data.preferred_contact_method && data.email) {
      data.preferred_contact_method = 'email';
    }

    if (!data.preferred_contact_method && data.phone) {
      data.preferred_contact_method = 'phone';
    }

    if (helix_company_id) {
      data.helix_company_id = helix_company_id;
    }

    if (service_marketplace_card_id) {
      data.service_marketplace_card_id = service_marketplace_card_id;
    }

    if (companyId) {
      options.companyIdOverride = companyId;
    }

    return this._post('related_companies/', data, options).then(c => new ProspectiveCompanyModel(c));
  }

  editRelatedCompany(id, {
    company_name,
    contact_name,
    category,
    phone,
    phone_type,
    phone_2,
    phone_2_type,
    email,
    preferred_contact_method,
    notes,
    tags,
  }, companyId) {
    const options = {};
    const data = {
      company_name,
      contact_name,
      category,
      preferred_contact_method,
      tags: tags || [],
    };

    if (email) {
      data.email = email;
    } else {
      data.email = null; // clear previously set value
    }

    if (phone) {
      data.phone = phone;
      data.phone_type = phone_type;
    } else {
      data.phone = null;
      data.phone_type = null;
    }

    if (phone_2) {
      data.phone_2 = phone_2;
      data.phone_2_type = phone_2_type;
    } else {
      data.phone_2 = null;
      data.phone_2_type = null;
    }

    if (notes) {
      data.notes = notes;
    } else {
      data.notes = null;
    }

    if (!data.preferred_contact_method && data.email) {
      data.preferred_contact_method = 'email';
    }

    if (!data.preferred_contact_method && data.phone) {
      data.preferred_contact_method = 'phone';
    }

    if (companyId) {
      options.companyIdOverride = companyId;
    }

    return this._patch(`related_companies/${id}/`, data, options);
  }

  getRelatedCompanyCategoryTypeahead(search = '', field = 'category', offset = 0, limit = 9999) {
    const data = {
      search,
      offset,
      limit,
    };
    return this._get(`related_companies/${field}/typeahead/`, data); // .then(t => t);
  }

  removeProspectiveVendor(id) {
    return this._delete(`related_companies/${id}/`);
  }
}
