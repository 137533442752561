import BaseAPIClient from './base/BaseAPIClient';
import ListResponseModel from '../models/ListResponseModel';
import OptionModel from '../models/OptionModel';
import MarketplaceServiceModel from '../models/MarketplaceServiceModel';
import MarketplaceRFPModel from '../models/MarketplaceRFPModel';
import MarketplaceLeadModel from '../models/MarketplaceLeadModel';

export default class MarketplaceClient extends BaseAPIClient {
  getServices(offset = 0, limit = 50, category = null, businessSolutionsOnly = false) {
    const params = {
      limit,
      offset,
      category,
    };

    if (businessSolutionsOnly) {
      params.marketplace_visibility = 'business_solutions';
    }

    return this._get('marketplace/services/', params).then(m => new ListResponseModel(m, MarketplaceServiceModel));
  }

  getServiceCategories(offset = 0, limit = 50) {
    const params = {
      limit,
      offset,
    };
    return this._get('marketplace/services/categories/', params).then(m => new ListResponseModel(m, OptionModel));
  }

  getService(serviceId) {
    return this._get(`marketplace/services/${serviceId}/`).then(s => new MarketplaceServiceModel(s));
  }

  getForm(type, formId) {
    if (type !== 'lead' && type !== 'rfp') {
      throw new Error('Attempted to load unknown form type');
    }

    return this._get(`marketplace/services/${type}_form/${formId}/`).then(
      f => (type === 'lead'
        ? new MarketplaceLeadModel(f)
        : new MarketplaceRFPModel(f)),
    );
  }

  submitRFPForm(form_id, location_id = null, equipment_ids = [], part_ids = [], installation_requested = false, form_field_responses = []) {
    const params = {
      form_id,
      installation_requested,
      form_field_responses,
    };

    if (location_id) {
      params.location_id = location_id;
    }
    if (equipment_ids.length) {
      params.equipment_ids = equipment_ids;
    }
    if (part_ids.length) {
      params.part_ids = part_ids;
    }

    return this._post('marketplace/services/rfp_form/response/', params);
  }

  submitLeadForm(form_id, form_field_responses = [], marketplace_card_ids = []) {
    const params = {
      form_id,
      form_field_responses,
      marketplace_card_ids,
    };

    return this._post('marketplace/services/lead_form/response/', params);
  }

  serviceMarketplaceCtaClicked(cta_id) {
    this._post(`marketplace/services/cta/${cta_id}/clicked/`);
  }
}
