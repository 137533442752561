import Vue from 'vue';

import CommentModel from './CommentModel';
import ObjectLogModel from './ObjectLogModel';
import UserModel from './UserModel';

export default class ReservationRequestLogModel extends ObjectLogModel {
  init(data) {
    super.init(data);

    this.reservation_request_id = data.reservation_request_id || 0;
    this.comment = data.comment ? new CommentModel(data.comment) : null;
    this.related_user = data.related_user ? new UserModel(data.related_user) : null;
  }

  get userDisplayName() {
    if (!this.user) {
      return '';
    }

    const loggedInUserId = Vue.prototype.$globals.user()?.id || 0;
    return this.user && this.user.id !== loggedInUserId
      ? this.user.fullName
      : 'You';
  }

  get log_display() {
    const reasonField = () => (this.log_message.replace(/: (.*)\./, ': <strong>$1</strong>.')); // bold any text between : and .

    const logMessageMap = new Map([
      ['canceled_reason', reasonField],
      ['denied_reason', reasonField],
    ]);

    return logMessageMap.has(this.field)
      ? logMessageMap.get(this.field)()
      : this.log_message;
  }
}
