import BaseAPIClient from './base/BaseAPIClient';
import ListResponseModel from '../models/ListResponseModel';
import TaskModel from '../models/TaskModel';
import WorkOrderRequestModel from '../models/WorkOrderRequestModel';
import CommentModel from '../models/CommentModel';
import TaskLogModel from '../models/TaskLogModel';
import { WorkOrderRequestUiSettingsModel } from '../models/UiSettingsModels';

export default class WorkOrderRequestClient extends BaseAPIClient {
  getWorkOrderRequests({
    search = '',
    ordering = 'name',
    limit = 50,
    offset = 0,
    statuses = null,
    no_pending_status = false,
    categories = [],
    priorities = null,
    requester = null,
    location__ancestor_hierarchy__ancestor_id = 0,
    created_after = null,
    created_before = null,
    requested_completion_date_after = null,
    requested_completion_date_before = null,
    reviewers = null,
    reviewed_by = null,
    no_assigned_reviewer = null,
    archived_by_requester = null,
  }) {
    const params = {
      ordering,
      limit,
      offset,
    };

    if (search) {
      params.search = search;
    }

    if (archived_by_requester !== null) {
      params.archived_by_requester = archived_by_requester;
    }

    if (no_pending_status && !statuses) {
      params.status__not_in = 'pending_review';
    } else if (statuses?.length) {
      params.status__in = statuses;
    }

    if (categories?.length) {
      params.category__in = categories;
    }

    if (priorities?.length) {
      params.priority__in = priorities;
    }

    if (requester) {
      params.user__id = requester;
    }

    if (location__ancestor_hierarchy__ancestor_id) {
      params.location__ancestor_hierarchy__ancestor_id = location__ancestor_hierarchy__ancestor_id;
    }

    if (created_after !== null) {
      params.created_after = created_after;
    }

    if (created_before !== null) {
      params.created_before = created_before;
    }

    if (requested_completion_date_after !== null) {
      params.requested_completion_date_after = requested_completion_date_after;
    }

    if (requested_completion_date_before !== null) {
      params.requested_completion_date_before = requested_completion_date_before;
    }

    if (reviewers !== null) {
      params.reviewers = reviewers;
    }

    if (reviewed_by !== null) {
      params.reviewed_by = reviewed_by;
    }

    if (no_assigned_reviewer !== null) {
      params.no_assigned_reviewer = no_assigned_reviewer;
    }

    return this._get('work_orders/requests/', params).then(requests => new ListResponseModel(requests, WorkOrderRequestModel));
  }

  createWorkOrderRequest({
    name,
    description,
    status,
    category,
    priority,
    requested_completion_date,
    location,
    equipment,
    part,
    sensor,
    attachments,
    project,
  }) {
    const data = {
      name,
      description,
      status,
      category,
      priority,
      location,
      equipment,
      part,
      sensor,
      project,
    };

    data.requested_completion_date = requested_completion_date ? requested_completion_date.split('T')[0] : null;

    if (attachments) {
      data.attachments = attachments.map(f => f.id);
    }

    return this._post('work_orders/requests/', data).then(request => new WorkOrderRequestModel(request));
  }

  getWorkOrderRequest(id) {
    return this._get(`work_orders/requests/${id}/`).then(request => new WorkOrderRequestModel(request));
  }

  addWorkOrderComment(id, message) {
    return this._post(`work_orders/requests/${id}/comments/`, {
      message,
    }).then(res => new CommentModel(res));
  }

  getWorkOrderRequestLogs({
    work_order_request_id,
    limit = 50,
    offset = 0,
    include_comments = null,
    ordering = 'created',
    field = null,
  }) {
    const params = {
      limit,
      offset,
      ordering,
    };

    if (include_comments !== null) {
      params.include_comments = include_comments;
    }

    if (field) {
      params.field = field;
    }

    return this._get(`work_orders/requests/${work_order_request_id}/logs/`, params).then(m => new ListResponseModel(m, TaskLogModel, { workOrderRequestLog: true }));
  }

  approveWorkOrderRequest(id, {
    project,
    name,
    description,
    assignees,
    priority,
    category,

    attachments,
    location,
    equipment,
    part,
    sensor,
    rfp,
    work_type,

    due_date,
    repeat_type,
    recurring_end_date,
    recurring_every_x_days,
    recurring_every_x_weeks,
    recurring_days_of_week,
    recurring_every_x_months,
    recurring_days_of_month,
  }) {
    if (due_date && due_date.toISOString) {
      due_date = due_date.toISOString();
    }

    const data = {
      name,
      due_date,
      priority,
    };

    if (due_date) {
      data.due_date = due_date.split('T')[0];
    }

    if (description) {
      data.description = description;
    }

    data.location = (location !== null) ? location : null;
    data.equipment = (equipment !== null) ? equipment : null;
    data.part = (part !== null) ? part : null;
    data.sensor = (sensor !== null) ? sensor : null;
    data.rfp = (rfp !== null) ? rfp : null;

    if (project) {
      data.project = project;
    }

    if (attachments) {
      data.attachments = attachments.map(f => f.id);
    }

    if (assignees) {
      data.assignees = assignees.map(a => a.id);
    }

    if (category?.id) {
      data.category = category.id;
    }

    if (work_type) {
      data.work_type = work_type;
    }

    data.recurring_every_x_days = null;
    data.recurring_every_x_weeks = null;
    data.recurring_days_of_week = null;
    data.recurring_every_x_months = null;
    data.recurring_days_of_month = null;
    data.recurring_end_date = null;

    if (repeat_type) {
      data.recurring_end_date = recurring_end_date ? recurring_end_date.split('T')[0] : null;

      if (repeat_type === 'day') {
        data.recurring_every_x_days = recurring_every_x_days;
      } else if (repeat_type === 'week') {
        data.recurring_every_x_weeks = recurring_every_x_weeks;
        data.recurring_days_of_week = recurring_days_of_week;
      } else if (repeat_type === 'month') {
        data.recurring_every_x_months = recurring_every_x_months;
        data.recurring_days_of_month = recurring_days_of_month;
      }
    }
    return this._post(`work_orders/requests/${id}/approve/`, data).then(task => new TaskModel(task));
  }

  denyWorkOrderRequest(id, reason, detail) {
    const data = {
      denied_reason: reason,
      additional_reason: detail,
    };
    return this._post(`work_orders/requests/${id}/deny/`, data);
  }

  cancelWorkOrderRequest(id, reason, detail) {
    const data = {
      canceled_reason: reason,
      additional_reason: detail,
    };
    return this._post(`work_orders/requests/${id}/cancel/`, data);
  }

  updateWorkOrderRequestReviewers(id, reviewers) {
    const data = {
      reviewers: reviewers.map(i => i?.id || i),
    };
    return this._patch(`work_orders/requests/${id}/`, data).then(request => new WorkOrderRequestModel(request).taskModel);
  }

  batchArchiveWorkOrderRequests(requestExtIds) {
    return this._post('work_orders/requests/batch/archive/', { work_order_request_external_ids: requestExtIds });
  }

  batchRestoreWorkOrderRequests(requestExtIds) {
    return this._post('work_orders/requests/batch/restore/', { work_order_request_external_ids: requestExtIds });
  }

  getUiSettings() {
    return this._get('work_orders/requests/ui_settings/').then(settings => new WorkOrderRequestUiSettingsModel(settings));
  }

  updateUiSettings({
    search = null,
    sort = null,
    statuses = null,
    task_categories = null,
    priorities = null,
    requester = null,
    locations = null,
    created_date_after = null,
    created_date_before = null,
    completion_date_after = null,
    completion_date_before = null,
    reviewers = null,
    no_assigned_reviewer = null,
  }) {
    const data = {
      search,
      sort,
      task_categories,
      priorities,
      locations,
      created_date_after,
      created_date_before,
      completion_date_after,
      completion_date_before,
      reviewers,
      no_assigned_reviewer,
    };

    if (!statuses?.length) {
      data.statuses = [];
    } else {
      data.statuses = statuses.filter(s => s.id).map(s => s.id);
    }

    if (!task_categories?.length) {
      data.task_categories = [];
    } else {
      data.task_categories = task_categories.map(c => c.id);
    }

    if (!requester || requester === 0) {
      data.requester = null;
    } else if (requester?.id) {
      data.requester = requester.id;
    } else {
      data.requester = requester;
    }

    if (!locations?.length || locations?.[0] === undefined) {
      data.locations = [];
    }

    if (!reviewers) {
      data.reviewers = [];
    } else {
      data.reviewers = reviewers.map(r => r.id);
    }

    if (no_assigned_reviewer === null) {
      data.no_assigned_reviewer = false;
    }

    return this._patch('work_orders/requests/ui_settings/', data).then(settings => new WorkOrderRequestUiSettingsModel(settings));
  }

  getWorkRequestUnseenUiCount() {
    return this._get('work_orders/requests/manager/ui/unseen_count/').then(r => r.count || 0);
  }

  resetWorkRequestUnseenUiCount() {
    return this._post('work_orders/requests/manager/ui/seen/');
  }
}
