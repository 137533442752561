import BaseModel from './BaseModel';
import UserModel from './UserModel';
import { PropertyLocationModel } from './LocationModels';

export default class AutomatedWorkflowModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.name = data.name || '';
    this.description = data.description || '';
    this.active = data.active || false;
    this.created = data.created ? new Date(data.created) : null;
    this.updated = data.updated ? new Date(data.updated) : null;
    this.user = data.user ? new UserModel(data.user) : null;
    this.updated_by = data.updated_by ? new UserModel(data.updated_by) : null;
    this.last_matched_date = data.last_matched_date;

    this.condition_task_categories = data.condition_task_categories || []; // id, name
    this.condition_locations = (data.condition_locations || []).map(x => PropertyLocationModel.factory(x));
    this.condition_created_by_users = (data.condition_created_by_users || []).map(x => new UserModel(x));
    this.result_assigned_users = (data.result_assigned_users || []).map(x => new UserModel(x));
  }

  get createdBy() {
    return this.user?.fullName || '';
  }

  get modifiedDate() {
    return this.updated;
  }

  get modifiedBy() {
    return this.updated_by?.fullName || '';
  }
}
