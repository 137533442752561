import BaseModel from './BaseModel';
import { getEquipmentStatusColor, getEquipmentStatusLabel } from '../constants/options.js';
import { titleCase } from '../util';

export default class AffiliateReportModel extends BaseModel {
  init(data) {
    this.companiesCount = data.companies_count || 0;
    this.usersCount = data.users_count || 0;

    this.companiesInvited = data.companies_invited || 0;
    this.usersInvited = data.users_invited || 0;

    this.companiesRegistered = data.companies_registered || 0;
    this.usersRegistered = data.users_registered || 0;

    this.companiesActive = data.companies_active || 0;
    this.usersActive = data.users_active || 0;

    this.companiesWithEquipment = data.companies_with_equipment || 0;

    this.registeredEquipmentCount = data.registeredEquipmentCount || 0;
    this.unregisteredEquipmentCount = data.unregisteredEquipmentCount || 0;
    this.locationsCount = data.locations_count || 0;

    this.equipmentStatus = data.equipment_status || [];
    this.equipmentStatusChartData = this.equipmentStatus.map(s => [
      getEquipmentStatusLabel(s.status),
      s.count,
    ]);
    this.equipmentStatusChartColors = this.equipmentStatus.map(s => getEquipmentStatusColor(s.status));

    this.equipmentType = data.equipment_type || [];
    this.equipmentTypeChartData = this.equipmentType.map(t => [
      titleCase(t.equipment_type_name),
      t.count,
    ]);
  }

  get companyChartData() {
    return [
      ['All', this.companiesCount],
      ['Unregistered', this.companiesInvited],
      ['Registered', this.companiesRegistered],
      // ['Active', this.companiesActive],
      ['Has Equipment', this.companiesWithEquipment],
    ];
  }

  get userChartData() {
    return [
      ['All', this.usersCount],
      ['Unregistered', this.usersInvited],
      ['Registered', this.usersRegistered],
      // ['Active', this.usersActive],
    ];
  }

  get totalEquipmentCount() {
    return this.registeredEquipmentCount + this.unregisteredEquipmentCount;
  }
}
