import BaseModel from '@/models/BaseModel';

export default class DashboardSuggestionModel extends BaseModel {
  static SuggestionType = Object.freeze({
    YesNo: 'yes_no',
    Choice: 'multiple_choice',
    Upload: 'upload',
    Link: 'web_link',
    Text: 'text',
  });

  init(data) {
    this.id = data.id || 0;
    this.title = data.title || '';
    this.body = data.body || '';
    this.icon = data.icon || '';
    this.success_message = data.success_message || '';
    this.company_display_name = data.company_display_name || '';
    this.company_logo_url = data.company_logo_url || '';
    this.company_logo_thumbnail_url = data.company_logo_thumbnail_url || '';
    this.suggestion_type = data.suggestion_type || '';

    this.is_default = data.is_default || false;

    this.web_link_url = data.web_link_url || '';
    this.multiple_choice_options = data.multiple_choice_options || [];
    this.placeholder_text = data.placeholder_text || '';
    this.button_text = data.button_text || '';
  }

  get choiceOptionsObj() {
    if (!this.multiple_choice_options.length) {
      return [];
    }

    return this.multiple_choice_options.map(c => ({ id: c, display_label: c }));
  }
}
