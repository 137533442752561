import FileModel from './FileModel';
import BaseModel from './BaseModel';
import CopyableMixin from './CopyableMixin';

export default class QuoteModel extends CopyableMixin(BaseModel) {
  init(data) {
    this.id = data.id || 0;

    this.status = data.status || '';
    this.rfp = data.rfp || null; // {id, name, reference_number }

    this.proposal_number = data.proposal_number || '';
    this.reference_number = data.reference_number || '';
    this.service_description = data.service_description || '';

    this.provider = data.provider || null;
    this.customer = data.customer || null;

    this.subtotal_price = data.subtotal_price ? parseFloat(data.subtotal_price) : null;
    this.shipping_price = data.shipping_price ? parseFloat(data.shipping_price) : null;
    this.total_price = data.total_price ? parseFloat(data.total_price) : null;
    this.helix_fee = data.helix_fee ? parseFloat(data.helix_fee) : null;
    this.tax_price = data.tax_price ? parseFloat(data.tax_price) : null;

    this.lead_time = data.lead_time || '';
    this.quote_valid_until = data.quote_valid_until || '';

    this.terms = data.terms || '';
    this.terms_other = data.terms_other || '';

    if (this.terms_other) {
      this.terms = 'other';
    }

    this.attachments = (data.attachments || []).map(f => new FileModel(f));

    this.shipping_cost_unknown = data.shipping_cost_unknown || false;
    this.tax_cost_unknown = data.tax_cost_unknown || false;

    this.created = data.created ? new Date(data.created) : null;

    this.most_recent_response = data.most_recent_response || null; // {response,reason,reason_details}
  }

  get serviceDescriptionHtml() {
    return this.getHtmlDisplayValue(this.service_description);
  }

  get quoteValidUntilDate() {
    return this.stringToDate(this.quote_valid_until);
  }

  get display_label() {
    return `Proposal #${this.proposal_number} (${this.statusDisplay})`;
  }

  get service_provider() {
    return this.provider && this.provider.name;
  }

  get client() {
    return this.customer && this.customer.name;
  }

  get isAccepted() {
    return this.status === 'accepted';
  }

  get isRejected() {
    return this.status === 'rejected';
  }

  get isCanceled() {
    return this.status === 'withdrawn';
  }

  get isRequestedRequote() {
    return this.status === 'requote_requested';
  }

  get isPending() {
    if (this.status) {
      return this.status === 'pending';
    }
    return !this.isAccepted && !this.isRejected && !this.isRequestedRequote && !this.isCanceled;
  }

  get statusDisplay() {
    if (this.isAccepted) {
      return 'Accepted';
    } if (this.isRejected) {
      return 'Rejected';
    } if (this.isCanceled) {
      return 'Withdrawn';
    } if (this.isRequestedRequote) {
      return 'Requested Requote';
    }
    return 'Pending';
  }

  get statusDate() {
    if (!this.most_recent_response?.created) return null;

    if (this.isAccepted || this.isRejected || this.isRequestedRequote) {
      return new Date(this.most_recent_response.created);
    }

    return null;
  }

  get statusReason() {
    return (this.most_recent_response && this.most_recent_response.reason) || '';
  }

  get statusReasonDetails() {
    return (this.most_recent_response && this.most_recent_response.reason_details) || '';
  }

  get displayTerms() {
    if (this.terms_other) {
      return this.terms_other;
    }

    if (this.terms && this.terms === 'payment_in_advance') {
      return 'Payment in Advance';
    }

    if (this.terms && this.terms !== 'other') {
      const spl = this.terms.split('_');
      if (spl.length > 1) {
        return `${spl[0].toUpperCase()} ${spl[1]}`;
      }
    }
    return '';
  }

  get rfpId() {
    if (this.rfp && this.rfp.reference_number) {
      return this.rfp.reference_number;
    }

    return this.rfp;
  }

  get quotedSubTotal() {
    if (this.subtotal_price < 0) {
      return 0;
    }
    return this.subtotal_price || 0;
  }

  get quotedShipping() {
    if (this.shipping_cost_unknown || this.shipping_price < 0) {
      return 0;
    }
    return this.shipping_price || 0;
  }

  get quotedTax() {
    if (this.tax_cost_unknown || this.tax_price < 0) {
      return 0;
    }
    return this.tax_price || 0;
  }

  get quotedTotal() {
    if (this.total_price) return this.total_price;

    return this.quotedSubTotal + this.quotedShipping + this.quotedTax;
  }

  calculateFee(fee) {
    return this.quotedSubTotal * (Math.abs(fee) / 100);
  }

  calculateNet(fee) {
    return (this.quotedTotal - this.calculateFee(fee)) || 0;
  }
}
