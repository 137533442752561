import BaseModel from './BaseModel';
import AffiliateModel from './AffiliateModel';

// Company has no children (i think?)
const _processChildCompany = company => ({
  id: `company_${company.id}`,
  companyId: company.id,
  full_display: company.display_label,
  label: `${company.display_label} (Company)`,
});

// Affiliate can have children company/affiliates
const _processChildAffiliate = (affiliate) => {
  const children = [
    ...affiliate.childAffiliates.map(a => _processChildAffiliate(a)),
    ...affiliate.companies.map(c => _processChildCompany(c)),
  ];

  const data = {
    id: `affiliate_${affiliate.id}`,
    affiliateId: affiliate.id,
    full_display: affiliate.name,
    label: `${affiliate.name} (Affiliate)`,
  };

  if (children.length) {
    data.children = children;
  }
  return data;
};

export default class AffiliateFilterModel extends BaseModel {
  init(data) {
    this.filterOptions = [];
    if (data.affiliate_tree) {
      this.filterOptions.push(_processChildAffiliate(new AffiliateModel(data.affiliate_tree)));
    }
  }
}
