import BaseModel from './BaseModel';
import FormFieldModel from './FormFieldModel';

export default class MarketplaceRFPModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.body = data.body || '';
    this.logo_url = data.logo_url || '';
    this.rfp_name = data.rfp_name || '';
    this.rfp_type = data.rfp_type || '';

    this.include_location_field = data.include_location_field || 'optional';
    this.include_equipment_field = data.include_equipment_field || 'optional';
    this.include_part_field = data.include_part_field || 'optional';
    this.ask_installation_required = 'ask_installation_required' in data ? data.ask_installation_required : false;
    this.form_fields = (data.form_fields || []).map(c => new FormFieldModel(c));

    this.location_field_properties_only = 'location_field_properties_only' in data ? data.location_field_properties_only : false;
  }

  get isRFPForm() {
    return true;
  }

  get title() {
    return this.rfp_name;
  }

  get hasLocation() {
    return this.include_location_field !== 'no';
  }

  get hasEquipment() {
    return this.include_equipment_field !== 'no';
  }

  get hasPart() {
    return this.include_part_field !== 'no';
  }
}
