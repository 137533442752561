import BaseModel from './BaseModel';
import FormFieldModel from './FormFieldModel';

export default class MarketplaceLeadModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.title = data.title || '';
    this.body = data.body || '';
    this.form_fields = (data.form_fields || []).map(c => new FormFieldModel(c));
  }

  get isLeadForm() {
    return true;
  }
}
