import BaseModel from './BaseModel';

const searchTerms = {
  rain: ['fas', 'cloud-rain'],
  lightning: ['fas', 'bolt'],
  sleet: ['fas', 'cloud-sleet'],
  smog: ['fas', 'smog'],
  snow: ['fas', 'cloud-snow'],
  cloud: ['fas', 'clouds'],
  hurricane: ['fas', 'hurricane'],
  wind: ['fas', 'wind'],
  sun: ['fas', 'sun'],
};

export default class WeatherForecastModel extends BaseModel {
  init(data) {
    this.temperature = data.temperature || '';
    this.temperatureUnit = data.temperatureUnit || '';
    this.detailedForecast = data.detailedForecast || '';

    this.city = data.city || '';
    this.state = data.state || '';
  }

  get icon() {
    const lowerDetails = this.detailedForecast.toLowerCase();
    const keys = Object.keys(searchTerms);

    for (let i = 0; i < keys.length; i++) {
      if (lowerDetails.indexOf(keys[i]) !== -1) {
        return searchTerms[keys[i]];
      }
    }

    return ['fas', 'cloud'];
  }

  get temperatureDisplay() {
    return `${this.temperature}\u00B0${this.temperatureUnit}`;
  }

  get locationDisplay() {
    return `${this.city}, ${this.state}`;
  }
}
