import BaseAPIClient from './base/BaseAPIClient';
import ListResponseModel from '../models/ListResponseModel';
import DashboardSuggestionModel from '../models/DashboardSuggestionModel';

export default class SuggestionClient extends BaseAPIClient {
  getDashboardSuggestions(limit = 100, offset = 0) {
    const params = {
      limit,
      offset,
    };
    return this._get('suggestions/dashboard/eo/', params).then(m => new ListResponseModel(m, DashboardSuggestionModel));
  }

  recordImpressions(ids) {
    return this._post('suggestions/dashboard/impressions/', { suggestion_ids: ids });
  }

  recordResponse(id, response, files) {
    const params = {
      response,
      files: files ? files.map(f => f.id) : [],
    };

    return this._post(`suggestions/dashboard/${id}/response/`, params).then(s => new DashboardSuggestionModel(s));
  }

  dismiss(id) {
    return this._post(`suggestions/dashboard/${id}/dismiss/`).then(s => new DashboardSuggestionModel(s));
  }
}
