import Vue from 'vue';
import BaseModel from './BaseModel';
import CompanyModel from './CompanyModel';
import CompanyMembershipModel from './CompanyMembershipModel';
import { PropertyLocationModel } from './LocationModels';
import AffiliateModel from './AffiliateModel';
import { formatPhoneNumber } from '../filters/formatters.js';

export default class UserModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.enabled_sms = data.enabled_sms; // null=not answered yet
    this.weather_alert_notifications = data.weather_alert_notifications; // null=not answered yet
    this.default_company_portal_id = data.default_company_portal_id || 0;
    this.default_affiliate_portal_id = data.default_affiliate_portal_id || 0;

    this.first_name = data.first_name || '';
    this.last_name = data.last_name || '';
    this.email = data.email || '';
    this.email_verified = data.email_verified || null;
    this.job_title = data.job_title || '';
    this._is_active = data._is_active || null; // ???
    this.is_active = data.is_active || null; // task assignee active flag
    this.profile_picture_url = data.profile_picture_url || '';
    this.date_joined = data.date_joined ? new Date(data.date_joined) : null;
    this.last_login = data.last_login ? new Date(data.last_login) : null;
    this.last_sent = data.last_sent ? new Date(data.last_sent) : null;
    this.companies = (data.companies || []).map(c => new CompanyModel(c));
    this.permissions = data.permissions || [];
    this.app_wide_permissions = data.app_wide_permissions || [];

    this._unread_messages_count = data._unread_messages_count || 0;
    this._unread_notifications_count = data._unread_notifications_count || 0;

    this.roles = data.platform_roles || data.roles || null;
    this.first_platform_role = data.first_platform_role || null;
    this.first_company_membership = data.first_company_membership || null;
    this.memberships = (data.memberships || []).map(m => new CompanyMembershipModel(m));
    this.memberships = this.memberships.sort((a, b) => ((a.company.name > b.company.name) ? 1 : -1));
    this.is_affiliate_manager = data.is_affiliate_manager || false;
    this.mfa_enabled = data.mfa_enabled || false;

    this.phone_number = data.phone || data.phone_number || '';
    this.phone_ext = data.phone_ext || '';
    this.mobile_phone = data.mobile_phone || '';

    const companyRestrictedLocations = data.companies?.flatMap(c => c.restricted_locations?.map(id => ({ id })) ?? []);
    this.restricted_locations = (
      data.restricted_locations
      ?? companyRestrictedLocations
      ?? []
    ).map(l => PropertyLocationModel.factory(l));

    this.managed_affiliates = (data.managed_affiliates || []).map(a => new AffiliateModel(a));

    this.require_terms_acceptance = data.require_terms_acceptance || false;

    this.loading = false;
    this.deleting = false;

    // company invite
    if (data.role) {
      this.roles = [data.role];
    }

    if (data.profile_picture && typeof data.profile_picture === 'object') {
      this.profile_picture_url = data.profile_picture.thumbnail_url;
    }
  }

  get ignored_sms() {
    return this.enabled_sms === false;
  }

  get email_address() {
    return this.email;
  }

  get platformRole() {
    return (this.first_platform_role && this.first_platform_role.display_name) || '';
  }

  get userTypeDetails() {
    if (this.permissions?.length && !this.roles) {
      return 'Custom';
    }
    return (this.roles || []).map(r => r.display_name).join(', ');
  }

  get accountAttributes() {
    const suffix = [
      this._is_active ? 'User Active' : 'User Inactive',
    ];

    if (this._is_active) {
      suffix.push(this.email_verified ? 'Email Verified' : 'Email Not Verified');
    }

    if (suffix.length) {
      return suffix.join(', ');
    }
    return '';
  }

  get company_name() {
    if (this.first_company_membership) {
      return this.first_company_membership.company.name;
    }
    return '';
  }

  get company_role() {
    if (this.first_company_membership) {
      return this.first_company_membership.role.display_name;
    }
    return '';
  }

  get userType() {
    if (this.roles?.length) {
      return this.roles[0].code_name;
    }

    if (!this.roles?.length && this.permissions?.length) {
      return 'custom';
    }

    return '';
  }

  get userTypeId() {
    if (this.roles?.length) {
      return this.roles[0].id;
    }

    return 0;
  }

  get display_label() {
    return this.fullName;
  }

  get isEOMember() {
    return this.companies.filter(c => c.is_equipment_operator).length > 0;
  }

  get isSPMember() {
    return this.companies.filter(c => c.is_service_provider).length > 0;
  }

  hasPlatformPermission = function (permission) {
    return this.app_wide_permissions.indexOf(permission) !== -1 || this.hasPlatformPermissionInCurrentCompany(permission);
  };

  get canExportEoEquipmentCsv() {
    return this.canExportCompanyData;
  }

  get canExportEoTasksCsv() {
    return this.canExportCompanyData;
  }

  get canEditGlobalSettings() {
    return this.hasPlatformPermission('update_platform_configuration');
  }

  get canViewAllUsers() {
    return this.hasPlatformPermission('view_all_users');
  }

  get canInviteCompany() {
    return this.hasPlatformPermission('invite_company_and_company_admin');
  }

  get canInviteStaff() {
    return this.hasPlatformPermission('invite_helix_staff');
  }

  get canEditAllUsers() {
    return this.hasPlatformPermission('update_all_users');
  }

  get canViewAllCompanies() {
    return this.hasPlatformPermission('view_all_companies');
  }

  get canEditAllCompanies() {
    return this.hasPlatformPermission('update_all_companies');
  }

  get canShadowCompanies() {
    return this.hasPlatformPermission('full_admin_access_to_all_company_dashboards');
  }

  get canShadowUsers() {
    return this.hasPlatformPermission('impersonate_users');
  }

  get canViewAllRFPs() {
    return this.hasPlatformPermission('view_all_rfps');
  }

  get canEditAllRFPs() {
    return this.hasPlatformPermission('administer_all_rfps');
  }

  get canViewInvoices() {
    return this.hasPlatformPermission('view_all_invoices');
  }

  get canViewQuotes() {
    return this.hasPlatformPermission('view_all_quotes');
  }

  get isOwner() {
    if (Vue.prototype.$globals.isInFlutterApp()) {
      // do not allow owner access in mobile app
      return false;
    }
    // owner portal
    return this.hasPlatformPermission('view_owner_portal');
  }

  get isAffiliate() {
    return this.is_affiliate_manager || this.managed_affiliates?.length > 0 || (Vue.prototype.$globals.selectedAffiliate && Vue.prototype.$globals.selectedDashboard === 'affiliate');
  }

  get canViewBatchAffiliateDataVerification() {
    if (!Vue.prototype.$globals.selectedAffiliate || Vue.prototype.$globals.selectedDashboard !== 'affiliate') {
      return false;
    }

    if (!Vue.prototype.$globals.selectedAffiliate.managers_can_batch_verify_data) {
      return false;
    }

    if (this.ownerCanManageAffiliates) {
      return true;
    }

    return this.canPerformBatchAffiliateDataVerification;
  }

  get selectedAffiliateManager() {
    if (!Vue.prototype.$globals.selectedAffiliate) {
      return null;
    }
    return this.managed_affiliates.find(a => a.id === Vue.prototype.$globals.selectedAffiliate.id);
  }

  get canPerformBatchAffiliateDataVerification() {
    return this.selectedAffiliateManager?.manager_permissions?.can_verify_equipment_data;
  }

  get canShadowAffiliateCompanies() {
    if (this.ownerCanManageAffiliates) return true;

    if (!Vue.prototype.$globals.selectedAffiliate?.managers_can_administer_companies) {
      return false;
    }

    return this.selectedAffiliateManager?.manager_permissions?.can_administer_companies;
  }

  get isAffiliateRiskManager() {
    if (this.ownerCanManageAffiliates) return true;

    return this.selectedAffiliateManager?.manager_permissions?.is_risk_manager;
  }

  get canDownloadAffiliateCompanies() {
    return this.ownerCanManageAffiliates || this.selectedAffiliateManager?.manager_permissions?.can_download_company_data;
  }

  get canDownloadAffiliateProperties() {
    return this.ownerCanManageAffiliates || this.selectedAffiliateManager?.manager_permissions?.can_download_property_data;
  }

  get canDownloadAffiliateUsers() {
    return this.ownerCanManageAffiliates || this.selectedAffiliateManager?.manager_permissions?.can_download_user_data;
  }

  get canManageAffiliateManagers() {
    return this.ownerCanManageAffiliates || this.selectedAffiliateManager?.manager_permissions?.can_administer_affiliate_managers;
  }

  get ownerCanManageAffiliates() {
    return this.hasPlatformPermission('manage_affiliates');
  }

  get canManageAffiliate() {
    const {
      selectedAffiliate,
      shadowAffiliate,
      selectedDashboard,
    } = Vue.prototype.$globals;

    // if we are an owner who selected an affiliate to "shadow"
    if (this.ownerCanManageAffiliates && selectedAffiliate && shadowAffiliate && selectedDashboard === 'affiliate') {
      return true;
    }

    // if we are a regular user who has affiliates to manage
    return this.isAffiliate;
    // return selectedAffiliate && this.managed_affiliates.indexOf(selectedAffiliate.id) !== -1;
  }

  get canViewAffiliates() {
    return this.ownerCanManageAffiliates;
  }

  get canViewAffiliateEquipments() {
    return this.canManageAffiliate;
  }

  get canViewAffiliateLocations() {
    return this.canManageAffiliate;
  }

  get canViewAffiliateUsers() {
    return this.canManageAffiliate;
  }

  get canViewAffiliateCompanies() {
    return this.canManageAffiliate;
  }

  // Company Level Permissions

  get canViewCompanyUsers() { return this.hasPermissionInCurrentCompany('view_users'); }

  get canViewCompanyInvites() { return this.canViewCompanyUsers; }

  get canManageCompanyUsers() { return this.hasPermissionInCurrentCompany('manage_users'); }

  get canInviteCompanyUsers() { return this.canManageCompanyUsers; }

  get canUpdateCompanyUserRoles() { return this.hasPermissionInCurrentCompany('update_user_roles'); }

  get canRemoveCompanyUser() { return this.canManageCompanyUsers; }
  // get canRemoveAdminCompanyUser() { return this.hasPermissionInCurrentCompany('delete_admin_company_user'); }

  get canViewLocations() { return this.hasPermissionInCurrentCompany('view_locations'); }

  get canManageLocations() { return this.hasPermissionInCurrentCompany('manage_locations'); }

  get canEditLocations() { return this.canManageLocations; }

  get canAddLocations() { return this.canManageLocations; }

  get canViewCustomers() { return this.hasPermissionInCurrentCompany('list_clients'); }

  get canViewVendors() { return this.hasPermissionInCurrentCompany('list_vendors'); }

  get canEditVendors() { return this.hasPermissionInCurrentCompany('manage_vendors'); }

  get canAddVendors() { return this.hasPermissionInCurrentCompany('manage_vendors'); }

  get canEditCustomers() { return this.hasPermissionInCurrentCompany('manage_clients'); }

  get canAddCustomers() { return this.hasPermissionInCurrentCompany('manage_clients'); }

  get canViewEquipments() { return this.hasPermissionInCurrentCompany('view_equipment'); }

  get canManageEquipments() { return this.hasPermissionInCurrentCompany('manage_equipment'); }

  get canEditEquipments() { return this.canManageEquipments; }

  get canAddEquipments() { return this.canManageEquipments; }

  get canViewParts() { return this.hasPermissionInCurrentCompany('view_parts'); }

  get canManageParts() { return this.hasPermissionInCurrentCompany('manage_parts'); }

  get canEditParts() { return this.canManageParts; }

  get canDeleteParts() { return this.canManageParts; }

  get canCreateParts() { return this.canManageParts; }

  get canViewCompanyRFPs() { return this.hasPermissionInCurrentCompany('view_requested_rfps'); }

  get canManageCompanyRFPs() { return this.hasPermissionInCurrentCompany('manage_requested_rfps'); }

  get canAddRFP() { return this.canManageCompanyRFPs; }

  get canEditRFPs() { return this.canManageCompanyRFPs; }

  get canPayInvoice() { return this.hasPermissionInCurrentCompany('pay_invoice'); }

  get canReplyToQuote() { return this.hasPermissionInCurrentCompany('respond_to_quote'); }

  // Note: For the vendor RFP workflow, currently, a user who is “assigned” to an RFP, is able to do any action on that RFP.
  get canViewAllMatchedRFPs() { return this.hasPermissionInCurrentCompany('view_all_matched_rfps'); }

  // todo: or assigned to RFP ...

  get canManageMatchedRFP() { return this.hasPermissionInCurrentCompany('manage_all_matched_rfps'); }

  get canEditMatchedRFP() { return this.canManageMatchedRFP; }

  get canSubmitInvoice() { return this.hasPermissionInCurrentCompany('create_invoice_all_matched_rfps'); }

  get canCancelInvoice() { return this.hasPermissionInCurrentCompany('cancel_invoice'); }

  get canDeclineMatch() { return this.hasPermissionInCurrentCompany('decline_all_matched_rfps'); }

  get canSubmitQuote() { return this.canManageMatchedRFP; }

  get canAddScheduledService() { return this.hasPermissionInCurrentCompany('schedule_service_all_matched_rfps'); }

  get canManageAutomatedWorkflows() { return this.hasPermissionInCurrentCompany('manage_automated_workflows'); }

  get canSubmitWorkOrders() { return this.hasPermissionInCurrentCompany('create_work_order_requests'); }

  get canManageWorkOrders() { return this.hasPermissionInCurrentCompany('manage_work_order_requests'); }

  get canViewTasks() { return this.hasPermissionInCurrentCompany('view_tasks'); }

  get canManageTasks() { return this.hasPermissionInCurrentCompany('manage_tasks'); }

  get canAddTasks() { return this.canManageTasks; }

  get canEditTasks() { return this.canManageTasks; }

  get canDeleteTasks() { return this.canManageTasks; }

  get canManageTaskTemplates() { return this.hasPermissionInCurrentCompany('manage_task_templates'); }

  get canViewReceivedInvoices() { return this.hasPermissionInCurrentCompany('view_received_invoices'); }

  get canViewSentInvoices() { return this.hasPermissionInCurrentCompany('view_sent_invoices'); }

  get canViewSentQuotes() { return this.canViewAllMatchedRFPs; }

  get canViewReceivedQuotes() { return this.canViewCompanyRFPs; }

  get canManageStripeConnectedAccount() { return this.hasPermissionInCurrentCompany('manage_stripe_connected_account'); }

  get canAddTagsOrFields() { return this.hasPermissionInCurrentCompany('manage_tags'); }

  get canManageRFPs() { return this.hasPermissionInCurrentCompany('manage_rfps'); }

  get canAdministerCompanyUsers() { return this.hasPermissionInCurrentCompany('administer_users'); }

  get canUpdateCompanyInfo() { return this.hasPermissionInCurrentCompany('update_company_info'); }

  get canManagePaymentMethods() { return this.hasPermissionInCurrentCompany('manage_payment_methods'); }

  get canViewIncentiveReports() { return this.hasPermissionInCurrentCompany('view_incentive_reports'); }

  get canManageIncentiveReports() { return this.hasPermissionInCurrentCompany('manage_incentive_reports'); }

  get canExportCompanyData() { return this.hasPermissionInCurrentCompany('export_company_data'); }

  get canManageRiskAssessments() { return this.hasPermissionInCurrentCompany('submit_risk_assessment_surveys'); }

  get canCreateSensorConnection() { return this.hasPermissionInCurrentCompany('create_sensor_connect'); }

  get canViewSensorDevices() { return this.hasPermissionInCurrentCompany('view_sensor_devices'); }

  get canManagePMSchedules() { return this.hasPermissionInCurrentCompany('manage_pm_schedules'); }

  get canRequestReservations() { return this.hasPermissionInCurrentCompany('create_reservation_requests'); }

  get canManageReservations() { return this.hasPermissionInCurrentCompany('manage_reservations'); }

  get isRequesterOnly() {
    return this.currentCompany?.company_permissions?.length === 2
      && this.currentCompany.company_permissions.includes('create_work_order_requests')
      && this.currentCompany.company_permissions.includes('create_reservation_requests');
  }

  hasPermissionInCurrentCompany = function (permission) {
    if ((this.isOwner || this.isAffiliate) && Vue.prototype.$globals.inShadowLoginMode()) {
      return true;
    }
    return this.currentCompany && this.currentCompany.company_permissions && this.currentCompany.company_permissions.indexOf(permission) !== -1;
  };

  hasPlatformPermissionInCurrentCompany = function (permission) {
    return this.currentCompany && this.currentCompany.platform_permissions && this.currentCompany.platform_permissions.indexOf(permission) !== -1;
  };

  get emailVerified() {
    if (this.email_verified) {
      return 'Yes';
    }
    return 'No';
  }

  get active() {
    if (this._is_active) {
      return 'Yes';
    }
    return 'No';
  }

  get avatar_url() {
    return this.profile_picture_url || '';
  }

  clear_avatar_url() {
    return this.profile_picture_url = null;
  }

  get companyId() {
    const company = this.currentCompany;
    if (company) {
      return company.id;
    }
    return null;
  }

  get selectedCompanyName() {
    if (this.isAffiliate && Vue.prototype.$globals.selectedDashboard === 'affiliate') {
      return 'Affiliate Manager';
    }

    if (this.isOwner && Vue.prototype.$globals.selectedDashboard === 'owner') {
      return 'HelixIntel Owner';
    }

    const company = this.currentCompany;
    if (company) {
      return company.name || '';
    }

    if (this.isOwner) {
      return 'HelixIntel Owner';
    }

    if (this.isAffiliate) {
      return 'Affiliate Manager';
    }

    return '';
  }

  get currentCompany() {
    const companyId = parseInt(Vue.prototype.$globals.companyId);
    if (companyId) {
      const shadowCompany = Vue.prototype.$globals.shadowCompany;

      if (shadowCompany && shadowCompany.id === companyId) {
        return shadowCompany;
      }

      const companies = this.companies.filter(o => o.id === companyId);
      if (companies.length) {
        return companies[0];
      }
    }

    return this.defaultCompany;
  }

  get defaultCompany() {
    if (!this.companies?.length) return null;

    let company = null;
    if (this.default_company_portal_id) {
      company = this.companies.find(c => c.id === this.default_company_portal_id);
    } else if (this.first_company_membership?.company?.id) {
      company = this.companies.find(c => c.id === this.first_company_membership.company.id);
    }

    if (!company) {
      company = this.companies[0];
    }

    return company;
  }

  get completedSignup() {
    if (Vue.prototype.$globals.selectedDashboard === 'owner' || !!Vue.prototype.$globals.shadowCompany) {
      return true;
    }
    return this.companies.length > 0;
  }

  get homeRouteName() {
    const selectedDashboard = Vue.prototype.$globals.selectedDashboard || 'eo';

    if (selectedDashboard === 'owner') {
      return 'owner';
    }

    if (selectedDashboard === 'affiliate') {
      return 'affiliate';
    }

    if (!this.completedSignup) {
      if (this.isOwner) {
        return 'owner';
      }
      if (this.isAffiliate) {
        return 'affiliate';
      }
      return 'registration';
    }

    if (this.isRequesterOnly) { // TODO: revisit this after reservations are live
      return 'work-requests';
    }

    return selectedDashboard === 'sp' ? 'vendor-dashboard' : 'dashboard';
  }

  get fullNameShort() {
    const nameParts = this.fullName.split(' ');
    if (nameParts.length > 1) {
      return `${nameParts[0]} ${nameParts[1][0]}`;
    }
    return this.fullName;
  }

  get fullName() {
    const nameParts = [];
    if (this.first_name) {
      nameParts.push(this.first_name);
    }
    if (this.last_name) {
      nameParts.push(this.last_name);
    }

    if (!nameParts.length) {
      return this.email;
    }

    return nameParts.join(' ');
  }

  // used for mention
  get value() {
    return this.fullName;
  }

  get initials() {
    let initials = '';

    if (this.first_name.length) {
      initials += this.first_name[0];
    }

    if (this.last_name.length) {
      initials += this.last_name[0];
    }

    if (!initials) {
      initials += this.email[0];
    }

    return initials.toUpperCase();
  }

  get firstInitial() {
    return this.first_name.length ? this.first_name[0].toUpperCase() : '';
  }

  get formattedPhone() {
    return formatPhoneNumber(this.phone_number) || '';
  }

  get fullPhone() {
    if (this.phone_ext) {
      return `${this.formattedPhone} x${this.phone_ext}`;
    }

    return this.formattedPhone;
  }
}
