import BaseModel from './BaseModel';

import CompanyRoleModel from './CompanyRoleModel';
import CompanyModel from './CompanyModel';

export default class CompanyMembershipModel extends BaseModel {
  init(data) {
    this.company = new CompanyModel(data.company || {});
    this.role = new CompanyRoleModel(data.role || {});
  }

  get companyNameAndRoleDisplay() {
    if (!this.company.name) return '';
    const role = this.role?.displayName || 'Custom';
    return `${this.company.name} (${role})`;
  }
}
