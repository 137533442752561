import { isOnlyHtmlTags } from '@/util';
import BaseModel from './BaseModel';
import CopyableMixin from './CopyableMixin';

export default class ServiceModel extends CopyableMixin(BaseModel) {
  init(data) {
    this.id = data.id || 0;

    this.type = data.type || '';
    this.short_description = data.short_description || '';
    this.tracking_number = data.tracking_number || '';
    this.other_details = data.other_details || '';

    this.service_date = data.service_date || '';
    this.estimated_ship_date = data.estimated_ship_date || '';
    this.estimated_delivery_date = data.estimated_delivery_date || '';

    this.archived = false;

    // hack for html field clearing
    if (isOnlyHtmlTags(this.short_description)) {
      this.short_description = '';
    }

    if (isOnlyHtmlTags(this.other_details)) {
      this.other_details = '';
    }
  }

  get shortDescriptionHtml() {
    return this.getHtmlDisplayValue(this.short_description);
  }

  get otherDetailsHtml() {
    return this.getHtmlDisplayValue(this.other_details);
  }

  get serviceDateDate() {
    return this.stringToDate(this.service_date);
  }

  get estimatedShipDateDate() {
    return this.stringToDate(this.estimated_ship_date);
  }

  get estimatedDeliveryDateDate() {
    return this.stringToDate(this.estimated_delivery_date);
  }

  get displayType() {
    return this.type === 'on_site_service' ? 'On-Site Service' : 'Delivery';
  }
}
