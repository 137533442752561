import CompanyModel from './CompanyModel';
import CompanyEquipmentModel from './CompanyEquipmentModel';

export default class AffiliateEquipmentModel extends CompanyEquipmentModel {
  init(data) {
    super.init(data);
    this.company = data.company ? new CompanyModel(data.company) : null;
  }

  get company_name() {
    return (this.company && this.company.name) || '';
  }

  get equipment_type_name() {
    return (this._equipment_type && this._equipment_type.name) || '';
  }

  get equipment_category_name() {
    return (this._equipment_category && this._equipment_category.name) || '';
  }

  get equipment_category_2_name() {
    return (this._equipment_category_2 && this._equipment_category_2.name) || '';
  }
}
