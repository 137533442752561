import moment from 'moment';
import BaseModel from './BaseModel';

class BankAccountModel extends BaseModel {
  init(data) {
    this.status = data.status || '';
    this.bank_name = data.bank_name || '';
    this.last4 = data.last4 || '';
  }
}

class CreditCardAccountModel extends BaseModel {
  init(data) {
    this.brand = data.brand || '';
    this.last4 = data.last4 || '';
    this.exp_month = data.exp_month || '';
    this.exp_year = data.exp_year || '';
  }
}

export default class PaymentMethodModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.type = data.type || '';
    this.stripe_bank_account = data.stripe_bank_account ? new BankAccountModel(data.stripe_bank_account) : null;
    this.stripe_credit_card = data.stripe_payment_method && data.stripe_payment_method.card ? new CreditCardAccountModel(data.stripe_payment_method.card) : null;

    this.ready_to_use = data.ready_to_use || false;
    this.default_payment_method = data.default_payment_method || false;

    this.created = data.created ? new Date(data.created) : null;
    this.last_used = data.last_used ? new Date(data.last_used) : null;
  }

  get isBankAccount() {
    return !!this.stripe_bank_account;
  }

  get isCreditCard() {
    return !!this.stripe_credit_card;
  }

  get isExpired() {
    if (!this.isCreditCard) { return false; }

    const day = 1;
    const month = this.stripe_credit_card.exp_month;
    const year = this.stripe_credit_card.exp_year;

    return moment({ day, month, year }).isBefore();
  }

  get display_label() {
    return this.details;
  }

  get displayType() {
    return this.type === 'card' ? 'Credit Card' : 'Bank Account';
  }

  get status() {
    if (!this.ready_to_use && this.type !== 'card') {
      return 'Pending Verification';
    }

    return this.isExpired ? 'Inactive' : 'Active';
  }

  get details() {
    const defaultString = this.default_payment_method ? ' (Default)' : '';
    if (this.stripe_bank_account) {
      return `${this.stripe_bank_account.bank_name} ****${this.stripe_bank_account.last4}${defaultString}`;
    }

    if (this.stripe_credit_card) {
      const card = this.stripe_credit_card;
      const expiredString = this.isExpired ? ' EXPIRED' : '';
      return `****${card.last4} ${card.brand.toUpperCase()} ${card.exp_month}/${card.exp_year}${defaultString}${expiredString}`;
    }

    return BaseModel.defaultDisplayValue;
  }
}
