import BaseModel from './BaseModel';
import FileModel from './FileModel';
import { PropertyLocationModel } from './LocationModels';
import TagModel from './TagModel';
import CopyableMixin from './CopyableMixin';

export default class PartContainerModel extends CopyableMixin(BaseModel) {
  init(data) {
    this.id = data.id || 0;
    this.name = data.name || '';
    this.description = data.description || '';
    this.location = data.location ? PropertyLocationModel.factory(data.location) : null;
    this.image = data.image ? new FileModel(data.image) : null;
    this.images = (data.images || []).map(f => new FileModel(f));
    this.qr_code = data.qr_code || '';
    this.tags = (data.tags || []).map(t => new TagModel(t)).filter(t => t.active);
    this.archived = 'archived' in data ? data.archived : false;
    this.total_quantity_on_hand = data.total_quantity_on_hand || 0;
  }

  get display_label() {
    return this.name || '';
  }

  get display_label_with_location() {
    return this.display_label + (this.location ? ` (${this.location.name})` : '');
  }

  get descriptionHtml() {
    return this.getHtmlDisplayValue(this.description);
  }

  get location_name() {
    return (this.location && this.location.fullAncestryPath) || '';
  }

  get thumbnail_url() {
    return (this.image && this.image.thumbnail_url) || (this.image && this.image.url) || '';
  }
}
