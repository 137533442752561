import BaseModel from './BaseModel';

export default class TerritoryModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.name = data.name || '';
    this.state_code = data.state_code || '';
    this.state_name = data.state_name || '';
  }

  get displayName() {
    return `${this.name}, ${this.state_code}`;
  }

  get sortValue() {
    return `${this.state_code} ${this.name}`;
  }
}
