import BaseModel from './BaseModel';

export default class TaskStatusModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.name = data.name || '';
    this.color = data.color || ''; // TODO default color?
    this.sort_order = data.sort_order || 0;
    this.archived = data.archived || false;
    this.is_initial_state = data.is_initial_state || false;
    this.is_complete_state = data.is_complete_state || false;
    this.migrated_to_id = 0;
    this.locked = false;
  }

  get nameSlug() {
    return this.name.replaceAll(' ', '_').trim();
  }

  get display_label() {
    return this.name;
  }
}
