const CopyableMixin = SuperClass => class extends SuperClass {
  copy(model) {
    const clone = { ...model };
    Object.keys(clone).forEach((key) => {
      // also clone sub objects to avoid ref
      if (clone[key] && typeof clone[key] === 'object') {
        // check for custom class
        if (clone[key].constructor && clone[key].constructor.name && clone[key].constructor.name !== 'Object') {
          this[key] = clone[key]; // keep the class
        } else if (typeof clone[key].length === 'number') {
          this[key] = clone[key].map(x => x);
        } else if (!Object.keys(clone[key]).length) {
          // empty obj
          this[key] = clone[key];
        } else {
          this[key] = { ...clone[key] };
        }
      } else {
        this[key] = clone[key];
      }
    });
  }
};

export default CopyableMixin;
