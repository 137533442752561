import _ from 'lodash';

import BaseModel from './BaseModel';
import ProjectModel from './ProjectModel.js';
import { PropertyLocationModel } from './LocationModels';
import CompanyEquipmentModel from './CompanyEquipmentModel';
import PartModel from './PartModel';
import SensorDeviceModel from './SensorDeviceModel';
import TaskTemplateModel from './TaskTemplateModel';
import UserModel from './UserModel';
import { titleCase, scheduleIntervalToDescription, scheduleWeekdayToDescription, nth, scheduleMonthToDescription } from '../util';
import { getTaskPriorityLabel } from '../constants/options.js';

export default class PMScheduleModel extends BaseModel {
  static ScheduleTypeBase = {
    Daily: 'daily',
    Weekly: 'weekly',
    Monthly: 'monthly',
    Yearly: 'yearly',
  };

  static ScheduleType = {
    Daily: 'daily',
    Weekly: 'weekly',
    MonthlyOnDates: 'monthly_on_dates',
    MonthlyDayOfWeek: 'monthly_day_of_week_occurrences',
    YearlyOnDates: 'yearly_on_dates',
    YearlyDayOfWeek: 'yearly_day_of_week_occurrences',
  };

  static monthlyOnDateScheduleDefault = { day: 1 };

  static monthlyDayOfWeekScheduleDefault = { occurrence: 1, weekday: 0 };

  static yearlyOnDateScheduleDefault = { month: 1, day: 1 };

  static yearlyOnDayOfWeekScheduleDefault = { month: 1, weekday: 0, occurrence: 1 };

  init(data) {
    this.id = data.id || 0;
    this.task_template = data.task_template ? new TaskTemplateModel(data.task_template) : null;
    this.name = data.name || '';
    this.task_name_override = data.task_name_override || '';
    this.active = 'active' in data ? data.active : false;
    this.priority = data.priority || 0;
    this.project = data.project ? new ProjectModel(data.project) : null;
    this.location = (data.location || data.location_id) ? PropertyLocationModel.factory(data.location || { id: data.location_id }) : null;
    this.equipment = (data.equipment || data.equipment_id) ? new CompanyEquipmentModel(data.equipment || { id: data.equipment_id }) : null;
    this.part = (data.part || data.part_id) ? new PartModel(data.part || { id: data.part_id }) : null;
    this.sensor = (data.sensor || data.sensor_id) ? new SensorDeviceModel(data.sensor || { id: data.sensor_id }) : null;
    this.assignees = (data.assignees || []).map(a => new UserModel(a));

    this.schedule_type = this.parseScheduleType(data.schedule_type);
    this.schedule_type_base = (this.schedule_type || '').split('_')?.[0] || '';
    this.schedule_repeat_type = (data.schedule_type || '').includes('dates') ? 'numeric' : 'weekday';
    this.schedule_interval = data.schedule_interval || 0;
    this.schedule_lead_time = data.schedule_lead_time || 0;
    this.schedule_start_date = data.schedule_start_date || '';
    this.schedule_end_date = data.schedule_end_date || '';
    this.schedule_data = data.schedule_data || [];

    this.created = data.created ? new Date(data.created) : null;
    this.updated = data.user_updated || data.updated ? new Date(data.user_updated || data.updated) : null;

    this.active_task_count = data.active_task_count || 0;
    this.occurrences = [...this.schedule_data];

    this.cachedFields = {
      assignees: this.assignees.map(a => a.id),
      priority: this.priority,
      project: this.project?.id || null,
      location: this.location?.id || null,
      equipment: this.equipment?.id || null,
    };

    this.cachedScheduleFields = {
      schedule_type: this.schedule_type,
      schedule_interval: this.schedule_interval,
      schedule_start_date: this.schedule_start_date,
      schedule_end_date: this.schedule_end_date,
    };
  }

  _detectChanges(cacheObj, currentValues) {
    const fieldNames = Object.keys(cacheObj);
    let updatedFields = fieldNames.filter(field => (cacheObj[field] !== currentValues[field]));

    // special case for array comparison (this if will need updating if we ever track aother array)
    if (updatedFields.includes('assignees')) {
      if (cacheObj.assignees.length !== currentValues.assignees.length) return true;
      if (cacheObj.assignees.every((val, idx) => val !== currentValues.assignees[idx])) return true;

      updatedFields = updatedFields.filter(z => z !== 'assignees'); // assignees all good, remove
    }

    return !!updatedFields.length;
  }

  parseScheduleType(type) {
    return Object.values(PMScheduleModel.ScheduleType).includes(type) ? type : null;
  }

  validateScheduleOccurrences() {
    if (!this.schedule_type || !this.occurrences.length) {
      return true;
    }

    let grouped = {};
    if (this.schedule_type === PMScheduleModel.ScheduleType.MonthlyOnDates) {
      grouped = _.groupBy(this.occurrences, obj => `${obj.day}`);
    } else if (this.schedule_type === PMScheduleModel.ScheduleType.MonthlyDayOfWeek) {
      grouped = _.groupBy(this.occurrences, obj => `${obj.occurrence}-${obj.weekday}`);
    } else if (this.schedule_type === PMScheduleModel.ScheduleType.YearlyOnDates) {
      grouped = _.groupBy(this.occurrences, obj => `${obj.month}-${obj.day}`);
    } else if (this.schedule_type === PMScheduleModel.ScheduleType.YearlyDayOfWeek) {
      grouped = _.groupBy(this.occurrences, obj => `${obj.month}-${obj.weekday}-${obj.occurrence}`);
    }

    return !_.some(grouped, group => group.length > 1);
  }

  hasNonScheduleChanges(currentFields) {
    return this._detectChanges(this.cachedFields, currentFields);
  }

  hasScheduleChanges(currentFields) {
    // test if any cached schedule field has changed
    if (this._detectChanges(this.cachedScheduleFields, currentFields)) return true;

    // also look in the guts of the "Repeat" data
    const occurrencesToTest = JSON.stringify(this.occurrences);
    const original = JSON.stringify(this.schedule_data || []);

    return occurrencesToTest !== original;
  }

  get scheduleBaseTypes() {
    return Object.keys(PMScheduleModel.ScheduleTypeBase).map(type => ({
      text: type,
      value: PMScheduleModel.ScheduleTypeBase[type],
    }));
  }

  get scheduleDescription() {
    const intervalDescription = scheduleIntervalToDescription(this.schedule_interval);
    let schedule = `Repeat ${intervalDescription}`;
    switch (this.schedule_type) {
      case PMScheduleModel.ScheduleType.Daily: {
        schedule += ' day';
        break;
      }
      case PMScheduleModel.ScheduleType.Weekly: {
        schedule += ' week';
        if (this.schedule_data.length > 0) {
          schedule += ' on ';
        }
        schedule += this.schedule_data
          .filter((val => 'weekday' in val))
          .map(val => scheduleWeekdayToDescription(val.weekday))
          .join(', ');
        break;
      }
      case PMScheduleModel.ScheduleType.MonthlyOnDates: {
        schedule += ' month';
        if (this.schedule_data.length > 0) {
          schedule += ' on the ';
        }
        schedule += this.schedule_data
          .filter((val => 'day' in val))
          .map(val => nth(0, val.day))
          .join(', ');
        break;
      }
      case PMScheduleModel.ScheduleType.MonthlyDayOfWeek: {
        schedule += ' month';
        if (this.schedule_data.length > 0) {
          schedule += ' on the ';
        }
        schedule += this.schedule_data
          .filter((val => 'weekday' in val && 'occurrence' in val))
          .map(val => `${nth(0, val.occurrence)} ${scheduleWeekdayToDescription(val.weekday)}`)
          .join(', ');
        break;
      }
      case PMScheduleModel.ScheduleType.YearlyOnDates: {
        schedule += ' year';
        if (this.schedule_data.length > 0) {
          schedule += ' in ';
        }
        schedule += this.schedule_data
          .filter((val => 'month' in val && 'day' in val))
          .map(val => `${scheduleMonthToDescription(val.month)} on the ${nth(0, val.day)}`)
          .join(', ');
        break;
      }
      case PMScheduleModel.ScheduleType.YearlyDayOfWeek: {
        schedule += ' year';
        if (this.schedule_data.length > 0) {
          schedule += ' on the ';
        }
        schedule += this.schedule_data
          .filter((val => 'month' in val && 'weekday' in val && 'occurrence' in val))
          .map(val => `${nth(0, val.occurrence)} ${scheduleWeekdayToDescription(val.weekday)} of ${scheduleMonthToDescription(val.month)}`)
          .join(', ');
        break;
      }
      default:
        break;
    }
    return schedule;
  }

  get scheduleTypeBaseStr() {
    return titleCase(this.schedule_type).split(' ')?.[0] || '';
  }

  get category() {
    return this.task_template?.category?.name || '';
  }

  get priorityLabel() {
    return getTaskPriorityLabel(this.priority);
  }

  get statusLabel() {
    return this.active ? 'Active' : 'Inactive';
  }

  get hasInactiveTemplate() {
    return (this.task_template?.archived ?? false) || (this.task_template?.deleted ?? false);
  }

  get startDateObj() {
    return this.schedule_start_date ? new Date(this.schedule_start_date) : null;
  }

  get endDateObj() {
    return this.schedule_end_date ? new Date(this.schedule_end_date) : null;
  }
}
