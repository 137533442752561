import BaseAPIClient from './base/BaseAPIClient';
import ListResponseModel from '../models/ListResponseModel';

import UserModel from '../models/UserModel';
import CompanyModel from '../models/CompanyModel';
import QuoteModel from '../models/QuoteModel';
import InvoiceModel from '../models/InvoiceModel';
import RFPModel from '../models/RFPModel';
import SettingsModel from '../models/SettingsModel';
import CsvImportModel from '../models/CsvImportModel';
import OwnerDashboardSuggestionModel from '../models/OwnerDashboardSuggestionModel';
import OwnerSuggestionResponseModel from '../models/OwnerSuggestionResponseModel';
import IncentiveReportModel from '../models/IncentiveReportModel';

export default class AdminClient extends BaseAPIClient {
  getStats() {
    return this._get('helix_admin/stats/');
  }

  deletePendingInvite(id) {
    return this._delete(`helix_admin/company/invite/${id}/`);
  }

  getMetricsNewUsers() {
    return this._get('metrics/new_users/histogram/');
  }

  getMetricsUsers() {
    return this._get('metrics/users/');
  }

  getMetricsCompanies() {
    return this._get('metrics/companies/');
  }

  generateQRCodes(quantity, type) {
    return this._post('helix_admin/generate_qr_codes/', {
      quantity,
      type,
    });
  }

  getUser(email) {
    return this._get('helix_admin/users/get_by_email/', { email }).then(user => new UserModel(user));
  }

  getPendingCompanyUsers(search = '', ordering = '-id', format = '', limit = 50, offset = 0) {
    const params = {
      search,
      ordering,
      limit,
      offset,
    };

    if (format) {
      params.format = format;
    }

    const promise = this._get('helix_admin/pending_users_invites/', params);

    if (params.format) {
      return promise; // csv
    }

    return promise.then(m => new ListResponseModel(m, UserModel));
  }

  resendCompanyInvite(id) {
    return this._post(`helix_admin/company/invite/${id}/resend/`);
  }

  getSPCompanies(search = '', limit = 50, offset = 0) {
    const params = {
      search,
      ordering: 'name',
      is_service_provider: true,
      limit,
      offset,
    };

    return this._get('helix_admin/companies/', params).then(m => new ListResponseModel(m, CompanyModel));
  }

  getCompany(id) {
    return this._get(`helix_admin/companies/${id}/`).then(e => new CompanyModel(e));
  }

  getCompanies(search = '', ordering = 'created', format = '', limit = 50, offset = 0, filters = {}) {
    const params = {
      search,
      ordering,
      limit,
      offset,
      ...filters,
    };

    let url = 'helix_admin/companies/';

    if (format) {
      params.format = format;
      if (format == 'csv') {
        url = 'helix_admin/companies_csv/';
      }
    }

    const promise = this._get(url, params);

    if (params.format) {
      return promise; // csv
    }

    return promise.then(m => new ListResponseModel(m, CompanyModel));
  }

  getProposals({
    search = '',
    ordering = 'created',
    format = '',
    limit = 50,
    offset = 0,
    equipment = 0,
    part = 0,
    status = '',
    created_before = '',
    created_after = '',
    matched_user = '',
  }) {
    const params = {
      search,
      ordering,
      limit,
      offset,
    };

    if (equipment) {
      params.equipment = equipment;
    }

    if (part) {
      params.part = part;
    }

    if (status) {
      params.status = status;
    }

    if (format) {
      params.format = format;
    }

    if (created_before) {
      params.created_before = created_before;
    }
    if (created_after) {
      params.created_after = created_after;
    }
    if (matched_user) {
      params.matched_user = matched_user;
    }

    let url = 'helix_admin/quotes/';

    if (format) {
      params.format = format;
      if (format == 'csv') {
        url = 'helix_admin/quotes_csv/';
      }
    }

    const promise = this._get(url, params);

    if (params.format) {
      return promise; // csv
    }

    return promise.then(m => new ListResponseModel(m, QuoteModel));
  }

  getInvoices({
    search = '',
    ordering = 'created',
    format = '',
    limit = 50,
    offset = 0,
    equipment = 0,
    part = 0,
    status = '',
    created_before = '',
    created_after = '',
    matched_user = '',
  }) {
    const params = {
      search,
      ordering,
      limit,
      offset,
    };

    if (equipment) {
      params.equipment = equipment;
    }

    if (part) {
      params.part = part;
    }

    if (status) {
      params.charge__status = status;
    }

    if (format) {
      params.format = format;
    }

    if (created_before) {
      params.created_before = created_before;
    }
    if (created_after) {
      params.created_after = created_after;
    }
    if (matched_user) {
      params.matched_user = matched_user;
    }

    let url = 'helix_admin/invoices/';

    if (format) {
      params.format = format;
      if (format == 'csv') {
        url = 'helix_admin/invoices_csv/';
      }
    }

    const promise = this._get(url, params);

    if (params.format) {
      return promise; // csv
    }

    return promise.then(m => new ListResponseModel(m, InvoiceModel));
  }

  getIncentiveReport(id) {
    return this._get(`helix_admin/incentives/reports/${id}/`).then(r => new IncentiveReportModel(r));
  }

  getIncentives({
    search = '',
    ordering = 'created',
    limit = 50,
    offset = 0,
    status = '',
    payment_status = '',
  }) {
    const params = {
      search,
      ordering,
      limit,
      offset,
    };

    if (status) {
      params.status = status;
    }

    if (payment_status) {
      params.payment_status = payment_status;
    }

    return this._get('helix_admin/incentives/reports/', params).then(m => new ListResponseModel(m, IncentiveReportModel));
  }

  updateIncentiveReport(id, status) {
    if (status === 'invoice_sent') {
      return this._post(`helix_admin/incentives/reports/${id}/invoice/sent/`);
    }

    if (status === 'invoice_paid') {
      return this._post(`helix_admin/incentives/reports/${id}/invoice/paid/`);
    }

    if (status === 'processing') {
      return this._post(`helix_admin/incentives/reports/${id}/processing/`);
    }

    return Promise.resolve({});
  }

  completeIncentiveReport(id, file, link, potentialSavings) {
    const data = {};
    if (file) {
      data.report_file = file;
    } else {
      data.external_report_url = link;
    }

    if (potentialSavings) {
      data.potential_savings = potentialSavings;
    }

    return this._post(`helix_admin/incentives/reports/${id}/complete/`, data);
  }

  setCompanyFee(id, {
    helix_fee_percentage_one_time_service,
    helix_fee_percentage_service_agreement,
    helix_fee_percentage_parts_order,
    helix_fee_percentage_new_equipment,
    helix_fee_percentage_rental_equipment,
    helix_fee_percentage_energy_assessment,
  }) {
    return this._patch(`helix_admin/companies/${id}/`, {
      helix_fee_percentage_one_time_service,
      helix_fee_percentage_service_agreement,
      helix_fee_percentage_parts_order,
      helix_fee_percentage_new_equipment,
      helix_fee_percentage_rental_equipment,
      helix_fee_percentage_energy_assessment,
    }).then(e => new CompanyModel(e));
  }

  approveSP(id) {
    return this._patch(`helix_admin/companies/${id}/`, {
      is_service_provider: true,
    }).then(e => new CompanyModel(e));
  }

  editCompany(id, {
    name,
    email,
    street_address,
    street_address_line_2,
    latitude,
    longitude,
    city,
    state,
    zip,
    phone,
    phone_ext,

    is_equipment_operator,
    is_service_provider,
    service_provider_type,
    equipment_operator_type,

    helix_fee_percentage_one_time_service,
    helix_fee_percentage_service_agreement,
    helix_fee_percentage_parts_order,
    helix_fee_percentage_new_equipment,
    helix_fee_percentage_rental_equipment,
    helix_fee_percentage_energy_assessment,

    demo_seed_company,

    tax_exempt,
    tax_exempt_document,

    industry,
    industry_other,
    naic_code,
    how_did_you_hear_about_us,
    how_did_you_hear_about_us_explanation,
    affiliate,
    hours_of_operation,
    client_company_number,
    client_policy_number,
    affiliate_policy_number,
    description,
    salesforce_id,
    is_helix_test_company,
  }) {
    const data = {
      name,
      email,
      street_address,
      street_address_line_2,
      location: latitude || longitude ? {
        latitude,
        longitude,
      } : null,
      city,
      state,
      zip,
      phone: phone.replace(/[^0-9+]/g, ''),
      is_equipment_operator,
      is_service_provider,
      service_provider_type,
      equipment_operator_type,

      helix_fee_percentage_one_time_service,
      helix_fee_percentage_service_agreement,
      helix_fee_percentage_parts_order,
      helix_fee_percentage_new_equipment,
      helix_fee_percentage_rental_equipment,
      helix_fee_percentage_energy_assessment,

      demo_seed_company,

      industry,
      industry_other,
      naic_code: naic_code || null,
      how_did_you_hear_about_us,
      how_did_you_hear_about_us_explanation,

      tax_exempt,
      affiliate,
      client_company_number,
      client_policy_number,
      affiliate_policy_number,
      description,
      salesforce_id,
      is_helix_test_company,
    };

    if (phone_ext) {
      data.phone_ext = phone_ext;
    }

    if (hours_of_operation) {
      data.hours_of_operation = hours_of_operation;
    }

    if (tax_exempt_document) {
      data.tax_exempt_document = tax_exempt_document;
    }

    return this._patch(`helix_admin/companies/${id}/`, data).then(e => new CompanyModel(e));
  }

  getAppUsers(search = '', ordering = 'last_login', format = '', limit = 50, offset = 0, filters = {}) {
    const params = {
      search,
      ordering,
      limit,
      offset,
      ...filters,
    };

    let url = 'helix_admin/users/';

    if (format) {
      params.format = format;
      if (format == 'csv') {
        url = 'helix_admin/users_csv/';
      }
    }

    const promise = this._get(url, params);

    if (params.format) {
      return promise; // csv
    }

    return promise.then(m => new ListResponseModel(m, UserModel));
  }

  getRFPs(search = '', ordering = 'name', format = '', limit = 50, offset = 0, equipment = 0, part = 0, status = '', date_submitted_before = null, date_submitted_after = null, type = null, archived = false) {
    const params = {
      search,
      ordering,
      limit,
      offset,
      format,
    };

    if (archived) {
      params.any_archived = true;
    } else {
      params.any_active = true;
    }

    if (equipment) {
      params.equipment_ids = JSON.stringify([equipment]);
    }

    if (part) {
      params.part_ids = JSON.stringify([part]);
    }

    if (status && status === 'quoted') {
      params.status = 'matched';
      params.quote_received_company_count__gt = 0;
    } else if (status && status === 'matched') {
      params.status = 'matched';
      params.quote_received_company_count__lt = 1;
    } else if (status) {
      params.status = status;
    }

    if (date_submitted_before) {
      params.date_submitted_before = date_submitted_before;
    }
    if (date_submitted_after) {
      params.date_submitted_after = date_submitted_after;
    }

    // if (matched_user) {
    //   if (Vue.prototype.$globals.selectedDashboard === 'eo') {
    //     params.matched_service_provider = matched_user;
    //   } else {
    //     params.company = matched_user;
    //   }
    // }

    if (type) {
      params.type = type;
    }

    const url = 'helix_admin/rfps/';

    const promise = this._get(url, params);

    if (params.format) {
      return promise; // csv
    }

    return promise.then(m => new ListResponseModel(m, RFPModel));
  }

  inviteAdminUser(email, role, first_name, last_name) {
    return this._post('helix_admin/users/', {
      email,
      role,
      first_name,
      last_name,
    });
  }

  inviteCompanyUser(company_id, role, email, first_name, last_name) {
    return this._post(`company/${company_id}/invites/`, {
      email,
      role,
      first_name,
      last_name,
    });
  }

  getAdminUserRoles() {
    return this._get('helix_admin/user_roles/');
  }

  setUserRoles(id, roles) {
    return this._put(`helix_admin/users/${id}/roles/`, {
      roles, // ['',...]
    });
  }

  editUser(id, first_name, last_name) {
    return this._post(`helix_admin/users/${id}/`, {
      first_name,
      last_name,
    });
  }

  updateUser(id, updates) {
    return this._patch(`helix_admin/users/${id}/`, updates);
  }

  rfpMatch(id, providers) {
    return this._post(`helix_admin/rfps/${id}/match/`, {
      provider_ids: providers.map(provider => provider.id),
    });
  }

  rfpUnMatch(id, provider_ids) {
    return this._post(`helix_admin/rfps/${id}/unmatch/`, {
      provider_ids,
    });
  }

  rfpReject(id, reason) {
    return this._post(`helix_admin/rfps/${id}/reject/`, {
      reason,
    });
  }

  getGlobalSettings() {
    return this._get('dynamic_config_variables/').then(m => new ListResponseModel(m, SettingsModel));
  }

  setGlobalSetting(id, boolean_value) {
    return this._patch(`dynamic_config_variables/${id}/`, {
      boolean_value,
    });
  }

  getCsvImport(id) {
    return this._get(`helix_admin/csv_import/${id}/`).then(m => new CsvImportModel(m));
  }

  uploadCompanyCSV(file) {
    return this._post('helix_admin/companies_csv_import/', {
      file,
    }).then(m => new CsvImportModel(m));
  }

  uploadCompanyMembersCSV(companyId, file) {
    const url = companyId ? `helix_admin/companies/${companyId}/members_csv_import/` : 'helix_admin/companies/members_csv_import/';

    return this._post(url, {
      file,
    }).then(m => new CsvImportModel(m));
  }

  uploadEquipmentsCSV(companyId, file) {
    const url = companyId ? `helix_admin/companies/${companyId}/equipments_csv_import/` : 'helix_admin/companies/equipments_csv_import/';

    return this._post(url, {
      file,
    }).then(m => new CsvImportModel(m));
  }

  uploadPartsCSV(companyId, file) {
    return this._post(`helix_admin/companies/${companyId}/parts_csv_import/`, {
      file,
    }).then(m => new CsvImportModel(m));
  }

  uploadTasksCSV(companyId, file) {
    return this._post(`helix_admin/companies/${companyId}/tasks_csv_import/`, {
      file,
    }).then(m => new CsvImportModel(m));
  }

  uploadLocationsCSV(companyId, file) {
    const url = companyId ? `helix_admin/companies/${companyId}/location_csv_import/` : 'helix_admin/companies/location_csv_import/';

    return this._post(url, {
      file,
    }).then(m => new CsvImportModel(m));
  }

  createCompany(data) {
    return this._post('helix_admin/companies/', data).then(d => new CompanyModel(d));
  }

  inviteNewCompanyAdmin({
    name,
    email,
    street_address,
    latitude,
    longitude,
    city,
    state,
    zip,
    phone,
    phone_ext,
    is_equipment_operator,
    is_service_provider,
    service_provider_type,
    equipment_operator_type,

    helix_fee_percentage_one_time_service,
    helix_fee_percentage_service_agreement,
    helix_fee_percentage_parts_order,
    helix_fee_percentage_new_equipment,
    helix_fee_percentage_rental_equipment,
    helix_fee_percentage_energy_assessment,

    industry,
    industry_other,
    naic_code,
    how_did_you_hear_about_us,
    how_did_you_hear_about_us_explanation,

    admin_email,
    admin_first_name,
    admin_last_name,
    admin_phone,
    admin_phone_ext,
    demo_seed_company_id,
    parent,

    affiliate,
    related_company_id,
  }) {
    const data = {
      name,
      email,
      street_address,
      location: latitude || longitude ? {
        latitude,
        longitude,
      } : null,
      city,
      state,
      zip,
      phone: phone.replace(/[^0-9+]/g, ''),
      is_equipment_operator,
      is_service_provider,
      service_provider_type,
      equipment_operator_type,
      helix_fee_percentage_one_time_service,
      helix_fee_percentage_service_agreement,
      helix_fee_percentage_parts_order,
      helix_fee_percentage_new_equipment,
      helix_fee_percentage_rental_equipment,
      helix_fee_percentage_energy_assessment,

      industry,
      industry_other,
      naic_code: naic_code || null,
      how_did_you_hear_about_us,
      how_did_you_hear_about_us_explanation,

      admin_user_invitation: {
        email: admin_email,
        first_name: admin_first_name,
        last_name: admin_last_name,
      },
    };

    if (demo_seed_company_id) {
      data.demo_seed_company_id = demo_seed_company_id;
    }

    if (parent) {
      data.parent = parent;
    }

    if (phone_ext) {
      data.phone_ext = phone_ext;
    }

    if (affiliate) {
      data.affiliate = affiliate;
    }

    if (related_company_id) {
      data.related_company_id = related_company_id;
    }

    if (admin_phone) {
      data.admin_user_invitation.phone = admin_phone.replace(/[^0-9+]/g, '');
    }

    if (admin_phone_ext) {
      data.admin_user_invitation.phone_ext = admin_phone_ext;
    }

    return this._post('helix_admin/companies/admin_invitations/', data).then(d => new CompanyModel(d));
  }

  completeInvoice(id) {
    return this._post(`helix_admin/rfps/${id}/complete/`);
  }

  editThirdPartyPaymentInfo(id, payment_link, image) {
    const data = {
      payment_link,
      active: true,
      image: (image && image.id) || null,
    };

    return this._patch(`helix_admin/third_party_payment_info/${id}/`, data);
  }

  deleteThirdPartyPaymentInfo(id) {
    const data = {
      active: false,
    };

    return this._patch(`helix_admin/third_party_payment_info/${id}/`, data);
  }

  setThirdPartyPaymentInfo(company_id, payment_link, image) {
    const data = {
      company_id,
      payment_link,
    };

    if (image) {
      data.image = image.id;
    }

    return this._post('helix_admin/third_party_payment_info/', data);
  }

  getThirdPartyPaymentInfo(id) {
    return this._get(`helix_admin/third_party_payment_info/${id}/`);
  }

  getSuggestionStats(active, limit = 1, offset = 0, ordering = '-id') {
    return this._get('helix_admin/suggestions/dashboard/stats/', {
      offset,
      limit,
      active,
      ordering,
    }).then(d => new ListResponseModel(d, OwnerDashboardSuggestionModel));
  }

  getSuggestionResponses(id, limit = 1, offset = 0) {
    return this._get(`helix_admin/suggestions/dashboard/${id}/responses/`, {
      offset,
      limit,
    }).then(d => new ListResponseModel(d, OwnerSuggestionResponseModel));
  }
}
