import { cleanHtmlTextForDisplay } from '@/util';
import globals from '../global.js';

export default class BaseModel {
  static defaultDisplayValue = globals?.data.defaultDisplayValue ?? '—';

  constructor(data) {
    this.init(data || {});
  }

  init() {
    // set props in super
  }

  stringToDate(input) {
    if (!input) {
      return null;
    }

    const dateParts = input.split('T')[0].split('-');
    if (dateParts.length === 3) {
      return new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
    }
    return null;
  }

  getHtmlDisplayValue(htmlValue) {
    return cleanHtmlTextForDisplay(htmlValue);
  }
}
