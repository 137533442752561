import { getRFPTypeLabel } from '@/constants/options.js';
import BaseModel from './BaseModel';

class SPDashboardCountsModel extends BaseModel {
  init(data) {
    this.current_matched_rfp_count = data.current_matched_rfp_count || 0;
    this.current_quoted_rfp_count = data.current_quoted_rfp_count || 0;
    this.total_accepted_rfp_count = data.total_accepted_rfp_count || 0;
    this.total_accepted_rfp_quote_prices = data.total_accepted_rfp_quote_prices || 0;
  }
}

class SPDasboardPerformanceMetricsModel extends BaseModel {
  init(data) {
    this.total_quoted_value = data.total_quoted_value || 0;
    this.matched_rfp_count = data.matched_rfp_count || 0;
    this.win_rate = data.win_rate;
    this.average_quote_value = data.average_quote_value || 0;
    this.average_accepted_quote_value = data.average_accepted_quote_value || 0;
    this.new_customer_count = data.new_customer_count || 0;
    this.average_time_to_close = data.average_time_to_close || 0;
  }

  get winRateDisplay() {
    if (this.win_rate === null) return BaseModel.defaultDisplayValue;
    return `${+(parseFloat(this.win_rate) * 100).toFixed(2)}%`;
  }
}

class RFPTypeCountModel extends BaseModel {
  init(data) {
    this.type = data.type || '';
    this.count = data.count || 0;
    this.type_display = getRFPTypeLabel(this.type);
  }

  get chartData() {
    return [this.type_display, this.count];
  }
}

export { SPDashboardCountsModel, SPDasboardPerformanceMetricsModel, RFPTypeCountModel };
