import filters from '@/filters';
import PartContainerModel from './PartContainerModel';
import ObjectLogModel from './ObjectLogModel';

export default class PartContainerInventoryLogModel extends ObjectLogModel {
  init(data) {
    super.init(data);
    this.action = data.action || '';

    this.related_container = data.related_container ? new PartContainerModel(data.related_container) : null;
  }

  get log_display() {
    if (!this.field) return this.log_message;

    switch (this.field) {
      case 'quantity_on_hand':
      case 'target_quantity':
        if (this.field == 'quantity_on_hand' && this.action.startsWith('transfer') && this.related_container) {
          const transferQuantity = this.action === 'transfer_in' ? (this.new_value - this.old_value) : (this.old_value - this.new_value);
          const direction = this.action === 'transfer_in' ? 'from' : 'to';
          const units = Math.abs(transferQuantity) === 1 ? 'unit' : 'units';
          return `transferred <strong>${filters.number(transferQuantity)}</strong> ${units} ${direction} <strong>${this.related_container.name}</strong>.<br/><strong>Quantity on hand</strong> updated from <strong>${filters.number(this.old_value)}</strong> to <strong>${filters.number(this.new_value)}</strong>.`;
        }
        if (this.old_value === null) {
          return `set the <strong>${this.field.replaceAll('_', ' ')}</strong> to <strong>${filters.number(this.new_value)}</strong>.`;
        }
        return `updated the <strong>${this.field.replaceAll('_', ' ')}</strong> from <strong>${filters.number(this.old_value)}</strong> to <strong>${filters.number(this.new_value)}</strong>.`;
      case 'archived':
        if (this.new_value.toLowerCase() === 'true') {
          return 'archived this inventory.';
        }
        return 'restored this inventory.';
      default:
        return this.log_message;
    }
  }
}
