import Vue from 'vue';
import BaseModel from './BaseModel';
import { PropertyLocationModel } from './LocationModels';
import CompanyEquipmentModel from './CompanyEquipmentModel';
import PartModel from './PartModel';
import UserModel from './UserModel';
import { titleCase } from '../util';
import FileModel from './FileModel';
import TaskModel from './TaskModel';
import ProjectModel from './ProjectModel.js';

export default class WorkOrderRequestModel extends BaseModel {
  init(data) {
    this.external_id = data.external_id || '';
    this.task_id = data.task_id || 0;
    this.name = data.name || '';
    this.description = data.description || '';
    this.status = data.status || '';
    this.priority = data.priority || 0;
    this.category = data.category || 0;
    this.work_type = data.work_type || null;
    this.created = new Date(data.created);
    this.requested_completion_date = data.requested_completion_date || null;
    this.user = data.user ? new UserModel(data.user) : null;
    this.location = data.location ? PropertyLocationModel.factory(data.location) : null;
    this.equipment = data.equipment ? new CompanyEquipmentModel(data.equipment) : null;
    this.part = data.part ? new PartModel(data.part) : null;
    this.commentCount = data.comment_count || 0;
    this.unreadCommentCount = data.unread_comment_count || 0;
    this.project = data.project ? new ProjectModel(data.project) : null;

    this.reviewers = (data.reviewers || []).map(a => new UserModel(a));
    this.reviewed_by = data.reviewed_by ? new UserModel(data.reviewed_by) : null;

    this.attachments = (data.attachments || []).map(x => new FileModel(x));
    this.featuredImage = data.featured_image ? new FileModel(data.featured_image) : null;
    this.attachmentIcon = data.attachment_icon || 'camera';

    this.archived_by_requester = data.archived_by_requester || false;
    this.selected = false;

    // keep track of the things that can be changed during approval
    this.cachedTaskChanges = {
      project: data.project?.id || undefined,
      name: undefined,
      description: undefined,
      assignees: undefined,
      reviewers: undefined,
      priority: undefined,
      category: undefined,
      work_type: undefined,

      checklists: undefined,
      attachments: undefined,
      location: undefined,
      equipment: data.equipment?.id || undefined,
      part: data.part?.id || undefined,
      sensor: data.sensor?.id || undefined,
      rfp: undefined,

      due_date: undefined,
      repeat_type: undefined,
      recurring_end_date: undefined,
      recurring_every_x_days: undefined,
      recurring_every_x_weeks: undefined,
      recurring_days_of_week: undefined,
      recurring_every_x_months: undefined,
      recurring_days_of_month: undefined,
    };
  }

  get id() {
    return this.external_id;
  }

  get statusDisplay() {
    return titleCase(this.status);
  }

  get requesterName() {
    return this.user?.fullName || '';
  }

  get reviewerName() {
    return this.reviewed_by?.fullName || '';
  }

  get hasUnreadComments() {
    return this.unreadCommentCount > 0;
  }

  get canArchive() {
    return this.status !== 'pending_review'
      && !this.archived_by_requester
      && Vue.prototype.$globals.user()?.canSubmitWorkOrders
      && !Vue.prototype.$globals.user()?.canManageWorkOrders;
  }

  get canRestore() {
    return this.archived_by_requester && Vue.prototype.$globals.user()?.canSubmitWorkOrders;
  }

  get canCancel() {
    return this.status === 'pending_review' && this.user?.id === Vue.prototype.$globals.user()?.id;
  }

  get canManage() {
    return this.status === 'pending_review' && Vue.prototype.$globals.user()?.canManageWorkOrders;
  }

  get isAbandoned() {
    return this.status === 'abandoned';
  }

  get displayWorkOrderRequestMeta() {
    // when we went to view the work order request instead of the task
    return this.status && this.status !== 'approved' && this.status !== 'work_complete';
  }

  get taskModel() {
    return TaskModel.fromWorkOrderRequest(this);
  }

  get due_date() {
    return this.requested_completion_date;
  }

  get requested_completion_date_obj() {
    return this.stringToDate(this.requested_completion_date);
  }

  get hasChanges() {
    let changes = false;
    Object.keys(this.cachedTaskChanges).forEach((key) => {
      const value = this.cachedTaskChanges[key];
      if (value !== undefined && !changes) {
        if (Array.isArray(value)) {
          changes = JSON.stringify(value) !== JSON.stringify(this[key] || []);
        } else if (this[key]?.id) {
          changes = this[key].id !== value;
        } else {
          changes = (value || '') !== (this[key] || '');
        }
      }
    });
    return changes;
  }
}
