import BaseModel from './BaseModel';
import { getEquipmentStatusLabel, getEquipmentStatusColor } from '../constants/options.js';

export default class EquipmentStatusModel extends BaseModel {
  init(data) {
    this.status = data.status || '';
    this.count = data.count || 0;
    this.status_display = getEquipmentStatusLabel(this.status);
  }

  get chartData() {
    return [this.status_display, this.count, getEquipmentStatusColor(this.status)];
  }
}
