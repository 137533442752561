import BaseModel from './BaseModel';

export default class OptionTypeModel extends BaseModel {
  init(data) {
    this.code_name = data.code_name || '';
    this.display_name = data.display_name || '';
    this.value = data.value || '';
  }

  get id() {
    return this.code_name;
  }

  get display_label() {
    return this.display_name;
  }
}
