import BaseAPIClient from './base/BaseAPIClient';
import WeatherAlertModel from '../models/WeatherAlertModel';
import WeatherForecastModel from '../models/WeatherForecastModel';

export default class WeatherClient extends BaseAPIClient {
  getWeatherAlert(id) {
    return this._get(`weather/alert/${id}/`).then(r => new WeatherAlertModel(r));
  }

  async getWeather(lng, lat) {
    const locationData = await this._getFullUrl(`https://api.weather.gov/points/${parseFloat(lng.toFixed(4))},${parseFloat(lat.toFixed(4))}`);

    if (locationData && locationData.properties && locationData.properties.forecast) {
      const weatherData = await this._getFullUrl(locationData.properties.forecast);

      if (weatherData && weatherData.properties && weatherData.properties.periods && weatherData.properties.periods.length) {
        const {
          temperature,
          temperatureUnit,
          detailedForecast,
        } = weatherData.properties.periods[0];

        let city = '';
        let state = '';

        if (locationData.properties.relativeLocation && locationData.properties.relativeLocation.properties) {
          city = locationData.properties.relativeLocation.properties.city;
          state = locationData.properties.relativeLocation.properties.state;
        }

        return new WeatherForecastModel({
          temperature,
          temperatureUnit,
          detailedForecast,
          city,
          state,
        });
      }
    }

    return null;
  }

  _getFullUrl(url) {
    const headers = {};

    return this.http.get(url, {
      headers,
    }).then(this._handleResponse);
  }
}
