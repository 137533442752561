import BaseModel from './BaseModel';

export default class FormFieldModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.field_name = data.field_name || '';
    this.sort_order = data.sort_order || 0;
    this.required = data.required || false;
    this.value = '';
  }

  get formId() {
    return `input_${this.id}`;
  }
}
