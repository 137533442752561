import BaseModel from './BaseModel';

export default class CsvImportModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.successes = data.successes || [];
    this.errors = data.errors || [];
    this.status = data.status || 'pending';
    this.total_record_count = data.total_record_count || 0;
    this.processed_record_count = data.processed_record_count || 0;
    this.validated_record_count = data.validated_record_count || 0;
  }
}
