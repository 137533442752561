import moment from 'moment';
import BaseAPIClient from './base/BaseAPIClient';
import ListResponseModel from '../models/ListResponseModel';
import PMScheduleModel from '../models/PMScheduleModel';
import { PMScheduleUiSettingsModel } from '../models/UiSettingsModels.js';

const simpleDateUTC = date_str => (moment.utc(date_str).format('YYYY-MM-DD'));

export default class PMScheduleClient extends BaseAPIClient {
  getPMSchedules({
    search = '',
    ordering = 'name',
    offset = 0,
    limit = 50,
    assignees = [],
    categories = null,
    priorities = null,
    projects = null,
    equipment = null,
    location = null,
    schedule = null,
    active = null,
    template = null,
  }) {
    const params = {
      search,
      ordering,
      offset,
      limit,
      assignees,
    };

    if (categories?.length) {
      params.task_template__category__in = categories;
    }

    if (template) {
      params.task_template_id = template;
    }

    if (priorities?.length) {
      params.priority__in = priorities.join(',');
    }

    if (projects?.length) {
      params.project_id = projects.map(p => (p.id || p)).join(',');
    }

    if (location?.length) {
      params.location_id = location.map(l => (l.id || l)).join(',');
    }

    if (equipment?.length) {
      params.equipment_id = equipment.map(e => (e.id || e)).join(',');
    }

    if (active) {
      params.active = active;
    }

    if (schedule) {
      params.schedule_type = schedule;
    }

    if (assignees?.length) {
      params.assignees = assignees.map(a => (a.id || a)).join(',');
    }

    return this._get('pm/schedules/', params).then(s => new ListResponseModel(s, PMScheduleModel));
  }

  getPMSchedule(id) {
    return this._get(`pm/schedules/${id}/`).then(pm => new PMScheduleModel(pm));
  }

  createPMSchedule({
    task_template,
    name,
    task_name_override,
    assignees,
    priority,
    project,
    location,
    equipment,
    part,
    sensor,
    schedule_type,
    schedule_data,
    schedule_interval,
    schedule_start_date,
    schedule_end_date,
    schedule_lead_time,
    active = true,
  }) {
    const data = {
      name,
      task_name_override,
      task_template,
      assignees,
      priority,
      project,
      location,
      equipment,
      schedule_type,
      schedule_data,
      schedule_interval,
      schedule_lead_time,
      active,
    };

    if (part) {
      data.part = part;
    }

    if (sensor) {
      data.sensor = sensor;
    }

    if (schedule_start_date) {
      data.schedule_start_date = simpleDateUTC(schedule_start_date);
    }
    if (schedule_end_date) {
      data.schedule_end_date = simpleDateUTC(schedule_end_date);
    }

    return this._post('pm/schedules/', data).then(s => new PMScheduleModel(s));
  }

  updatePMSchedule(id, {
    name = null,
    task_name_override = null,
    assignees,
    priority,
    project,
    location,
    equipment,
    schedule_type = null,
    schedule_data = null,
    schedule_interval = null,
    schedule_start_date = null,
    schedule_end_date = null,
    schedule_lead_time = null,
    active,
    sync_related_tasks = false,
  }) {
    const data = {
      name,
      task_name_override,
      priority,
      project,
      schedule_type,
      schedule_data,
      schedule_interval,
      schedule_lead_time,
      schedule_start_date,
      schedule_end_date,
      sync_related_tasks,
    };

    data.location = location ?? null;
    data.equipment = equipment ?? null;

    if (assignees?.length) {
      data.assignees = assignees.map(a => (a.id || a));
    }

    // these need to go local TZ -> UTC as API expects UTC
    if (schedule_start_date) {
      data.schedule_start_date = simpleDateUTC(schedule_start_date);
    } else {
      data.schedule_start_date = simpleDateUTC(moment().format('YYYY-MM-DD'));
    }

    if (schedule_end_date) {
      data.schedule_end_date = simpleDateUTC(schedule_end_date);
    } else {
      data.schedule_end_date = null;
    }

    if (active !== null) {
      data.active = active;
    }

    return this._patch(`pm/schedules/${id}/`, data).then(s => new PMScheduleModel(s));
  }

  updateRelatedItems(id, { location = null, equipment = null }) {
    return this._patch(`pm/schedules/${id}/`, { location, equipment }).then(s => new PMScheduleModel(s));
  }

  activatePMSchedule(id) {
    return this._patch(`pm/schedules/${id}/`, { active: true }).then(s => new PMScheduleModel(s));
  }

  async getPMScheduleWorkItemCounts(id, companyClient, nonCompleteOnly = false) {
    if (!id || !companyClient) return {};

    let completePMTasks = 0;

    const nonCompletePMTasks = await companyClient.getProjectCompanyTasks(null, { pm_schedule: id, completed: false, limit: 0 });

    if (!nonCompleteOnly) {
      const nonCompleteCounts = await companyClient.getCompanyTaskInstances({ pm_schedule: id, completed: true, limit: 0 });
      completePMTasks = nonCompleteCounts.count;
    }

    return {
      nonComplete: nonCompletePMTasks.count,
      complete: completePMTasks,
    };
  }

  deletePMSchedule(id, updateActiveTasks = false, updateCompleteTasks = false) {
    return this._post(
      `pm/schedules/${id}/delete/`,
      {
        archive_related_non_completed_tasks: updateActiveTasks,
        archive_related_completed_tasks: updateCompleteTasks,
      },
    );
  }

  deactivatePMSchedule(id, archiveRelatedNonCompletedTasks = false, archiveRelatedCompletedTasks = false) {
    return this._post(
      `pm/schedules/${id}/deactivate/`,
      {
        archive_related_non_completed_tasks: archiveRelatedNonCompletedTasks,
        archive_related_completed_tasks: archiveRelatedCompletedTasks,
      },
    );
  }

  getUiSettings() {
    return this._get('pm/ui_settings/').then(settings => new PMScheduleUiSettingsModel(settings));
  }

  updateUiSettings({
    search = null,
    sort = null,
    task_categories = null,
    priorities = null,
    projects = null,
    locations = null,
    equipment = null,
    schedule = null,
    active = null,
    assignees = null,
  }) {
    const data = {
      search,
      sort,
      priorities,
      projects,
      active,
      schedule,
    };

    if (!task_categories?.length) {
      data.task_categories = [];
    } else {
      data.task_categories = task_categories.map(c => c.id);
    }

    if (!locations) {
      data.locations = [];
    } else {
      data.locations = locations.map(u => u.id || u);
    }

    if (!equipment) {
      data.equipment = [];
    } else {
      data.equipment = equipment.map(u => u.id || u);
    }

    if (!assignees) {
      data.assignees = [];
    } else {
      data.assignees = assignees.map(u => u.id || u);
    }

    return this._patch('pm/ui_settings/', data).then(settings => new PMScheduleUiSettingsModel(settings));
  }
}
