import BaseModel from './BaseModel';
import FileModel from './FileModel';

export default class ServiceReportLogModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.service_date = data.service_date || '';
    this.service_completed_by = data.service_completed_by || '';
    this.service_description = data.service_description || '';
    this.service_report_files = (data.service_report_files || []).map(f => new FileModel(f));
    this.service_cost = data.service_cost ? parseFloat(data.service_cost) : null;
  }

  get serviceDateDate() {
    return this.stringToDate(this.service_date);
  }
}
