import BaseModel from './BaseModel';
import CopyableMixin from './CopyableMixin';
import UserModel from './UserModel';

export default class ProjectModel extends CopyableMixin(BaseModel) {
  static defaultColor = '335E8D';

  init(data) {
    this.id = data.id || 0;
    this.name = data.name || '';
    this.is_default = data.is_default || false;
    this.description = data.description || '';
    this.color = data.color || ProjectModel.defaultColor;
    this.archived = data.archived || false;
    this.task_count = data.task_count || 0;
    this.completed_task_count = data.completed_task_count || 0;
    this.user = new UserModel(data.user);
  }

  get nameSlug() {
    return this.name.split(' ').join('_').trim();
  }

  get percent_complete() {
    if (this.task_count === 0 || this.completed_task_count === 0) {
      return 0;
    }
    if (this.completed_task_count > this.task_count) {
      return 100;
    }

    return Math.floor((this.completed_task_count / this.task_count) * 100);
  }
}
