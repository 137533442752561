import {
  statusOptions,
  operatingHourOptions,
  electricalInfoOptions,
  physicalLocationOptions,
  conditionOptions,
  purchaseConditionOptions,
  typeOfServiceContractOptions,
  maintenanceFrequencyOptions,
} from '@/constants/options.js';

import { PropertyLocationModel } from './LocationModels';
import BaseModel from './BaseModel';
import CopyableMixin from './CopyableMixin';
import ServiceReportLogModel from './ServiceReportLogModel';
import FileModel from './FileModel';
import TagModel from './TagModel';
import CustomFieldModel from './CustomFieldModel';
import EquipmentFieldModel from './EquipmentFieldModel';
import filters from '../filters';

export default class CompanyEquipmentModel extends CopyableMixin(BaseModel) {
  init(data) {
    this.id = data.id || 0;
    this.qr_code = data.qr_code || '';
    this.updated = data.updated || '';

    // required fields:
    this.name = data.name || '';
    this.manufacturer = data.manufacturer || null; // {id, name}
    if (this.manufacturer && !this.manufacturer.display_label) {
      this.manufacturer.display_label = this.manufacturer.name;
    }
    this.location = data.location ? PropertyLocationModel.factory(data.location) : null;

    // optional fields:
    // active only for model display
    this.tags = (data.tags || []).map(t => new TagModel(t)).filter(t => t.active);
    this.custom_fields = (data.custom_field_values || []).map(t => new CustomFieldModel(t)).filter(t => t.active);

    this.image = data.image ? new FileModel(data.image) : null; // cover image
    this.images = (data.images || []).map(f => new FileModel(f));

    if (!this.image && this.images.length) {
      this.image = this.images[0];
    } else if (this.image && !this.images.find(i => i.id === this.image.id)) {
      this.images.push(this.image);
    }

    this.archived = data.archived || false;
    this.user_manual_files = (data.user_manual_files || []).map(f => new FileModel({ ...f, related_record_context: 'user_manual' }));
    this.warranty_files = (data.warranty_files || []).map(f => new FileModel({ ...f, related_record_context: 'warranty' }));
    this.certificate_files = (data.certificate_files || []).map(f => new FileModel({ ...f, related_record_context: 'certificate' }));
    this.equipment_files = (data.equipment_files || data.files || []).map(f => new FileModel({ ...f, related_record_context: 'equipment' }));
    this.existing_service_contract_files = (data.existing_service_contract_files || []).map(f => new FileModel({ ...f, related_record_context: 'existing_service_contract' }));

    this.make = data.make || '';
    this.model = data.model || '';
    this.manufacturer_other = data.manufacturer_other || '';
    this.serial_number = data.serial_number || '';
    this.manufacture_date = data.manufacture_date || '';

    this._category = data.category || {};
    this._equipment_type = data.equipment_type || {};
    this._equipment_category = data.equipment_category || {};
    this._equipment_category_2 = data.equipment_category_2 || {};
    this._equipment_category_3 = data.equipment_category_3 || {};

    // just grab the IDs for form
    this.category = this._category.id || null;
    this.equipment_type = this._equipment_type.id || '';
    this.equipment_category = this._equipment_category.id || '';
    this.equipment_category_2 = this._equipment_category_2.id || '';
    this.equipment_category_3 = this._equipment_category_3.id || '';

    this.equipment_type_other = data.equipment_type_other || '';
    this.equipment_category_other = data.equipment_category_other || '';
    this.category_fields = (this._category.category_specific_fields || []).map(f => new EquipmentFieldModel(f));
    this.category_specific_field_values = data.category_specific_field_values || [];

    this.updateCategoryFieldValues(this.category_specific_field_values);

    this.equipment_info = data.equipment_info || '';
    this.status = data.status || 'operating';
    this.status_details = data.status_details || '';
    this.current_run_hours = data.current_run_hours;
    this.current_run_hours_as_of = data.current_run_hours_as_of || '';
    this.estimated_operating_hours_per_year = data.estimated_operating_hours_per_year || '';
    this.electrical_info = data.electrical_info || '';

    this.building = data.building || '';
    this.floor = data.floor || '';
    this.room_area = data.room_area || '';
    this.physical_location = data.physical_location || data.specific_area || '';
    this.condition = data.condition || '';
    this.purchase_condition = data.purchase_condition || '';
    this.date_of_startup = data.date_of_startup || '';
    this.purchased_from = data.purchased_from || '';
    this.installed_by = data.installed_by || '';

    if (typeof data.currently_under_warranty !== 'undefined') {
      this.currently_under_warranty = data.currently_under_warranty || false;
    } else {
      this.currently_under_warranty = null;
    }

    this.warranty_expiration_date = data.warranty_expiration_date || '';
    this.name_of_current_service_provider = data.name_of_current_service_provider || '';
    this.type_of_service_contract = data.type_of_service_contract || '';
    this.contract_expiration_date = data.contract_expiration_date || '';
    this.useful_years = data.useful_years || '';
    this.replacement_year = data.replacement_year || '';
    this.estimated_life_expectancy = data.estimated_life_expectancy || '';
    this.estimated_replacement_year = data.estimated_replacement_year || '';
    this.purchase_cost = data.purchase_cost ? parseFloat(data.purchase_cost) : null;
    this.purchase_date = data.purchase_date || '';

    this.replacement_cost = data.replacement_cost ? parseFloat(data.replacement_cost) : null;
    this.routine_maintenance_cost = data.routine_maintenance_cost ? parseFloat(data.routine_maintenance_cost) : null;
    this.routine_maintenance_frequency = data.routine_maintenance_frequency || '';
    this.is_maintained = data.is_maintained || false;

    this.service_reports = (data.service_reports || []).map(r => new ServiceReportLogModel(r));

    this.open_rfp_count = data.open_rfp_count || 0;
    this.active_task_count = data.active_task_count || 0;

    this.href = `${document.location.origin}/equipment/${this.id}`;

    this.selected = false;
    this.added = false;
    this.removed = false;

    this.user_manual_file_count = data.user_manual_file_count || 0;
    this.warranty_file_count = data.warranty_file_count || 0;
    this.certificate_file_count = data.certificate_file_count || 0;
    this.equipment_file_count = data.equipment_file_count || 0;
    this.service_contract_file_count = data.service_contract_file_count || 0;
  }

  updateCategoryFieldValues(fieldValues) {
    this.category_fields.map((c) => {
      const val = fieldValues.find(v => v.field_id === c.id);
      if (val) c.value = val.value;
      return c;
    });
  }

  get attachments() {
    // todo: organize by attachmentRecordType
    return [...this.user_manual_files, ...this.warranty_files, ...this.certificate_files, ...this.equipment_files, ...this.existing_service_contract_files];
  }

  get updatedDate() {
    return this.updated ? new Date(this.updated) : null;
  }

  get runHoursDate() {
    return this.stringToDate(this.current_run_hours_as_of);
  }

  get dateOfStartupDate() {
    return this.stringToDate(this.date_of_startup);
  }

  get purchaseDateDate() {
    return this.stringToDate(this.purchase_date);
  }

  get equipmentTypeDisplay() {
    return (this._equipment_type && this._equipment_type.name) || '';
  }

  get equipmentCategoryDisplay() {
    if (!this._category) return null;
    let display = this._category.full_path_display_name;
    if (this._category.name === 'Other' && this.equipment_category_other) {
      display += ` (${this.equipment_category_other})`;
    }
    return display;
  }

  get icon() {
    return this._equipment_category_3?.icon || this._equipment_category_2?.icon || this._equipment_category?.icon || this._equipment_type.icon || '';
  }

  get icon_prefix() {
    return this._equipment_category_3?.icon_prefix || this._equipment_category_2?.icon_prefix || this._equipment_category?.icon_prefix || this._equipment_type.icon_prefix || '';
  }

  get placeholderIcon() {
    if (this.icon && this.icon_prefix) {
      let icon = this.icon;
      if (this.icon.substring(0, 1) !== 'fa') {
        icon = `fa-${this.icon}`;
      }
      return [this.icon_prefix, icon];
    }

    if (this.equipment_type_other || this.equipment_category_other) {
      return ['fas', 'fa-cog'];
    }

    return ['fas', 'fa-images'];
  }

  get warrantyExpirationDateDate() {
    return this.stringToDate(this.warranty_expiration_date);
  }

  get contractExpirationDateDate() {
    return this.stringToDate(this.contract_expiration_date);
  }

  get manufactureDateDate() {
    return this.stringToDate(this.manufacture_date);
  }

  get currentRunHoursAsOfDate() {
    return this.stringToDate(this.current_run_hours_as_of);
  }

  copy(model, notForEditMode = false) {
    super.copy(model);

    // convert models to strings for edit form
    if (!notForEditMode) {
      this.manufacturer = (model.manufacturer && model.manufacturer.id) || '';
      this.location = (model.location && model.location.id) || '';

      if (this.manufacturer_other) {
        this.manufacturer = 'other';
      }
    }
  }

  addAnotherServiceLog() {
    this.service_reports.push(new ServiceReportLogModel());
  }

  // allow input-select integration to bool
  get _currently_under_warranty() {
    if (this.currently_under_warranty === null) {
      return '';
    }

    return this.currently_under_warranty ? 'true' : 'false';
  }

  set _currently_under_warranty(v) {
    this.currently_under_warranty = v === 'true';
  }

  get _current_run_hours() {
    try {
      return this.current_run_hours.toString();
    } catch {
      return '';
    }
  }

  set _current_run_hours(v) {
    this.current_run_hours = v;
  }

  get _useful_years() {
    return this.useful_years.toString();
  }

  set _useful_years(val) {
    this.useful_years = val;
  }

  get _replacement_year() {
    return this.replacement_year.toString();
  }

  set _replacement_year(val) {
    this.replacement_year = val;
  }

  get manufacturerName() {
    if (this.manufacturer_other) {
      return this.manufacturer_other;
    }
    return (this.manufacturer && this.manufacturer.name) || '';
  }

  get display_label() {
    if (this.location_name) {
      return `${this.name} (${this.location_name})`;
    }
    return this.name;
  }

  getEnumDisplayValue(options, toFind) {
    if (!toFind) return '';
    return options.find(s => s.id.toLowerCase() === toFind.toLowerCase())?.display_label || '';
  }

  get displayStatus() {
    return this.getEnumDisplayValue(statusOptions, this.status);
  }

  get displayElectricalInfo() {
    return this.getEnumDisplayValue(electricalInfoOptions, this.electrical_info);
  }

  get displayPhysicalLocation() {
    return this.getEnumDisplayValue(physicalLocationOptions, this.physical_location);
  }

  get displayCondition() {
    return this.getEnumDisplayValue(conditionOptions, this.condition);
  }

  get displayOperatingHours() {
    return this.getEnumDisplayValue(operatingHourOptions, this.estimated_operating_hours_per_year);
  }

  get displayPurchaseCondition() {
    return this.getEnumDisplayValue(purchaseConditionOptions, this.purchase_condition);
  }

  get displayServiceContractType() {
    return this.getEnumDisplayValue(typeOfServiceContractOptions, this.type_of_service_contract);
  }

  get displayMaintenanceFrequency() {
    return this.getEnumDisplayValue(maintenanceFrequencyOptions, this.routine_maintenance_frequency);
  }

  get displayIsMaintained() {
    return filters.booleanDisplay(this.is_maintained);
  }

  get is_negative_status() {
    return ['maintenance_required', 'needs_repair', 'scrap'].indexOf(this.status) !== -1;
  }

  get statusClass() {
    return this.status || 'operating';
  }

  get requests_summary() {
    return `${this.open_rfp_count} Ongoing`;
  }

  get tasks_summary() {
    return `${this.active_task_count} Scheduled`;
  }

  get location_name() {
    return (this.location && this.location.fullAncestryPath) || '';
  }

  get location_id() {
    return this.location ? this.location.id : null;
  }

  get thumbnail_url() {
    return (this.image && this.image.thumbnail_url) || (this.image && this.image.url) || '';
  }

  get equipmentInfoHtml() {
    return this.getHtmlDisplayValue(this.equipment_info);
  }

  getCategorySpecificFieldValue(id) {
    const fieldId = parseInt(id.replace('category_specific_field:', ''));
    return this.category_fields.find(x => x.id === fieldId)?.valueForDisplay || '';
  }

  getCustomFieldValue(id) {
    const customFieldId = parseInt(id.replace('custom_field:', ''));
    return this.custom_fields.find(x => x.id === customFieldId)?.value || '';
  }

  matchesSearch(s) {
    return !s || this.name.toLowerCase().indexOf(s) !== -1;
  }

  isEquipmentType(code_name) {
    if (this._equipment_type) {
      return this._equipment_type.code_name === code_name;
    }
    return false;
  }
}
