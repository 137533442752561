import moment from 'moment';
import Vue from 'vue';
import filters from '@/filters';
import { restrictedFileExtensions } from './constants/options';

const titleCase = (snake) => {
  if (!snake) {
    return 'Unknown';
  }
  return snake.split('_').map(a => a.charAt(0).toUpperCase() + a.slice(1)).join(' ').trim();
};

const utcDateString = (dateVal, dayStart = false, dayEnd = false) => {
  if (dateVal === '') {
    return '';
  }
  const momentDate = moment.utc(dateVal);

  if (dayStart && !dayEnd) {
    momentDate.startOf('day');
  }
  if (dayEnd && !dayStart) {
    momentDate.endOf('day');
  }
  return momentDate.toISOString();
};

const datetimeFromNow = (d) => {
  if (!d) {
    return '-';
  }

  return moment(d).fromNow();
};

const getCurrentDateNoTime = () => {
  const d = new Date();
  d.setHours(0, 0, 0, 0);
  return d;
};

const cleanSimpleDateStr = (value) => {
  // YYYY-MM-DD
  const dateParts = value.split('-');
  if (dateParts.length === 3) {
    return new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
  }
  return '';
};

const getNumberOfDays = (date1, date2) => {
  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // Calculating the time difference between two dates
  const diffInTime = date2.getTime() - date1.getTime();

  // Calculating the no. of days between two dates
  const diffInDays = Math.round(diffInTime / oneDay);

  return diffInDays;
};

const friendlyDayNameFromInt = (day) => {
  const dayOfWeek = moment().day(day);
  return dayOfWeek.format('dddd');
};

const nullableEpochDate = (dateStr) => {
  const dateObj = dateStr ? new Date(dateStr) : null;
  return dateObj?.getTime() !== 0 ? dateObj : null;
};

const writeToClipboard = (value, event) => {
  const clipboardData = (event && event.clipboardData) || window.clipboardData || (event && event.originalEvent && event.originalEvent.clipboardData) || navigator.clipboard;

  if (clipboardData) {
    clipboardData.writeText(value);
    return true;
  }

  return false;
};

const validateFileTypes = (files, validateType = true, showMsg = true) => {
  if (files?.constructor?.name !== FileList.name) {
    return false;
  }

  const allFilesHaveExtensions = [...files].filter(f => !f.type).length === 0;
  if (!allFilesHaveExtensions && showMsg) {
    Vue.prototype.$globals.showError(invalidFileExtMessage);
    return false;
  }

  const allFilesHaveSize = [...files].filter(f => f.size === 0).length === 0;
  if (!allFilesHaveSize && showMsg) {
    Vue.prototype.$globals.showError(invalidFileSizeMessage);
    return false;
  }

  let allFilesValid = true;
  const failedExtensions = [];

  if (validateType) {
    [...files].forEach((f) => {
      const parts = f.name.split('.');
      if (!parts.length) {
        return;
      }

      const ext = parts[parts.length - 1];
      if (restrictedFileExtensions.includes(ext)) {
        failedExtensions.push(ext);
      }
    });

    allFilesValid = failedExtensions.length === 0;

    if (!allFilesValid && showMsg) {
      Vue.prototype.$globals.showError(`We do not accept uploads with an extension(s) of ${failedExtensions.join(' or ')}!`);
    }
  }

  return allFilesHaveExtensions && allFilesValid;
};
const invalidFileExtMessage = 'File extensions are required for all uploads! Please add an extension and try again.';
const invalidFileSizeMessage = 'You have uploaded an empty file. Please try again.';

const isOnlyHtmlTags = (input, trim = false) => {
  if (!input) return false;

  const sansTags = input.replace(/(<([^>]+)>)/gi, '');
  return (trim ? sansTags.trim() : sansTags).length === 0;
};

const breakTag = '<span class="break"></span>';
const cleanHtmlTextForDisplay = str => str.replace(/\n/g, breakTag).replaceAll('<p></p>', breakTag);

const regexChars = /[.*+?^${}()|[\]\\]/g;
const cleanStringForRegex = str => (str.replace(regexChars, ''));

const observableArrayEquals = (arr, other) => (JSON.stringify(arr) === JSON.stringify(other));

const convertHexToRGB = (H) => {
  let r = 0;
  let g = 0;
  let b = 0;

  if (H.length == 4) {
    r = `0x${H[1]}${H[1]}`;
    g = `0x${H[2]}${H[2]}`;
    b = `0x${H[3]}${H[3]}`;
  } else if (H.length == 7) {
    r = `0x${H[1]}${H[2]}`;
    g = `0x${H[3]}${H[4]}`;
    b = `0x${H[5]}${H[6]}`;
  }
  r /= 1;
  g /= 1;
  b /= 1;

  return {
    r,
    g,
    b,
  };
};

const convertHexToHSL = (H) => {
  const rgb = convertHexToRGB(H);
  const r = rgb.r / 255;
  const g = rgb.g / 255;
  const b = rgb.b / 255;

  // Then to HSL
  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;
  let h = 0;
  let s = 0;
  let l = 0;

  if (delta == 0) {
    h = 0;
  } else if (cmax == r) {
    h = ((g - b) / delta) % 6;
  } else if (cmax == g) {
    h = (b - r) / delta + 2;
  } else {
    h = (r - g) / delta + 4;
  }

  h = Math.round(h * 60);

  if (h < 0) {
    h += 360;
  }

  l = (cmax + cmin) / 2;
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return {
    h,
    s,
    l,
  };
};

const getFormattedDate = (date) => {
  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${month}/${day}/${year}`;
};

const colorChange = (color, percent) => {
  let R = parseInt(color.substring(0, 2), 16);
  let G = parseInt(color.substring(2, 4), 16);
  let B = parseInt(color.substring(4, 6), 16);

  R = parseInt(R * (100 + percent) / 100);
  G = parseInt(G * (100 + percent) / 100);
  B = parseInt(B * (100 + percent) / 100);

  R = Math.round((R < 255) ? R : 255);
  G = Math.round((G < 255) ? G : 255);
  B = Math.round((B < 255) ? B : 255);

  const RR = ((R.toString(16).length == 1) ? `0${R.toString(16)}` : R.toString(16));
  const GG = ((G.toString(16).length == 1) ? `0${G.toString(16)}` : G.toString(16));
  const BB = ((B.toString(16).length == 1) ? `0${B.toString(16)}` : B.toString(16));

  return `${RR}${GG}${BB}`;
};

const filterListForSearch = (list, search = '', field = 'name') => {
  list = list.sort((a, b) => b[field].toLowerCase() - a[field].toLowerCase());
  search = search.toLowerCase().trim();
  if (!search) {
    return list;
  }

  return list.filter(
    o => o[field].toLowerCase().includes(search),
  ).sort(
    (a, b) => b[field].toLowerCase().startsWith(search) - a[field].toLowerCase().startsWith(search),
  );
};

const padZeros = z => (z.toString().padStart(2, '0'));
const convertSecondsToStopwatch = (seconds) => {
  if (!seconds) return '00:00:00';

  const breakDown = convertSecondsToHMS(seconds);
  return `${padZeros(breakDown.hours)}:${padZeros(breakDown.minutes)}:${padZeros(breakDown.seconds)}`;
};

const convertStopWatchToSeconds = (hours, minutes, seconds) => {
  const hour_sec = (hours || 0) * 3600;
  const minute_sec = (minutes || 0) * 60;

  return (hour_sec + minute_sec + parseInt((seconds || 0)));
};

const convertSecondsToHMS = (seconds) => {
  if (!seconds) return '00:00:00';

  return {
    hours: Math.floor(seconds / 3600),
    minutes: (Math.floor((seconds % 3600) / 60)),
    seconds: (seconds % 60),
  };
};

const sortList = (list, field = 'name') => {
  list = list || [];
  return list.sort((a, b) => {
    if (a[field] < b[field]) { return -1; }
    if (a[field] > b[field]) { return 1; }
    return 0;
  });
};

const stringMatchesSearch = (str, search) => {
  if (!search || !str) return false;

  const words = search.toLowerCase().trim().split(/\s+/);
  const lowercaseName = str.toLowerCase();
  return words.every(word => lowercaseName.indexOf(word) >= 0);
};

const getOrCreateTagIds = async (tags) => {
  const tagIds = [];
  let addedTag = false;
  for (let i = 0; i < tags.length; i++) {
    const tag = tags[i];

    if (tag.id) {
      tagIds.push(tag.id);
    } else {
      const resp = await Vue.prototype.$globals.companyClient.addTag(tag.name);
      if (resp && resp.id) {
        addedTag = true;
        tagIds.push(resp.id);
        tag.id = resp.id;
      }
    }
  }

  if (addedTag) {
    Vue.prototype.$globals.cachedData.getActiveCompanyTags(true);
  }

  return tagIds;
};

const getSelectedSort = (selectedSort, currentSort = null, ignoreInvertCheck = false) => {
  if (!ignoreInvertCheck && currentSort === selectedSort) {
    return `-${selectedSort.split(',').join(',-')}`;
  }
  return selectedSort;
};

const selectOptionsFromStrs = strs => strs.map(str => ({ id: str, display_label: str }));

const applyEquipmentTypeFilterToApiParams = (params, equipmentTypeId) => {
  if (!equipmentTypeId) return;
  if (equipmentTypeId === -1) {
    params.equipment_type__isnull = true;
  } else {
    params.equipment_type__in = equipmentTypeId;
  }
};

const youtubeRegex = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
const youtubeParser = (url) => {
  const match = url.match(youtubeRegex);
  return (match && match[7].length === 11) ? match[7] : false;
};

const boldenText = (inputText, query) => {
  if (!inputText || !query) { return inputText; }
  const texts = cleanStringForRegex(query).split(/[\s-_/\\|\.]/gm).filter(t => !!t) || [''];
  return inputText.replace(new RegExp(`(.*?)(${texts.join('|')})(.*?)`, 'gi'), '$1<b>$2</b>$3');
};

const countDisplay = (count, word) => `${filters.number(count)} ${pluralize(count, word)}`;

const pluralize = (count, word) => {
  if (!word || count == 1) return word;
  return `${word}s`;
};

const cappedNotificationCount = (count = 0, cappedCount = 9) => {
  const value = count || 0;
  return value <= cappedCount ? value : `${cappedCount}+`;
};

const isNullOrUndefined = value => value === undefined || value === null;

const scheduleWeekdayToDescription = (weekday) => {
  const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  if (weekday < 0 || weekday > 6) {
    return '';
  }
  return weekdays[weekday];
};

const scheduleMonthToDescription = (month) => {
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  if (month < 1 || month > 12) {
    return '';
  }
  return months[month - 1];
};

const scheduleIntervalToDescription = (interval) => {
  const ordinalNumbers = [
    '', '', 'other', 'third', 'fourth', 'fifth', 'sixth', 'seventh',
    'eighth', 'ninth', 'tenth', 'eleventh', 'twelfth', 'thirteenth',
    'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth',
    'nineteenth', 'twentieth', 'twenty-first', 'twenty-second', 'twenty-third',
    'twenty-fourth', 'twenty-fifth', 'twenty-sixth', 'twenty-seventh',
    'twenty-eighth', 'twenty-ninth', 'thirtieth', 'thirty-first',
  ];

  if (interval < 0 || interval > 31) {
    return '';
  }

  const description = ordinalNumbers[interval];
  return `every ${description}`.trim();
};

const nth = (month, day) => moment().month(month).date(day).format('Do');

const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
const isGuid = test_str => guidRegex.test(test_str);

export {
  getCurrentDateNoTime,
  getNumberOfDays,
  titleCase,
  utcDateString,
  datetimeFromNow,
  nullableEpochDate,
  writeToClipboard,
  validateFileTypes,
  isOnlyHtmlTags,
  cleanHtmlTextForDisplay,
  cleanStringForRegex,
  observableArrayEquals,
  convertHexToHSL,
  convertHexToRGB,
  colorChange,
  friendlyDayNameFromInt,
  getFormattedDate,
  filterListForSearch,
  convertSecondsToStopwatch,
  convertStopWatchToSeconds,
  convertSecondsToHMS,
  sortList,
  stringMatchesSearch,
  getOrCreateTagIds,
  selectOptionsFromStrs,
  getSelectedSort,
  applyEquipmentTypeFilterToApiParams,
  cleanSimpleDateStr,
  youtubeParser,
  boldenText,
  countDisplay,
  pluralize,
  cappedNotificationCount,
  isNullOrUndefined,
  scheduleWeekdayToDescription,
  scheduleMonthToDescription,
  scheduleIntervalToDescription,
  nth,
  isGuid,
};
