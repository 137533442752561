<template>
  <div class="default-layout">
    <helix-header />
    <main>
      <slot />
    </main>
    <helix-footer />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
  name: 'Default',
  components: {
    HelixHeader: defineAsyncComponent(() => import('@/components/HelixHeader.vue')),
    HelixFooter: defineAsyncComponent(() => import('@/components/HelixFooter.vue')),
  },
};
</script>

<style lang="scss" scoped>
.default-layout {
  main {
    padding-top: calc(#{$header-height} + #{$gutter});
    min-height: 100vh;
    background: $light-gray;
    padding-bottom: 75px;
  }
}
</style>
