import BaseModel from './BaseModel';
import FileModel from './FileModel';

export default class ThirdPartyPaymentInfoModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.company_id = data.company_id || 0;
    this.payment_link = data.payment_link || '';
    this.image = data.image ? new FileModel(data.image) : null;
  }
}
