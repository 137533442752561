import BaseModel from './BaseModel';

class CustomFieldModel extends BaseModel {
  init(data) {
    this.id = data.id || data.custom_field_id || 0;
    this.name = data.name || data.custom_field_name || '';
    this.value = data.value || '';

    this.custom_field_type = data.custom_field_type || '';
    this.active = data.custom_field_active || data.active || false; // only shown as a usable tag if active
  }

  static getDisplayName(id) {
    return CustomFieldModel.typeOptions.find(t => t.id === id)?.display_label || '';
  }

  get display_label() {
    return this.name;
  }

  get fieldTypeDisplay() {
    return CustomFieldModel.getDisplayName(this.custom_field_type);
  }
}

CustomFieldModel.typeOptions = [
  { id: 'equipment', display_label: 'Equipment' },
  { id: 'location', display_label: 'Location' },
];
export default CustomFieldModel;
