import { datetimeFromNow } from '@/util';
import BaseModel from './BaseModel';
import PartModel from './PartModel';
import PartContainerModel from './PartContainerModel';

export default class PartContainerInventoryModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.updated = data.updated ? new Date(data.updated) : null;
    this.container = data.container ? new PartContainerModel(data.container) : null;
    this.part = data.part ? new PartModel(data.part) : null;
    this.quantity_on_hand = data.quantity_on_hand || 0;
    this.target_quantity = data.target_quantity || 0;
  }

  get location_name() {
    return this.container?.location_name || BaseModel.defaultDisplayValue;
  }

  get part_name() {
    return this.part?.name || BaseModel.defaultDisplayValue;
  }

  get part_container_name() {
    return this.container?.name || BaseModel.defaultDisplayValue;
  }

  get hasLowInventory() {
    return this.quantity_on_hand < this.target_quantity;
  }

  get updatedTableDisplay() {
    return datetimeFromNow(this.updated);
  }
}
