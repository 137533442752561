import Ably from 'ably/callbacks';
import Vue from 'vue';

export default class PubSubUtil {
  constructor() {
    this.companyId = null;
    this.listeners = [];
    this.sessionId = null;
  }

  closeConnection() {
    if (this.client != null) {
      this.client.close();
    }
    this.sessionId = null;
  }

  initClient() {
    this.client = new Ably.Realtime({
      authCallback: async (tokenParams, callback) => {
        Vue.prototype.$globals.userClient.getPubSubToken().then((response) => {
          this.sessionId = response.session_id;
          callback(null, response.token);
        }).catch((ex) => {
          console.warn(ex);
          const error = ex?.body?.error?.message || 'Failed to authenticate';
          callback(error, null);
        });
      },
    });
  }

  async connectToCurrentCompanyChannel() {
    try {
      const companyId = Vue.prototype.$globals.companyId;
      // already connected
      if (companyId && this.companyId === companyId) return;

      this.closeConnection();

      this.companyId = companyId;

      if (!this.companyId) return;

      this.initClient();

      this.connectToChannel(`company_${companyId}:main`);
    } catch (ex) {
      console.error(ex);
    }
  }

  async connectToChannel(channelName) {
    try {
      await this.client.channels.get(channelName).subscribe((message) => {
        this.messageReceived(message);
      });
    } catch (ex) {
      console.error(ex);
    }
  }

  addListener(listener) {
    if (this.listeners.find(l => l === listener)) return;

    if (!listener.processPubSubEvent) {
      console.error('component missing processPubSubEvent method');
      return;
    }
    this.listeners.push(listener);
  }

  removeListener(listener) {
    this.listeners = this.listeners.filter(l => l !== listener);
  }

  messageReceived(message) {
    // If the session id matches our session id, we did the action.
    // Pass this along as in some cases it will already have been accounted for, but others may still need to update.
    const wasLocalAction = this.sessionId && message.data?.session_id === this.sessionId;

    this.listeners = this.listeners.filter(l => !l._isDestroyed);

    this.listeners.forEach((l) => {
      l.processPubSubEvent(message.name, message.data, wasLocalAction);
    });
  }
}
