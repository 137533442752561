import BaseModel from './BaseModel';
import UserModel from './UserModel';
import CompanyModel from './CompanyModel';

export default class AffiliateUserModel extends BaseModel {
  init(data) {
    this.user = new UserModel(data.user);
    this.company = new CompanyModel(data.company);
  }

  get company_name() {
    return (this.company && this.company.name) || '';
  }

  get full_name() {
    return (this.user && `${this.user.first_name} ${this.user.last_name}`) || '';
  }

  get email() {
    return (this.user && this.user.email) || '';
  }

  get status() {
    return this.user && this.user.email_verified ? 'Registered' : 'Unregistered';
  }
}
