import BaseModel from './BaseModel';

export default class TagModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.name = data.name || data.text || '';
    this.active = data.active || false; // only shown as a usable tag if active

    this.color = data.color || '';

    this.equipment_count = data.equipment_count || 0;
    this.part_count = data.parts_count || 0;
    this.location_count = data.locations_count || 0;
  }

  get display_label() {
    return this.name;
  }

  get lowerCaseName() {
    return this.name.toLowerCase();
  }

  get colorHex() {
    if (this.color) {
      return `#${this.color}`;
    }
    return '';
  }
}
