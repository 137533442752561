import BaseModel from './BaseModel';

export default class LocationRelatedItemsModel extends BaseModel {
  init(data) {
    this.id = data.id;
    this.equipment_count = data.equipment_count || 0;
    this.starting_equipment_count = this.equipment_count;

    this.tasks_count = data.tasks_count || 0;
    this.starting_tasks_count = data.tasks_count;

    this.parts_containers_count = data.parts_containers_count || 0;
    this.starting_parts_containers_count = data.parts_containers_count;
  }

  get hasRelatedItems() {
    return (this.equipment_count || this.parts_containers_count || this.tasks_count);
  }

  updateCount(type, countRemoved) {
    if (type === 'equipment') {
      this.equipment_count = countRemoved <= this.equipment_count ? this.equipment_count - countRemoved : 0;
    }
    if (type === 'tasks') {
      this.tasks_count = countRemoved <= this.tasks_count ? this.tasks_count - countRemoved : 0;
    }
    if (type === 'part_bins') {
      this.parts_containers_count = countRemoved <= this.parts_containers_count ? this.parts_containers_count - countRemoved : 0;
    }
  }
}
