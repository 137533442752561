import BaseModel from './BaseModel';
import TagModel from './TagModel';

export default class ProspectiveCompanyModel extends BaseModel {
  // user added vendor or client model
  init(data) {
    this.id = data.id || 0;
    this.company_name = data.company_name || '';
    this.contact_name = data.contact_name || '';
    this.category = data.category || '';
    this.phone = data.phone || '';
    this.phone_type = data.phone_type || '';
    this.phone_2 = data.phone_2 || '';
    this.phone_2_type = data.phone_2_type || '';
    this.email = data.email || '';
    this.user_id = data.user_id || 0;
    this.company_id = data.company_id || 0;
    this.service_marketplace_card_id = data.service_marketplace_card_id || 0;
    this.preferred_contact_method = data.preferred_contact_method || 'email';
    this.notes = data.notes || '';
    this.tags = (data.tags || []).map(t => new TagModel(t)).filter(t => t.active);

    if (data.helix_company) {
      this.company_id = data.helix_company.id || 0;
      this.company_name = data.helix_company.name || '';
    }

    if (data.contact_user) {
      this.user_id = data.contact_user.id || 0;
      this.contact_name = `${data.contact_user.first_name} ${data.contact_user.last_name}`;
      this.phone = data.contact_user.phone || data.phone || '';
      this.email = data.contact_user.email || data.email || '';
    }

    this.selected = false;
  }

  get display_label() {
    if (this.contact_name?.length) {
      return `${this.company_name} - ${this.contact_name}`;
    }
    return this.company_name;
  }

  get phone_icon() {
    return this.phoneIcon(false);
  }

  get phone_2_icon() {
    return this.phoneIcon(true);
  }

  get name() {
    return this.display_label;
  }

  phoneIcon(usePhone2 = false) {
    const type = usePhone2 ? this.phone_2_type : this.phone_type;
    return type === 'phone'
      ? 'phone-rotary'
      : (type === 'cell' ? 'mobile-alt' : 'fax');
  }
}
