import OptionModel from './OptionModel';

export default class VendorHubCategoryModel extends OptionModel {
  init(data) {
    super.init(data);
    this.description = data.description || '';
    this.color = data.color || '';
    this.icon_prefix = data.icon_prefix || '';
    this.icon = data.icon || '';
    this.type = 'vendor_hub_category';
  }

  get title() {
    return this.name;
  }

  get summary() {
    return this.description;
  }

  get placeholderIcon() {
    if (this.icon && this.icon_prefix) {
      let icon = this.icon;
      if (this.icon.substring(0, 1) !== 'fa') {
        icon = `fa-${this.icon}`;
      }
      return [this.icon_prefix, icon];
    }

    return ['far', 'circle'];
  }
}
