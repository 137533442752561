import BaseModel from './BaseModel';

export default class StripeAccountModel extends BaseModel {
  init(data) {
    this.stripe_account_id = data.stripe_account_id;
    this.charges_enabled = data.charges_enabled;
    this.details_submitted = data.details_submitted;
    this.payouts_enabled = data.payouts_enabled;
  }
}
