import BaseModel from './BaseModel';
import UserModel from './UserModel';
import CopyableMixin from './CopyableMixin';

class TaskChecklistModel extends CopyableMixin(BaseModel) {
  constructor(data, args) {
    super();

    this.init(data || {});
    this.persisted = (args && 'persisted' in args) ? args.persisted : true;
  }

  init(data) {
    this.id = data.id || 0;
    this.name = data.name || '';
    this.items = (data.items || []).map(i => new TaskChecklistItemModel(i));
  }

  get completedCount() {
    return (this.items.filter(i => i.completed) || []).length;
  }

  get completionRatio() {
    if (!this.items.length) return '';

    return `(${this.completedCount}/${this.items.length})`;
  }

  get assignedCount() {
    return (this.items.filter(i => i.assignees.length) || []).length;
  }

  get isComplete() {
    return this.items.length && (this.completedCount === this.items.length);
  }
}

class TaskChecklistItemModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.name = data.name || '';
    this.completed = 'completed' in data ? data.completed : false;
    this.completed_by = new UserModel(data.completed_by);
    this.completed_date = data.completed_date ? new Date(data.completed_date) : null;
    this.sort_order = data.sort_order || 0;
    this.assignees = (data.assignees || []).map(a => new UserModel(a));
  }
}

export { TaskChecklistModel, TaskChecklistItemModel };
