import BaseModel from './BaseModel';
import FileModel from './FileModel';

export default class VendorHubResultModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.name = data.name || '';
    this.icon_prefix = data.icon_prefix || '';
    this.icon = data.icon || '';
    this.color = data.color || '';
    this.type = data.type || ''; // vendor_hub_category | vendor | vendor_placeholder
    this.street_address = data.street_address || '';
    this.city = data.city || '';
    this.state = data.state || '';
    this.logo = data.logo ? new FileModel(data.logo) : null;
    this.selectable = 'selectable' in data ? data.selectable : true;
  }

  get display_id() {
    if (this.id && !this.type) return `unknown_${this.id}`;
    if (!this.id && this.type) return `${this.type}_0`;
    if (!this.id && !this.type) return 'unknown_0';
    return `${this.type}_${this.id}`;
  }

  get display_label() {
    return this.name;
  }

  get address() {
    if (!this.street_address && !this.city) return '';
    return `${this.street_address}, ${this.city}, ${this.state}`;
  }

  get placeholderIcon() {
    if (this.icon && this.icon_prefix) {
      let icon = this.icon;
      if (this.icon.substring(0, 1) !== 'fa') {
        icon = `fa-${this.icon}`;
      }
      return [this.icon_prefix, icon];
    }

    return ['far', 'fa-question-circle'];
  }
}
