import Vue from 'vue';
import BaseModel from './BaseModel';

export default class CompanyPermissionModel extends BaseModel {
  init(data) {
    this.code_name = data.code_name || '';
    this.description = data.description || '';
    this.for_equipment_operators = data.for_equipment_operators || false;
    this.for_service_providers = data.for_service_providers || false;

    this.heading = data.heading || '';

    if (typeof data.sort_order_equipment_operators === 'number') {
      this.sort_order_equipment_operators = data.sort_order_equipment_operators || 0;
    } else {
      this.sort_order_equipment_operators = 99999999;
    }

    if (typeof data.sort_order_service_providers === 'number') {
      this.sort_order_service_providers = data.sort_order_service_providers || 0;
    } else {
      this.sort_order_service_providers = 99999999;
    }

    this.selected = false;
    this.disabled = false;
  }

  get formId() {
    return `perm_${this.code_name}`;
  }

  get sortOrder() {
    if (Vue.prototype.$globals.company().is_equipment_operator) return this.sort_order_equipment_operators;
    if (Vue.prototype.$globals.company().is_service_provider) return this.sort_order_service_providers;

    return 0;
  }

  get forCurrentCompanyType() {
    const is_eo = Vue.prototype.$globals.company().is_equipment_operator;
    const is_vendor = Vue.prototype.$globals.company().is_service_provider;
    return (this.for_equipment_operators && is_eo) || (this.for_service_providers && is_vendor);
  }
}
