import BaseModel from './BaseModel';

export default class EquipmentAgeModel extends BaseModel {
  init(data) {
    this.equipment_type_id = data.equipment_type_id || 0;
    this.name = data.name || '';
    this.data = data.data || {}; // { year: count }
  }

  get hasData() {
    const keys = Object.keys(this.data);
    return keys.filter(k => this.data[k] > 0).length > 0;
  }

  chartData(allYears) {
    return {
      label: this.name,
      data: allYears.map(year => [year, this.data[year] || 0]),
    };
  }
}
